import { useNavigate } from "react-router-dom"

const RoflerCompanyMenu = (props) => {
  const navigate = useNavigate();

  function toFAQ()    { if ( props.set!=="1" ) navigate('/faq'); }
  function toQNA()    { if ( props.set!=="2" ) navigate('/qna'); }
  function toTerms()  { if ( props.set!=="3" ) navigate('/terms'); }
  function toPolicy() { if ( props.set!=="4" ) navigate('/policy'); }

  window.scrollTo(0, 0);

  return (
    <>
      <div className="my-page-menu-wrap">
        <a href="#" className="m-my-page-menu">메뉴</a>
        {/* <!--my-page-menu--> */}
        <ul className="my-page-menu">
          <li className={props?.set==="1"?"on":""}><a onClick={toFAQ}>고객센터</a></li>
          <li className={props?.set==="2"?"on":""}><a onClick={toQNA}>문의등록</a></li>
          <li className={props?.set==="3"?"on":""}><a onClick={toTerms}>이용약관</a></li>
          <li className={props?.set==="4"?"on":""}><a onClick={toPolicy}>개인정보처리방침</a></li>
        </ul>
        {/* <!--//my-page-menu--> */}
      </div>
    </>
  )
}

export default RoflerCompanyMenu;