// import { Navigate } from 'react-router-dom';
// import NotFound from 'src/pages/NotFound';
// import Register from 'src/pages/Register';
// import DashboardLayout from 'src/components/DashboardLayout';
// import Account from 'src/pages/Account';
// import CustomerList from 'src/pages/CustomerList';
// import Dashboard from 'src/pages/Dashboard';
// import Login from 'src/pages/Login';
// import ProductList from 'src/pages/ProductList';
// import Settings from 'src/pages/Settings';

import { Outlet } from 'react-router-dom';
import RoflerLogin from './pages/RoflerLogin.js';
import RoflerLoginWrapper from './pages/RoflerLogin';
import RoflerSignup from './pages/RoflerSignup.js';
import RoflerSignupStep2 from './pages/RoflerSignUp2.js';
import RoflerSignupStep3 from './pages/RoflerSignUp3.js';
import HomeMain from './pages/Home.js';
import { VideoPlayerPage } from './pages/VideoPlayer.js';
// import { RoflerSignup } from './pages/RoflerSignup';
// import RoflerSignupStep2 from './pages/RoflerSignupStep2';
// import RoflerSignupStep3 from './pages/RoflerSignupStep3';
// import Test from './pages/Test';
import { NotFound } from 'http-errors';
import VideoPlayerLocalPage from './pages/VideoPlayerLocal.js';
import RoflerLanding from './pages/Landing.js';
import QNA from './pages/QNA.js';
import FAQ from './pages/FAQ.js';
import Terms from './pages/Terms.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import Withdraw from './pages/Withdraw.js';
import WithdrawDone from './pages/WithdrawDone.js';
import MyPage from './pages/MyPage.js';
import PasswordChange from './pages/PasswordChange.js';
import BPass from './pages/BPASS.js';
import BPASSRedir from './pages/BPASSRedir.js';
import PasswordReset, { RequestPWReset } from './pages/RequestPWReset.js';
import EmailPasswordReset from './pages/PasswordChangeEmail.js';


const routes = [
  {
    path: '/',
    element: (<> <Outlet /> </>),   // children을 쓰기 위해서는 Outlet이 붙어 줘야 함.
    children: [
      { path: '/', element: <RoflerLanding /> },
      { path: 'login', element: <RoflerLoginWrapper /> },
      { path: 'help', element: <RoflerLogin /> },
      { path: 'signup', element: <RoflerSignup /> },
      { path: 'signup2', element: <RoflerSignupStep2 /> },
      { path: 'signup3', element: <RoflerSignupStep3 /> },
      { path: 'player', element: <VideoPlayerPage /> },
      { path: 'playerlocal', element: <VideoPlayerLocalPage /> },
      { path: 'home', element: <HomeMain /> },
      { path: 'faq', element: <FAQ /> },
      { path: 'qna', element: <QNA /> },
      { path: 'terms', element: <Terms /> },
      { path: 'policy', element: <PrivacyPolicy /> },
      { path: 'cancelMembership', element: <Withdraw /> },
      { path: 'cancelResult', element: <WithdrawDone /> },
      { path: 'mypage', element: <MyPage /> },
      { path: 'bpass', element: <BPass /> },
      { path: 'bpassredir', element: <BPASSRedir /> },
      { path: 'passwordchange', element: <PasswordChange /> },  // 로긴후 pw변경
      { path: 'rqpwreset', element: <RequestPWReset /> },   // 이멜 pw변경요청
      { path: 'pwreset', element: <EmailPasswordReset /> },  // 이멜key 이요 pw변경
      // { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      // { path: '*', element: <Navigate to="/404" /> }
    ]
  },
];

export default routes;
