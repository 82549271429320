import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";

export const Withdraw = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const [ contents, setContents ] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect( () => {
    if ( contents===false )
      setContents( location.state.contents );
  }, [])

  // const schema = Yup.object({
  //     // username : Yup.string().email("정확한 이메일 주소를 사용해 주세요").required("id로 email주소를 입력해 주세요"),
  //     // password: Yup.string().min(4, "8자 이상을 입력해주세요").required("패스워드를 입력해 주세요")
  //     username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
  //     password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
  //   });
  // const { 
  //   register, 
  //   handleSubmit, 
  //   formState: { errors }, 
  // } = useForm( {
  //   reValidateMode: 'onChange',
  //   resolver: yupResolver(schema)
  // });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });

  // const letsStart = () => {
  //   const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}${email}`;
    
  //   function okFunc(res) {
  //     console.log("CHECK RES : ", res);
  //     if ( res.data?.user ) {
  //       setDimmer(<>이미 가입된 이메일입니다.</>)
  //     }
  //     else
  //       navigate('/signup', { state: {email}})
  //   }
  //   function errFunc(e, code) {
  //     // 이외의 오류일경우
  //     setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
  //   }
  //   _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  // }

  // useEffect( () => {
  //   if ( _w.checkLogged() )
  //     navigate('home');
  // }, [])
  function getBirthStr(str)
  {
    const arr = str.split('T')[0].split('-');
    return `${arr[0]} 년 ${arr[1]} 월 ${arr[2]} 일 `;
  }

  function getNextPayStr(str)
  {
    console.log("지난 결제일 : ", str);
    const d = new Date(new Date(str).getTime() + 30 * 24 * 3600 * 1000);
    return getBirthStr(d.toISOString());
  }

  function withdraw()
  {
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_CANCEL_MEMBERSHIP}`;
    // 
    function okFunc(res) {
      console.log("CHECK RES : ", res);
      navigate('/cancelResult', { state: {contents}})
    }
    function errFunc(e, code) {
      // 이외의 오류일경우
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'DELETE',  null, okFunc, errFunc, null)
  }
  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러" />
  <meta property="og:description" content="회원 탈퇴" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>회원 탈퇴</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
{/* <!--netstream-wrap--> */}
<div className="netstream-wrap member">
  {/* <!--nets-header--> */}
  <header className="nets-header">
    <nav className="nets-nav">
      {/* <!--0723 HJ edit:s--> */}
      <h1 className="logo"><a href="#"><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
      {/* <!--0723 HJ edit:e--> */}
    </nav>
  </header>
  {/* <!--//nets-header--> */}
  {/* <!--netstream-conts--> */}
  <section className="netstream-conts">
    {/* <!--my-page-wrap--> */}
    <div className="my-page-wrap">
      {/* <!--cancle-account-wrap--> */}
      <div className="cancle-account-wrap">
        <h2>계정 해지</h2>
        <p className="my-msg">
          멤버십을 해지하시겠어요?<br />
          멤버십을 해지하시려면<br />아래의 해지 완료 버튼을 클릭하세요.
          <span className="date-txt">서비스 결제는 결제 주기 마지막 날인 <br /><em className="date-num">{contents && getNextPayStr(contents.dLASTPAY)}</em>에 해지됩니다.</span>
        </p>
        <div className="btn-wrap">
          <button onClick={withdraw} type="button" className="check-normal-bt">해지 완료</button>
          <button type="button" className="cancle-normal-bt">취소</button>
        </div>
      </div>
      {/* <!--//cancle-account-wrap--> */}
    </div>
    {/* <!--//my-page-wrap--> */}
  </section>
  {/* <!--//netstream-conts--> */}
<RoflerCommonFooter />
</div>
{/* <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default Withdraw;
