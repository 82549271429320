import React from "react";
import { Helmet } from "react-helmet";
// import '../css/ui.css';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";
import { useEffect } from "react";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import jQuery from "jquery";

window.$ = window.jQuery = jQuery;


export const VideoPlayerPage = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ JSLoaded, setJSLoaded ] = React.useState(false);
  const [ contents, setContents ] = React.useState(null);        // 홈 화면에 추천, 베스트, 찜 콘텐츠 세팅
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = (data) => {
    console.log("섭밋 데이터 : ", data);

    let body = 
    {
    }
    console.log("바디 데이터 : ", body);
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}`
    
    function okFunc(res) {
      // navigate('/signup2');
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'POST',  data, okFunc, errFunc, null)
  }

  function firstRender()
  {
    if ( JSLoaded )
      return;

    setContents(location.state.contents);

    // _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  }

  useEffect( () => {
    if ( contents===null ) return;
    console.log("계속 돌아?", contents, location.state.contents);
    var params = {
      target: "dev",
      url: `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_PLAY_VIDEO}${contents.nMOVIEID}`,
      viewID: contents.viewID,
      episodeID: contents.nEPID,
      memID: _w.getAvatarInfo()?.memID,
      get( what )
      {
        return this[what];
      }

    }
    loadJS(params);
  }, [contents])

  function letsPlay()
  {
    console.log("콘텐츠 : ", contents);
    // var params = new URLSearchParams(document.location.search);
    var src = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_PLAY_VIDEO}${contents.nMOVIEID}`;
  // setContents(location.state.contents);

    //init videojs
    var player = videojs('video', {
      controls: true,
      // muted: true,
      autoplay: true,
      loop: true,
    });

    //set video src
    player.src({
      src: src,
      type: "application/x-mpegURL"
    });

    // 360 비디오일 경우 video.js-vr set params
    // ffmpeg.wasm으로 metadata 읽으려고 했는데 크로스도메인 문제가 있어서 동작 안함.
    //모노면 '360', 'Sphere', or 'equirectangular'
    // 스테레오면 360_TB
    // var param_vr = params.get("vr");
    if ( contents && contents.b360VR.data[0] )
    {
      var param_vr = '360';
      switch( contents.sSHAPE )
      {
        case '1:1T&B':
          param_vr = '360_TB';
          break;
        case '2:1':
          param_vr = 'Sphere';
          break;
      }
      if (param_vr)
      {
        player.vr({
          projection: param_vr
        });
      }

    }
    // 해상도 선택기능 활성화
    player.hlsQualitySelector();

    //모바일인지 체크하기 위한 함수
    function detectMobile()
    {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];

      return toMatch.some((toMatchItem) =>
      {
        return navigator.userAgent.match(toMatchItem);
      });
    }

    // 재생 가능한 상태에서 처리할 것들
    player.one("canplay", function ()
    {
      // m3u일경우 서브 플리가 없으면 해상도 선택 끔.
      if (player.hls != null && player.hls.playlists.master.playlists.length <= 1)
      {
        player.hlsQualitySelector._qualityButton.hide()
      }
      // 모바일인지 체크. 모바일에선 upscaler가 느리고 크래시 빈도도 높음
      if (!detectMobile())
      {
        // 해상도가 720p 이하면 AI 업스케일링
        // video.js-vr과 같이 쓸 수 없다
        if (player.videoHeight() <= 720 && param_vr == undefined)
        {
          // vjsUpscaler is videoJSPlugin instance, ai model : https://cdn.vectorly.io/upscaler/docs/latest/global.html#NetworkParams
          var vjsUpscaler = player.vectorlyPlugin({
            token: "dbfbd7b5-1d7f-40ed-a083-79eee9183a00",
            networkParams: {
              name: 'residual_5k_3x',
              tag: 'general',
              version: '0'
            },
          });
          // 디버깅용 텍스트
          document.getElementById("video-status").innerHTML = "upscaling video";
        }
      }
    });
  }

  async function loadJS(params)
  {
    function loadScript(src)
    {
      return new Promise((resolve, reject) =>
      {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.onload = () =>
        {
          resolve();
        };
        script.onerror = () =>
        {
          console.log("Failed to load script", src);
          reject();
        };
        script.src = src;
        document.head.appendChild(script);
      });
    }

    var targetUrlList = {                     // 플레이어 클래스에서도 참조 가능하게 전역에 주입.
      test: "http://127.0.0.1:5500/rofler_VideoTest/HLSTest",
      relative: "./vp/",                       // 내부 개발용
      dev: "http://dev.rofler.io:4000/",       // 테스트 서버용
      real: "https://rofler.io/"               // 서비스용
    }

    if (document.querySelector("#error-vjs-no-js")) document.querySelector("#error-vjs-no-js").remove();
    // var params = new URLSearchParams(document.location.search);
    var targetUrl = targetUrlList.relative;
    var apiUrl = "http://devapi.rofler.io:30000/";
    var target = params.get("target");
    switch (target)
    {
      case "test":
        targetUrl = targetUrlList.test;
      case "real":
        //targetUrl = targetUrlList.real;
        apiUrl = "https://api.rofler.io/";
        break;

      case "dev":
        //targetUrl = targetUrlList.dev;
        break;

      case undefined:
      case null:
      case "internal":
        //targetUrl = targetUrlList.relative;
        break;
    }


    if (params.get("url")) 
      var mediaUrl = params.get("url").trim().replace(" ", "%20");

    await loadScript(targetUrl + "js/util.js");
    await loadScript(targetUrl + "js/rofler.js");

    var app = null;

    if (mediaUrl.includes(".m3u"))
    {
      await loadScript(targetUrl + "js/P2PVJSApp.js");
      app = new P2PVJSApp();
    } 
    else if (mediaUrl.includes(".mpd"))
    {
      await loadScript(targetUrl + "js/P2PSPlyrApp.js");
      app = new P2PSPlyrApp();
    } 
    else
    {
      await loadScript(targetUrl + "js/P2PSPlyrApp.js");
      app = new P2PSPlyrApp();
    }
    app.settings.url = mediaUrl;
    app.settings.targetUrl = targetUrl;
    app.settings.apiUrl = apiUrl;
    app.settings.target = target;
    app.settings.episodeID = params.get('episodeID');
    app.settings.viewID = params.get('viewID');
    console.log(app);
    window.__a = app;
    await app.Init();

  }

  // 이 함수는 이 페이지의 콘텐츠를 모두 표출한 후에 호출해야 함.
  useEffect( () => {
    firstRender();
  }, [JSLoaded])


  return (
    <>
<Helmet>
<meta charset="UTF-8" />
<meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
<meta property="og:type" content="website" />
<meta property="og:title" content="로플러" />
<meta property="og:description" content="로플러 비디오 플레이어" />
<meta property="og:url" content="" />
<meta property="og:image" content="" />
<meta name="mobile-web-app-capable" content="yes" />
<meta name="format-detection" content="telephone=no" />
<title>로플러 비디오 플레이어</title>
<link href="css/ui.css" rel="stylesheet" />
<link href="https://vjs.zencdn.net/7.14.3/video-js.css" rel="stylesheet" />
<link href="https://unpkg.com/@silvermine/videojs-quality-selector/dist/css/quality-selector.css" rel="stylesheet" />

{/* <script src="js/jquery.min.js"></script> */}
{/* <script src="js/jquery.bxslider.min.js"></script> */}
{/* <script src="js/ui.js"></script> */}
</Helmet>

{/* <div id="video-wrapper" className="video-player-wrap embed-responsive embed-responsive-16by9">
  <video id="video" className=" video-player embed-responsive-item video-js vjs-default-skin">
    <p className="vjs-no-js"> To view this video please enable JavaScript, and consider upgrading to a web browser
      that <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>
  </video>
</div> */}

  <div id="video-wrapper">
    <video id="video">
      <p id="vjs-no-js"> To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>.<hr /> </p>
      <div id="error-webrtc-data-channels" className="vjs-no-js"> WebRTC Data Channels API is not supported by your browser. P2P disabled.<br /> Read more at <a href="http://iswebrtcreadyyet.com/legacy.html" target="_blank">Is WebRTC ready yet?</a>.<hr /> </div>
      <div id="error-hls-js" className="vjs-no-js"> Your browser doesn't support hls.js engine. P2P disabled.<br />Read more at <a href="https://en.wikipedia.org/wiki/Media_Source_Extensions" target="_blank">Media Source Extensions</a>.<hr /></div>
    </video>
  </div>
  {/* <!-- 리다이렉트 막기 위한 더미 --> */}
  <iframe name="dummyframe" id="dummyframe" style="display: none;"></iframe>
  {/* <!-- 디버깅용 문단, 포함 되어야 함 --> */}
  <div id="debug-view">
    {/* <!-- 업스케일링등의 안내 문단 --> */}
    <p id="video-status"></p>
    {/* <!-- 데이터 전송 문단 --> */}
    <div className="tracker_container">
      <div id="chart_container">
        <div id="legend"></div>
        <div id="legend-totals"></div>
        <div id="y_axis"></div>
        <div id="chart"></div>
      </div>
      <div id="graph"></div>

    </div>
    <div id="announce" width="300">
      <p>Trackers:...................</p>
    </div>
  </div>


{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { return setDimmer(null); }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default VideoPlayerPage
