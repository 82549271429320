import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";
import RoflerCompanyMenu from "../component/RoflerCompanyMenu";
import RoflerCompanyMenuLogo from "../component/RoflerCompanyMenuLogo";

export const Terms = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const navigate = useNavigate();

  const schema = Yup.object({
      username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
      password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
    });
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
  } = useForm( {
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });
  // const letsStart = () => {
  //   if ( email.length==0 ) // 메일 주소가 적혀 있다면 가입되었는지 확인
  //   {
  //     // navigate('/signup', { state: {email}})
  //     navigate('/signup');
  //   }

  //   const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}${email}`;
    
  //   function okFunc(res) {
  //     console.log("CHECK RES : ", res);
  //     if ( res.data?.user ) {
  //       setDimmer(<>이미 가입된 이메일입니다.</>)
  //     }
  //     else
  //       navigate('/signup', { state: {email}})
  //   }
  //   function errFunc(e, code) {
  //     // 이외의 오류일경우
  //     setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
  //   }
  //   _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  // }

  useEffect( () => {
    // if ( _w.checkLogged() )
    //   navigate('home');
  }, [])

  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러 고객센터" />
  <meta property="og:description" content="로플러 고객센터" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>고객센터</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
      <div className="netstream-wrap member">
        {/* <!--nets-header--> */}
        <header className="nets-header">
          <RoflerCompanyMenuLogo />
        </header>
        {/* <!--//nets-header--> */}
        {/* <!--netstream-conts--> */}
        <section className="netstream-conts">
          {/* <!--my-page-wrap--> */}
          <div className="my-page-wrap terms">
            {/* <!--my-page-menu-wrap--> */}
            <RoflerCompanyMenu set="3" />
            {/* <!--//my-page-menu-wrap--> */}
            <div className="policy-wrap">
              <p className="policy-desc">
                Rofler는 인터넷 연결 지원 컴퓨터 및 기타 디바이스(' Rofler 지원 디바이스')에서 인터넷 스트리밍을 통해 회원에게 영화(' Rofler 콘텐츠')를 제공하는 블록체인 기반의 P2P스트리밍 서비스로, P2P 기술을 접목하여 동의한 유저들은 스토리지와 네트워크 대역폭을 CDN대신 할애하는 방식으로 스트리밍 서버 비용을 절감하고 유저들에게 이를 포인트로 돌려주며 제작사들이 저렴한 비용으로 영상을 제공하는 신개념의 OTT영상 플랫폼입니다.
                <br />
                본 이용 약관은 사용자가 Rofler 서비스를 이용하는 데 적용됩니다. 본 이용 약관에 언급되는 ' Rofler 서비스' 또는 '당사 서비스'는 모든 특성과 기능, 웹사이트, 모바일에서의 사용자 인터페이스뿐만 아니라 Rofler 서비스와 관련된 모든 콘텐츠와 소프트웨어를 포함하는 스트리밍 서비스를 의미합니다.
              </p>
              <dl>
                <dt>1. 멤버쉽</dt>
                <dd>
                  1.1. Rofler 멤버쉽은 해지 시까지 지속되며,  Rofler 서비스를 이용하려면 인터넷 액세스 및 Rofler를 지원하는 디바이스가 필요합니다.
                </dd>
                <dd>
                  1.2. 회원의 Rofler 멤버쉽과 관련된 세부 사항은 Rofler 웹사이트에서 확인할 수 있습니다.
                </dd>
              </dl>
              <dl>
                <dt>2. Rofler요금제</dt>
                <dd>2.1. 회원은 Rofler 멤버십에 가입하는 과정에서 월정액으로 지불 방식과 VOD 컨텐츠를 별도로 구입할 수 있는 방식을 선택할 수 있습니다. 즉, 회원은 가입과 동시에 Rofler의 유료 서비스를 구입하는 것입니다.
                </dd>
                <dd>2.2. Rofler는 회원 가입 시 사용한 디바이스 ID, 결제 수단, 이메일 주소 등과 같은 정보를 이용 자격 판단에 활용할 수 있으며, 다른 프로모션과 함께 사용할 경우 특정한 제한이 가해질 수 있습니다.</dd>
              </dl>
              <dl>
                <dt>3. 결제 및 해지</dt>
                <dd>
                  3.1. (결제 방법) Rofler 서비스 월정액 요금과 서비스 이용과 관련해 발생하는 기타 청구 금액(예: 세금, 거래 수수료 등)은 Rofler ‘계정’ 페이지에 표시된 결제일에 결제 금액이 청구됩니다. 결제 일은 회원이 Rofler 가입 일자와 서비스 이용 유형에 따라 결정됩니다.
                </dd>
                <dd>
                  3.2. (결제 수단 제공 및 제한) Rofler 서비스의 원활한 사용을 위하여 회원은 하나 이상의 결제 수단을 제공해야 합니다. 미결제 금액에 대한 책임은 회원에게 있으며, 유효기간 만료, 잔고 부족 등과 같은 회원 개인의 사유로 결제가 정상적으로 처리되지 않는다면 유효한 결제 수단에 청구가 완료될 때까지 회원의 서비스 이용이 제한될 수 있습니다. 일부 결제 수단의 경우, 발급자가 회원에게 해외 거래 수수료나 기타 결제 수단 처리 관련 수수료 등의 수수료를 청구할 수 있으니, 자세한 사항은 결제 수단 서비스 제공업체를 통해 확인하시기 바랍니다.
                </dd>
                <dd>
                  3.3. (요금제 해지) 회원은 언제라도 Rofler 멤버십을 해지할 수 있으며, 이 경우 결제한 요금제 기간만큼은 Rofler 서비스를 계속 이용할 수 있습니다.
                </dd>
                <dd>
                  3.4. (환불 요청) 회원이 결제일로부터 7일 이내 Rofler 콘텐츠를 시청하지 않고 Rofler 멤버십을 해지하는 경우, 회원은 해당 결제 주기에 청구된 멤버십 요금을 전액 환불 요청할 수 있습니다.
                </dd>
              </dl>
              <dl>
                <dt>4. Rofler 서비스</dt>
                <dd>4.1. Rofler는 현재 베타 버전으로 서비스되고 있어 일부 기능이 지원되지 않거나 제한될 수 있습니다.</dd>
                <dd>4.2. Rofler 서비스는P2P스트리밍(rofler)를 통해 온라인 스트리밍 서비스를 할 수 있으며 P2P를 활용한 서버 비용의 절감을 통해 발생된 수익은 유저들에게 포인트로 보상해줍니다. 이와 관련한 포인트 정산, 사용내역, 네트워크 대역폭을 활용한 정보가 바른손의 블록체인 기술을 활용하여 투명하게 관리됩니다. </dd>
                <dd>4.3. Rofler 서비스와 이 서비스를 통해 제공되는 모든 콘텐츠는 개인적, 비상업적 용도로만 사용해야 하며, 가구 구성원이 아닌 개인과 공유해서는 안 됩니다. Rofler 멤버십 가입 기간 동안 Rofler는 회원에게 Rofler 서비스에 액세스하고 Rofler 콘텐츠를 이용할 수 있는 제한적이고 비독점적이며 양도 불가능한 권한을 부여합니다. 앞서 언급한 권한을 제외하고는 어떠한 권리, 소유권 또는 이권도 회원에게 이전되지 않습니다. 또한 회원은 대중 공연이나 전시를 목적으로 Rofler 서비스를 이용해서도 안 됩니다.</dd>
                <dd>4.3. 회원은 Rofler가 서비스를 제공하고 Rofler 콘텐츠에 대한 라이선스를 허용한 지역 내에서만 해당 콘텐츠 이용이 가능합니다. 시청 가능한 콘텐츠는 수시로 변경될 수 있으며, Rofler 서비스는 정기적으로 업데이트됩니다.</dd>
                <dd>4.4. Rofler 콘텐츠는 지원되는 특정 디바이스에서 일시적으로 저장해 오프라인으로 시청할 수 있습니다('오프라인 콘텐츠'). 단, 계정당 허용되는 오프라인 콘텐츠 수, 오프라인 콘텐츠를 저장할 수 있는 디바이스의 최대 수, 오프라인 콘텐츠 시청을 시작해야 하는 기간, 오프라인 콘텐츠를 이용할 수 있는 기간 등에 제약이 따릅니다.</dd>
                <dd>4.5. 회원은 모든 관련 법률, 규칙, 규정 또는 Rofler 서비스 및 콘텐츠 사용과 관련된 기타 제한에 따라 Rofler 서비스 내의 모든 기능을 포함한 Rofler서비스를 이용하는 데 동의합니다. 회원은 Rofler 서비스에 포함되어 있거나 Rofler 서비스를 통해 획득한 콘텐츠와 정보를아카이브, 복제, 배포, 수정, 전시, 시연, 출판, 라이선스, 2차적 저작물로 생성, 판매 권유하거나 이용하지 않을 것에 동의합니다. 다만, rofler서비스를 위한 P2P 스트리밍 이용시에는 법을 위반하지 않는 범위 내에서 허용합니다.회원은 소프트웨어 바이러스나 기타 컴퓨터 코드, 파일이나 프로그램을 포함하여, Rofler 서비스와 관련된 컴퓨터 소프트웨어나 하드웨어 또는 통신 장비의 기능을 방해, 파괴 또는 제한하기 위해 설계된 자료를 업로드, 게시, 이메일 전송, 또는 다른 방식으로 발송, 전송해서는 안 됩니다. 회원이 본 이용 약관에 명시된 조항을 위반하거나 불법복제, 명의도용, 신용카드 부정 사용, 기타 이에 준하는 사기행위 또는 불법행위에 가담하는 경우, Rofler는 회원의 서비스 사용을 종료시키거나 제한할 수 있습니다.</dd>
                <dd>4.6. Rofler 소프트웨어는 ㈜바른손에 의해 자체 사용 목적으로 개발되었으며, Rofler 지원 디바이스를 이용해 Rofler로부터 콘텐츠를 합법적으로 스트리밍하고 시청하는 용도로만 사용할 수 있습니다. Rofler 소프트웨어는 디바이스와 매체별로 달라질 수 있으며, 기능도 디바이스마다 다를 수 있습니다. 회원은 Rofler 서비스 이용을 위해 타사 라이선스 계약의 대상인 타사 소프트웨어가 필요할 수 있음을 인지합니다. 회원은 Rofler 소프트웨어와 관련 타사 소프트웨어의 업데이트된 버전을 자동으로 다운로드하는 데에 동의합니다.</dd>
              </dl>
              <dl>
                <dt> 5. 비밀번호 및 계정 접근권한</dt>
                <dd>Rofler 계정을 생성하여 본인의 등록 결제 수단에 요금이 청구되는 회원(이하 '계정 소유자')은 Rofler 계정 사용으로 인한 모든 활동에 대해 책임이 있습니다. 계정해킹, 명의도용, 신용카드 부정사용이나 기타 이에 준하는 사기행위 또는 불법행위로부터 회원 및 Rofler 등을 보호하기 위해 Rofler는 회원 계정을 종료하거나 보류 조치를 취할 수 있습니다.</dd>
              </dl>
              <dl>
                <dt>6. 보증 및 책임의 제한</dt>
                <dd>Rofler는 안정적인 서비스 제공을 위해 노력합니다. 다만, Rofler의 책임 없이 서비스 중단이나 오류가 발생할 수 있습니다. Rofler는 고의 또는 과실로 인하여 회원이 입은 손해를 배상하되, 특별한 사정으로 통상적인 범위를 벗어나는 손해는 Rofler의 고의 또는 중대한 과실을 제외하고는 책임지지 않습니다.</dd>
              </dl>
              <dl>
                <dt>7. 기타</dt>
                <dd>7.1. (준거법) 본 이용 약관은 대한민국 법률의 적용을 받고 그에 따라 해석됩니다.</dd>
                <dd>7.2. (불원 자료) Rofler는 Rofler 콘텐츠와 관련된 불원 자료 또는 아이디어를 인정하지 않으며, Rofler 콘텐츠 또는 다른 미디어상의 프로그램과 Rofler에 전송된 자료 또는 아이디어의 유사성에 대해 책임지지 않습니다.</dd>
                <dd>7.3. (고객 센터) Rofler 서비스 및 기능에 대한 자세한 정보를 확인하고자 하거나 계정과 관련하여 도움이 필요한 경우 Rofler 홈페이지에 제공된 이메일(support@barunson.co.kr)로 문의주시기 이용하시기 바랍니다.</dd>
                <dd>7.4. (이용 약관의 변경 및 양도) Rofler는 수시로 본 이용 약관을 변경할 수 있습니다. Rofler는 변경 사항이 적용되기 최소 30일 이전에 관련 내용을 회원에게 통지합니다. Rofler는 회원과의 계약(관련 권리 및 의무 포함)을 관련 법률에서 정한 절차에 따라 양도 또는 이전할 수 있으며, 회원은 이에 동의하지 않는 경우 언제라도 Rofler 멤버십을 해지할 수 있습니다.</dd>
                <dd>7.5. (전자적 커뮤니케이션) Rofler는 회원 계정과 관련한 정보(예: 결제 승인, 청구서, 비밀번호 또는 결제 수단 변경, 확인 메시지, 공지)를 회원이 등록 당시 기재한 이메일 주소로 이메일을 전송하는 등의 전자적 형태로만 통지합니다.</dd>
              </dl>
            </div>
            {/* <!--btn-wrap--> */}
            <div className="btn-wrap">
              {/* <button type="button" className="check-normal-bt">인쇄</button> */}
            </div>
            {/* <!--//btn-wrap--> */}
          </div>
          {/* <!--my-page-wrap--> */}
        </section>

  <RoflerCommonFooter />
</div>
        {/* <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default Terms;
