import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";
import RoflerCompanyMenu from "../component/RoflerCompanyMenu";
import RoflerCompanyMenuLogo from "../component/RoflerCompanyMenuLogo";

export const PrivacyPolicy = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const navigate = useNavigate();

  const schema = Yup.object({
      username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
      password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
    });
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
  } = useForm( {
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });
  // const letsStart = () => {
  //   if ( email.length==0 ) // 메일 주소가 적혀 있다면 가입되었는지 확인
  //   {
  //     // navigate('/signup', { state: {email}})
  //     navigate('/signup');
  //   }

  //   const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}${email}`;
    
  //   function okFunc(res) {
  //     console.log("CHECK RES : ", res);
  //     if ( res.data?.user ) {
  //       setDimmer(<>이미 가입된 이메일입니다.</>)
  //     }
  //     else
  //       navigate('/signup', { state: {email}})
  //   }
  //   function errFunc(e, code) {
  //     // 이외의 오류일경우
  //     setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
  //   }
  //   _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  // }

  useEffect( () => {
    // if ( _w.checkLogged() )
    //   navigate('home');
  }, [])

  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러 고객센터" />
  <meta property="og:description" content="로플러 고객센터" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>고객센터</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
      <div className="netstream-wrap member">
        {/* <!--nets-header--> */}
        <header className="nets-header">
          <RoflerCompanyMenuLogo />
        </header>
        {/* <!--//nets-header--> */}
        {/* <!--netstream-conts--> */}
        <section class="netstream-conts">
          {/* <!--0910 HJ edit:s--> */}
          {/* <!--my-page-wrap--> */}
          <div class="my-page-wrap terms">
            {/* <!--my-page-menu-wrap--> */}
            <div className="my-page-menu-wrap">
              <a href="#" className="m-my-page-menu">메뉴</a>
              {/* <!--my-page-menu--> */}
              <ul className="my-page-menu">
              <li><a href="#">고객센터</a></li>
              <li><a href="#">문의등록</a></li>
              <li><a href="#">이용약관</a></li>
              <li className="on"><a href="#">개인정보처리방침</a></li>
              </ul>
              {/* <!--//my-page-menu--> */}
            </div>
            {/* <!--//my-page-menu-wrap--> */}
            <div className="policy-wrap">
            <p className="policy-desc">
            Rofler (이하 “Rofler”)는 고객(회원 및 비회원 포함)님의 개인정보를 적극적으로 보호하며 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 모든 관련 법령을 준수하기 위하여 개인정보 처리방침(이하 “개인정보처리방침")을 제정하고 이를 준수하고 있습니다. 또한 아래에 게시된 개인정보보호 정책을 통하여 고객님이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 설명합니다.
            </p>
            <div className="policy-order">
            본 개인정보 처리방침은 회사가 운영하는 서비스에 공개되며, 고객님이 언제나 쉽게 찾아볼 수 있도록 하고 있습니다.회사의 개인정보 처리방침은 다음의 내용을 포함합니다.
              <ul className="order-lst">
              <li>
              1.  개인정보의 수집/이용 등에 대한 동의방법
              </li>
              <li>
              2.  수집하는 개인정보 항목 및 개인정보의 수집/이용 목적</li>
              <li>
              3.  개인정보의 보유, 이용기간 및 파기
              </li>
              <li>
              4.  개인정보의 제3자 제공 및 공유에 대한 동의
              </li>
              <li>
              5.  개인정보에 대한 고객의 권리와 그 행사 방법
              </li>
              <li>
              6.  개인정보의 열람, 증명 또는 정정
              </li>
              <li>
              7.  개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</li>
              <li>
              8.  개인정보의 수집과 이용 또는 제공에 대한 동의 철회</li>
              <li>
              9.  만14세 미만 아동의 개인정보 보호
              </li>
              <li>
              10. 고객 의견수렴 및 불만처리
              </li>
              <li>
              11. 개인정보의 기술적, 관리적 보호
              </li>
              <li>
              12. 개인정보 침해 관련 상담 및 신고
              </li>
              <li>
              13. 개인정보 처리방침의 고지 또는 민원 처리
              </li>
              <li>
              14. 본 개인정보 처리방침의 변경
              </li>
              </ul>

            </div> 
            {/* <!-- policy-order --> */}
            <dl>
              <dt>1. 개인정보수집/이용 등에 대한 동의방법</dt>
              <dd>
              고객님의 개인정보 수집, 이용, 제3자 제공 및 처리업무의 위탁 등에 대한 동의의 방법은 서비스 회원가입 시 PC 웹, 모바일 웹/앱 홈페이지(http://rofler.io)에 게시된 ‘서비스 이용약관’ 및 '개인정보처리방침'을 숙지하신 후 홈페이지에서 제공하는 ‘동의 버튼’을 클릭함으로써 완료됩니다.<br /><br />
              계정 또는 고객 센터 문의 방법과 관련해 질문이 있는 경우 로플러 홈페이지(rofler.io)에 접속해 Rofler 온라인 고객 센터를 방문하시기 바랍니다.본 개인정보 처리방침에 대한 질문이나 Rofler의 회원 개인정보, 쿠키 또는 유사 기술 이용에 관해 질문이 있는 경우 이메일(support @barunson.co.kr)로 문의하시기 바랍니다.
              회원의 개인정보 데이터를 관리하는 업체는 ㈜바른손입니다.회원이 Rofler에 도움을 요청하는 경우, 요청 사항을 해결하기에 앞서 회원 및 Rofler의 안전을 위해 신원 확인을 요구할 수도 있음을 참고해 주시기 바랍니다.
              </dd>
            </dl>
            <dl>
            <dt>
            2. 수집하는 개인정보 항목 및 개인정보의 수집/이용 목적
            </dt>
            <dd>
            <span className="tit">수집/이용 목적</span>
            Rofler는 Rofler 서비스 및 마케팅 활동을 제공, 분석, 관리, 개선, 회원의 등록, 주문 및 결제를 처리하고, 다양한 주제에 관하여 회원들과 소통하기 위해 정보를 이용합니다.예를 들어, 다음 목적으로 해당 정보를 이용합니다.
            <ul>
              <li>•   Rofler 시스템 보안을 확립하고, 부정행위를 방지하고, Rofler 계정의 보안을 유지하기 위해</li>
              <li>•   사기 등 법을 위반하거나 금지될 가능성이 있는 행위를 방지, 감지 및 조사하고, Rofler 약관(Rofler 가입 혜택 이용 자격 유무 및 이용 가능한 혜택의 종류 결정 그리고 특정 디바이스에서 Rofler 이용 약관에 따라 해당 계정 사용이 허용되는지 여부 결정 등)을 적용하기 위해</li>
              <li>•   사용자를 파악하고, 서비스(사용자 인터페이스 환경 및 서비스 품질 포함)를 개선하고, 콘텐츠 선정 및 배포를 최적화하기 위해</li>
              <li>•   Rofler 관련 뉴스, Rofler에서 이용 가능한 신규 기능 및 콘텐츠에 관한 상세 정보, 특별 혜택, 프로모션 공지, 고객 설문조사를 회원에게 전송하는 등 Rofler 서비스와 관련해 회원과 소통하고, 비밀번호 재설정 요청과 같은 운영 관련 요청 발생 시 고객을 지원하기 위해 이러한 소통은 이메일 등의 다양한 방식으로 이루어질 수 있습니다</li>
            </ul>
            </dd>
            <dd>
              <span className="tit">회원이 Rofler에 제공하는 정보</span>
              Rofler는 다음 정보를 포함해 회원이 Rofler에 제공하는 정보를 수집합니다.<br />
              회원의 이름, 이메일 주소, 생년월일, 결제 수단.Rofler는 회원이 Rofler 서비스 이용 과정에서 정보를 입력하는 경우, 고객 센터와 상담하는 경우 또는 설문 조사나 마케팅 프로모션에 참여하는 경우 등 다양한 방식을 통해 이러한 정보를 수집합니다.
              <br />
              회원계정 설정(웹사이트의 '계정' 섹션에 설정된 환경 설정 포함) 시 입력하는 정보 또는 Rofler 서비스나 타사를 통해 Rofler에 제공하는 정보를 수집합니다.
            </dd>
            <dd>
              <span className="tit">회원 정보 보유기간</span>
            회원 탈퇴 후 30일까지 보관합니다.
            </dd>
            <dd>
              <span className="tit">(선택) 기타 개인정보 수집항목</span>
              <span className="tit">수집/이용 목적</span>
              서비스 이벤트 및 업데이트 정보 제공, 타사와의 업무제휴를 통하여 제공하는 신상품 소개, 이벤트 안내 <br />
              ※ 고객님은 선택항목을 입력하지 않아도 서비스 가입이 가능하지만, 일부 서비스 제공에 제한이 있을 수 있습니다.
            </dd>
            <dd>
              <span className="tit">수집 방법</span>
              <ul>
              <li>• 회원 가입 및 서비스 이용과정에서 고객님이 개인정보 수집에 대해 동의하고 직접 정보를 입력하여 수집</li>
              <li>• 고객센터 이메일 등 고객님이 제시하는 개인정보 수집</li>
              <li>• 제휴 서비스 및 단체 등으로부터의 제공</li>
              <li>• 로그 분석 프로그램을 통한 생성정보 수집</li>
              <li>• 쿠키(cookie)에 의한 정보 수집</li>
              </ul>
            </dd>
            <dd>
              <span className="tit">서비스 이용 과정이나 사업처리 과정에서 생성되어 수집되는 정보</span>
              서비스 이용과정이나 사업처리 과정에서 이용요금 정산 및 개인 서비스 제공을 위하여 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
              <ul>
              <li>• 발/수신번호 및 통화시각</li>
              <li>• 사용도수, 서비스 이용시간 및 이용기록</li>
              <li>• 접속로그 및 접속IP정보, 쿠키</li>
              <li>• 결제기록(수단, 내역, 사용처)</li>
              <li>• 이용정지 기록</li>
              </ul>
            </dd>
            <dd>
              <span className="tit">서비스 이용과정에서 자동수집장치에 의해 수집되는 정보</span>
              서비스 이용 과정에서 자동수집장치에 의해 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
              <ul>
              <li>• 이동통신사정보</li>
              <li>• 단말기 모델명 및 단말식별번호(단말기 아이디)</li>
              <li>• OS(Android/iOS) 및 버전</li>
              <li>• PUSH 토큰</li>
              <li>• 단말접속일시</li>
              <li>• 세션아이디</li>
              <li>• 단말 MAC Address</li>
              <li>• 비식별 행태정보 (광고식별자 등)</li>
              </ul>
            </dd>
            <dd>
              ※ 자동수집장치에 의해 수집되는 정보는 개인을 식별할 수 없는 형태이며 회사는 수집된 정보를 바탕으로 개인을 식별하는 일체의 활동을 하지 않습니다.<br /><br />
              ※ 비식별 행태정보는 이용자의 인구통계학적 특성과 관심, 기호, 성향의 추정을 통한 서비스 제공•개선, 신규 서비스 개발, 맞춤형 콘텐츠 추천 및 관심기반 마케팅/광고 등에 활용하기 위한 목적으로만 사용됩니다.<br /><br />
              ※ 자동수집장치에 의해 수집되는 정보에 대한 내용과 수집거부 방법은 “10. 자동수집장치의 설치/운영 및 거부방법”을 참고하시기 바랍니다.
            </dd>
            </dl>

            <dl>
              <dt>3. 개인정보의 보유, 이용기간 및 파기</dt>
              <dd>
              <span className="tit">1) 이용기간 및 보유기간</span>
              고객님의 개인정보는 회사가 고객님에게 서비스를 제공하는 기간(이용기간) 또는 분쟁처리 기간(보유기간) 동안에 한하여 보유하고 이를 활용합니다.
              <ul>
              <li>가.이용기간: 서비스 가입일 ~해지일 까지</li>
              <li>나.보유기간: 불량회원의 부정한 이용의 재발 방지 및 서비스 혼선 방지를 위해 이용계약 해지일로부터 30일간 또는 해당 이용 목적이 종료된 때까지 보유</li>
              </ul>
              </dd>
              <dd>
              <span className="tit">2) 개인정보의 보관 및 보존기간</span>
              상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 개인정보를 보관합니다.이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
              <ul>
              <li>• 계약 또는 청약철회 등에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)</li>
              <li>• 대금결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)</li>
              <li>• 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래 등에서의 소비자보호에 관한 법률)</li>
              <li>• 접속에 관한 기록: 사이트 방문 기록 3개월(통신비밀보호법)</li>
              <li>• 그 외: 국세기본법 등에 따라 거래기록 등을 보관해야 하는 경우, 정보주체에게 별도 동의를 받은 경우</li>
              </ul>
              </dd>
              <dd>
              <span className="tit">3) 개인정보의 파기절차 및 방법</span>
              <ul>
              <li>가.파기절차: 고객님이 서비스 가입 등을 위해 제공하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 보유 사유에 따라 일정기간 저장된 후, 아래 다항에 명시한 방법으로 파기됩니다.별도 DB에 옮겨진 개인정보는 법률에 의해 보유되는 경우를 제외하고는 이외의 다른 목적으로 이용되지 않습니다.</li>
              <li>나.파기대상: 보유기간 및 관련법령에 따른 보존기간이 종료된 고객정보 </li>
              <li>다.파기방법
              <ol>
              <li>• 서면에 작성, 출력된 개인정보: 분쇄하거나 소각</li>
              <li>• DB 등 전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적 방법으로 삭제</li>
              </ol>
              </li>
              </ul>
              </dd>
            </dl>
            <dl>
              <dt>4. 개인정보의 제3자 제공 및 공유에 대한 동의</dt>
              <dd>
              회사는 고객님의 개인정보를 '서비스 이용약관' 또는 '개인정보처리방침'에서 명시한 범위 내에서 이용하며, 명시한 범위를 넘어서 개인정보를 이용하거나 제3자에게 제공하지 않습니다.다만, 고객님의 사전동의가 있거나, 관련 법령이 정한 규정과 절차에 따라 제공하는 경우는 예외입니다.단, 향후 개인정보/신용정보를 제공받는 제3자와의 신규계약 또는 해지 등 계약 변경에 따라 제3자는 변경될 수 있으며, 이 경우 홈페이지의 개인정보 처리방침 등을 통해 공지하는 등 관계법령에 따라 요구되는 절차를 준수하겠습니다.
              </dd>
              <dd>
              <span className="tit">정보의 공개</span>
              Rofler는 아래에 설명된 바와 같이 특정한 목적으로 제3자에게 회원 정보를 공개합니다.
              <ul>
                <li>•   Rofler 기업 그룹: Rofler는 Rofler 서비스 액세스 권한 부여, 고객 센터 지원 제공, 서비스 개선 관련 의사결정, 콘텐츠 개발 및 본 개인정보 처리방침의 '정보의 이용' 항목에 기술된 기타 목적에 필요한 경우 개발사인 ㈜바른손과 회원 정보를 공유합니다.</li>
                <li>•   블록체인 상의 공개: Rofler가 제공하는P2P기술을 활용하여 스토리지 대역폭을 할당해 준 유저들의 정보 및 사용정보는 블록체인 상에 투명하게 공개됩니다.</li>
                <li>•   서비스 제공업체: Rofler는 Rofler를 대신해 서비스를 수행하거나 Rofler를 도와 회원에게 서비스를 제공하는 작업에 다른 회사, 에이전트 또는 협력업체('서비스 제공업체')를 이용합니다.서비스를 제공하는 과정에서 서비스 제공업체는 회원의 개인정보 및 기타 정보에 접근할 수 있습니다.Rofler는 서비스 제공(서비스 유지 및 개선 포함)과 관련된 경우를 제외하고 서비스 제공업체가 회원의 개인정보를 사용하거나 공개하도록 허가하지 않습니다.</li>
                <li>•   파트너사: 위에서 설명한 것처럼, 회원은 한 곳 이상의 Rofler 파트너사와 계약 관계일 수 있습니다.이러한 경우 Rofler는 회원에게 Rofler 서비스를 제공하고 Rofler 서비스의 이용 가능 여부에 대한 정보를 제공하는 과정에서 파트너사와 협력하고자 특정 정보를 파트너사에 공유할 수 있습니다.</li>
                <li>•   프로모션 제공: Rofler는 회원의 참여를 위해 Rofler가 제3자와 회원 정보를 공유해야 하는 공동 프로모션이나 프로그램을 제공하기도 합니다.이러한 유형의 프로모션을 수행하는 과정에서 Rofler는 회원 이름 등의 프로모션 수행 관련 정보를 공유할 수 있습니다.이러한 제3자는 자체 개인정보 보호 정책을 준수할 책임을 지게 됩니다.</li>
                <li>•   Rofler 및 기타 당사자의 보호: Rofler와 서비스 제공업체는 (a) 관련 법률, 규정, 법적 절차 또는 정부 요청에 응하기 위해, (b) 이용 약관 위반 가능성에 대한 조사를 포함해 관련 이용 약관을 적용하기 위해, (c) 불법적이거나 위법성이 의심되는 행위(결제 사기 포함), 보안 또는 기술적 문제를 감지, 방지 또는 해결하기 위해, (d) 법률상 요구되거나 허용되는 범위에서 Rofler, Rofler 서비스 사용자 또는 대중의 권리, 재산 또는 안전에 대한 위해로부터 보호하기 위해, 회원의 개인정보 및 기타 정보의 공개가 필요하다고 판단하는 경우 해당 정보를 공개하고 이용할 수 있습니다.</li>
                <li>•   사업 양도: 조직개편, 구조조정, 합병 또는 매각, 기타 자산 양도와 관련해 정보 인수 당사자가 Rofler의 개인정보 처리방침과 일관된 방식으로 회원의 개인정보를 존중하기로 합의하는 경우에 한해 Rofler는 개인정보를 포함한 정보를 이전합니다.</li>
              </ul>
              </dd>
              <dd>
              Rofler는 정보 공유 과정 중 개인정보를 다른 국가로 이전할 때 항시 이러한 정보가 본 개인정보 처리방침에 따라, 데이터 보호에 관한 관련 법률의 허용 범위 내에서 이전되도록 최선을 다할 것입니다.또한 회원은 아래의 방식으로 본인 정보를 공개하기로 선택할 수 있습니다.
              <ul>
              <li>•   Rofler 서비스 중 일부는 회원의 스마트 디바이스에 설치된 클라이언트와 애플리케이션을 이용해 회원이 이메일 등의 공유 애플리케이션으로 정보를 공유할 수 있는 도구를 포함할 수 있습니다.</li>
              <li>•   회원은 소셜 플러그인과 기타 유사한 기술을 통해 정보를 공유할 수 있습니다.</li>
              </ul>
              </dd>
              <dd>
              소셜 플러그인 및 소셜 애플리케이션은 해당 소셜 네트워크에서 자체적으로 운영하므로 해당 업체의 이용 약관 및 개인정보 보호 정책이 적용됩니다.
              </dd>
              <dd>
              회원의 선택사항: 이메일
              </dd>
              <dd>
              Rofler로부터 이메일 등의 특정 커뮤니케이션의 수신을 원치 않는 경우 수신을 거부하시면 됩니다.계정 거래 관련 메시지와 같이 Rofler가 보내는 서비스 관련 커뮤니케이션은 수신 거부할 수 없음을 유의하시기 바랍니다.
              </dd>
            </dl>
            <dl>
            <dt>5. 개인정보에 대한 고객의 권리와 그 행사 방법</dt>
            <dd>고객님은 언제나 홈페이지(http://rofler.io)를 방문하여 등록되어 있는 자신의 개인정보를 조회하거나 Rofler가 보유하고 있는 오래되거나 부정확한 개인정보를 수정 또는 업데이트할 수 있으며, 개인정보의 수집과 이용, 위탁 또는 제공에 대한 동의의 철회 및 가입 해지를 요청할 수 있습니다.</dd>
            </dl>
            <dl>
            <dt>6. 개인정보의 열람, 증명 또는 정정</dt>
            <dd>
            Rofler는 서비스 사용자의 개인정보가 소실, 도난, 무단 액세스, 이용 및 수정되지 않도록 합리적인 방식으로 행정적, 논리적, 물리적 및 관리적 방법을 활용합니다.
            </dd>
            <dd> 1) 고객님은 홈페이지(http://rofler.io)에 제공된 고객센터 이메일을 통해 개인정보에 대한 열람, 증명을 요구할 수 있습니다.</dd>
            <dd>2) 고객님이 본인의 개인정보에 대한 열람, 증명을 요구하는 경우 고객님의 신분을 증명할 수 있는 주민등록증, 여권, 운전면허증(신형) 등의 신분증명(사본)을 제시 받아 본인 여부를 확인합니다.</dd>
            <dd>3) 고객님의 대리인이 열람, 증명을 요구하는 경우에는 대리 관계를 나타내는 위임장, 명의고객의 인감증명서와 대리인의 신분증명서 등의 증표를 제시 받아 대리인인지 여부를 확인합니다.</dd>
            <dd>4) 고객님께서 개인정보의 오류에 대해 정정을 요청하신 경우에는 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</dd>
            <dd>5) 단, 다음과 같은 경우에는 예외적으로 개인정보의 열람 및 정정을 제한할 수 있습니다.</dd>
            <dd>
              <ul>
              <li>가.본인 또는 제3 자의 생명, 신체, 재산 또는 권익을 현저하게 해할 우려가 있는 경우 </li>
              <li>나.당해 서비스 제공자의 업무에 현저한 지장을 미칠 우려가 있는 경우</li>
              <li>다.법령에 위반하는 경우</li>
              </ul>
            </dd>
            </dl>
            <dl>
              <dt>7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</dt>
              <dd>
              1) 쿠키란?<br />
              Rofler는 개인화되고 맞춤화 된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
              쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 파악하여 이용자의 환경설정을 유지하고 맞춤화 된 서비스를 제공하기 위해 이용됩니다.
              쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.
              </dd>
              <dd>2) 회사의 쿠키 사용 목적<br />
              회원이 방문한 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기-검색어, 보안접속 여부, 뉴스편집, 이용자 규모 등을 파악하여 회원에게 광고를 포함한 최적화된 맞춤형 정보를 제공하기 위해 사용합니다.
              </dd>
              <dd>
              3) 쿠키의 설치/운영 및 거부<br />
              이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.따라서 이용자는 웹_브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
              다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
              쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.
              <ul>
              <li>①[도구]메뉴에서[인터넷 옵션]을 선택합니다.</li>
              <li> ②[개인정보 탭]을 클릭합니다.</li>
              <li>③[개인정보처리 수준]을 설정하시면 됩니다.</li>
              </ul>
              </dd>
            </dl>
            <dl>
            <dt>8. 개인정보의 수집과 이용 또는 제공에 대한 동의 철회</dt>
            <dd>1) 고객님은 개인정보의 수집과 이용 또는 제공에 대한 동의를 철회할 수 있습니다.동의 철회를 위해서는 홈페이지에서 제공하는 ‘회원정보-회원 탈퇴’ 서비스에서 본인 확인 절차를 거친 후, 회원 탈퇴와 함께 동의를 철회할 수 있습니다.</dd>
            <dd>
            2) 만 14세 미만의 아동은 자신에 대한 정보를 다른 사람에게 함부로 공유하지 않으며, 보내기 전에 반드시 부모님(법정대리인)의 허락을 받아야 합니다.Rofler는 만 14세 미만 아동의 개인정보를 제3자인 다른 사람 또는 업체와 공유하지 않으며, 만 14세 미만의 아동에게는 광고메일을 발송하지 않습니다.만 14세 미만 아동의 경우, ‘서비스 이용약관’ 제8조 및 ‘개인정보처리방침’ 제9조에 의거, 부모님(법정대리인)의 가입 동의를 얻은 후 서비스를 이용할 수 있도록 하고 있습니다.10일 이내에 부모님(법정대리인)이 가입 동의를 하지 않으면 만 14세 미만의 아동의 가입 정보는 삭제되며, 회원가입이 해지됩니다.
            </dd>
            </dl>
            <dl>
            <dt>9. 만14세 미만 아동의 개인정보보호</dt>
            <dd>1) 만 14 세 미만 아동(이하 ‘아동’이라 함)의 회원가입은 개인정보 수집 시 반드시 법정대리인 (부모님)의 동의를 구하고 있습니다.법정대리인(부모님)의 동의 방법은 법정대리인(부모님)의 휴대폰과 전자우편으로 발송한 인증번호로 동의를 구하고 있습니다.또한 일정 서식을 다운받아 법정대리인(부모님) 서명을 거친 후 고객센터 이메일로 송부해주시면 됩니다.</dd>
            <dd>
            2) 회사는 법정대리인(부모님)의 동의를 받기 위하여 아동으로부터 법정대리인(부모님)의 이름과 연락처 등 최소한의 개인정보를 수집하고 있습니다.법정대리인(부모님)은 아동의 개인정보에 대한 열람, 정정 및 삭제를 할 수 있으며, 아동의 개인정보를 열람, 정정 및 삭제하고자 할 경우에는[회원정보수정]를 클릭하여 법정대리인(부모님)이 직접 하거나, 개인정보 관련 담당자에게 전화 또는 전자우편으로 요청하시면 필요한 조치를 취해 드립니다.
            </dd>
            <dd>
            3) 회사는 아동에 관한 개인정보를 제3 자에게 제공하거나 공유하지 않으며, 아동으로부터 수집한 개인정보에 대하여 법정대리인(부모님)이 오류의 정정을 요구하는 경우 그 오류를 정정할 때까지 해당 개인정보의 이용 및 제공을 금지합니다.또한 아동의 회원가입 신청 후, 10일이 지나도 법정대리인(부모님)의 동의가 없을 경우에는 아동 및 법정대리인의 개인정보는 지체 없이 모두 폐기됩니다.
            </dd>
            <dd>
            4) 아동 및 법정 대리인의 권리와 그 행사방법에 따른 '개인정보처리방침' 제6조 내지 제8조를 준용합니다.
            </dd>
            </dl>
            <dl>
            <dt>10. 고객 의견수렴 및 불만처리</dt>
            <dd>
            회사는 고객님의 의견을 매우 소중하게 생각합니다.고객님께서 문의사항이 있으실 경우, 회사 고객센터에 문의하시면 신속하고 정확한 답변을 드리겠습니다.
            <ul>
            <li>개인정보보호 책임자 및 담당자 연락처</li>
            <li>㈜바른손</li>
            <li>이메일: support @barunson.co.kr</li>
            </ul>
            </dd>
            </dl>
            <dl>
            <dt>11. 개인정보의 기술적, 관리적 보호</dt>
            <dd>
            Rofler는 고객님의 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 주요 개인정보는 중앙 서버에서 통합 관리되며, 블록체인에 등록되는 개인정보는 블록체인이 아닌 웹서버나 오프체인으로 저장합니다.
            </dd>
            </dl>
            <dl>
            <dt>12. 개인정보 침해 관련 상담 및 신고</dt>
            <dd>
            개인정보 침해에 대한 상담 및 신고가 필요하신 경우에는 개인정보 관련 담당자에게 이메일로 연락하시거나, 과학기술정보통신부 산하 공공기관인 한국인터넷진흥원(KISA) 내에 설치된 개인정보 침해 신고센터로 문의하시기 바랍니다.
            <ul>
            <li>☎ 개인정보침해신고센터 <br />
            전화: (국번없이)118 / 홈페이지: http://www.privacy.kisa.or.kr </li>
            <li>☎ 대검찰청 첨단범죄수사과 <br />
            전화: 02-3480-2000 / 홈페이지: http://www.spo.go.kr </li>
            <li>
            ☎ 경찰청 사이버테러대응센터 <br />
            전화: 02-392-0330 / 홈페이지: http://www.cyberbureau.police.go.kr/index.do</li>
            </ul>
            </dd>
            </dl>
            <dl>
            <dt>13. 개인정보 처리방침의 고지 또는 민원 처리</dt>
            <dd>
            개인정보보호 관련 법령의 개정 또는 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정할 수 있습니다.또한, 고객님 개인정보의 수집, 처리, 관리 등의 업무를 위탁하는 경우에는 홈페이지의 ‘개인정보처리방침’ 등을 통하여 그 사실을 고객님에게 고지합니다.고객님께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다.회사는 고객님의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
            </dd>
            </dl>
            <dl>
            <dt>14. 본 개인정보 처리방침의 변경</dt>
            <dd>Rofler는 법률적, 규제적 또는 운영상의 요구 사항이 변화함에 따라 필요시 본 개인정보 처리방침의 내용을 수정할 수 있습니다.Rofler는 법률에 의거하여 해당 변경 내용(효력 발생일 포함)을 통지합니다.변경 사항의 효력 발생일 이후 회원이 지속적으로 Rofler 서비스를 이용하는 경우, 해당 변경 사항을 인지하고 (해당하는 경우) 수락한 것으로 간주됩니다.본 개인정보 처리방침의 변경 내용을 인지 또는 수락하고자 하지 않을 경우, 회원은 Rofler 서비스 이용을 취소할 수 있습니다.</dd>
            </dl>
            </div>
            {/* <!--btn-wrap--> */}
            <div className="btn-wrap">
            <button type="button" className="check-normal-bt">인쇄</button>
            </div>
          {/* <!--//btn-wrap--> */}
          </div>
          {/* <!--my-page-wrap--> */}
          {/* <!--0910 HJ edit: e--> */}
        </section>


  <RoflerCommonFooter />
</div>
        {/* <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default PrivacyPolicy;
