import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";

export const RequestPWReset = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const navigate = useNavigate();

  const schema = Yup.object({
      email : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
    });
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
  } = useForm( {
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });

  // const letsStart = () => {
  //   const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}${email}`;
    
  //   function okFunc(res) {
  //     console.log("CHECK RES : ", res);
  //     if ( res.data?.user ) {
  //       setDimmer(<>이미 가입된 이메일입니다.</>)
  //     }
  //     else
  //       navigate('/signup', { state: {email}})
  //   }
  //   function errFunc(e, code) {
  //     // 이외의 오류일경우
  //     setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
  //   }
  //   _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  // }

  // useEffect( () => {
  //   if ( _w.checkLogged() )
  //     navigate('home');
  // }, [])

  function requestChange(data)
  {
    console.log("data : ", data);
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_RQUEST_PW_CHANGE}`;
    
    function okFunc(res) {
      console.log("CHECK RES : ", res);
        setDimmer(<>이메일을 성공적으로 보냈습니다.<br />이메일의 링크로 비밀번호를 변경해 주세요.</>)
    }
    function errFunc(e, code) {
      // 이외의 오류일경우
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'POST', data, okFunc, errFunc, null)
  }
  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러" />
  <meta property="og:description" content="로플러" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>비밀번호 변경 신청</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
{/* <!--netstream-wrap--> */}
<div className="netstream-wrap home">
  {/* <!--nets-header--> */}
  <header className="nets-header">
    <nav className="nets-nav">
      <h1 className="logo"><a href="#"><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
    </nav>
  </header>
  {/* <!--//nets-header--> */}
  {/* <!--netstream-conts--> */}
  <section className="netstream-conts">
    <div className="password-search-wrap">
      <h2>아이디/비밀번호를 잊으셨나요?</h2>
      <p className="desc">재설정 안내 이메일을 보내드리겠습니다.</p>
      <form onSubmit={handleSubmit(requestChange)}>
        <div className="input-txt">
          <input type="text" placeholder="이메일 주소" {...register('email')} />
          <div className="validation-txt">{errors.email?.message}</div>
        </div>
        <div className="btn-wrap">
          <button className="big-confirm-bt" type="submit" >이메일로 받기</button>
        </div>
      </form>
    </div>
  </section>
  {/* <!--//netstream-conts--> */}
  {/* <!--footer-wrap--> */}
  <div className="footer-wrap">
    <footer>
      {/* <!--footer는 공통이고 페이지 종류에따라 div 부모가 달라집니다--> */}
      <p><a href="#">고객센터(문의하기)</a></p>
      <p>회사 이름 &#47; 대표 ○○○ &#47; 회사 주소 &#47; 사업자등록번호 &#47; <a href="#">이용약관</a> &#47; <a href="#">개인정보처리방침</a></p>
    </footer>
  </div>
  {/* <!--//footer-wrap--> */}

<RoflerCommonFooter />
</div>
{/* <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default RequestPWReset;
