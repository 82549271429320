import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { useRoutes } from 'react-router-dom';
import routes from './routes';

function App() {
  const routing = useRoutes( routes );

  // console.log(routes);
  // console.log(routing);

  return ( <div>{ routing }</div> );
  // return (
  //   <div className="App">
  //       { routing }
  //       야야야App
  //   </div>
  // );
}

export default App;
