import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";
import RoflerCompanyMenu from "../component/RoflerCompanyMenu";
import RoflerCompanyMenuLogo from "../component/RoflerCompanyMenuLogo";

// let qaList = [
//   {
//     question: "회원가입 시 이메일 인증은 어떻게 하나요?",
//     answerTitle: "임시로 숫자 6개(121212)를 적어주세요.",
//     answer: " - 특수문자 및 알파벳 글자를 인증 코드에 적으면 작동이 안 됩니다. "
//   },
//   {
//     question: "로그인이 안 돼요.",
//     answerTitle: "이메일 및 비밀번호 입력이 올바르게 되었는지 확인해주세요.",
//     answer: " - 그럼에도 불구하고 로그인이 되지 않는다면 문의 등록 화면으로 이동하여 상세한 상황을 기재해 이메일로 제줄해주세요. | - 이메일로 로그인 문제 관련하여 제출 시 가입한 이메일 주소를 정확하게 적어주세요. "
//   },
//   {
//     question: "영상이 재생되지 않습니다.",
//     answerTitle: "영상 설치 프로그램을 제대로 설치했는지 확인해주세요.",
//     answer: " - 프로그램을 설치하였는데도 재생 되지 않는다면 영상 설치 프로그램에 로그인이 되었는지 확인해주세요. | - 영상 설치 프로그램 로그인 시 로플러 사이트 가입에 기재한 이메일과 일치해야 합니다. "
//   },
//   {
//     question: "360도 영상은 어떻게 시청하나요?",
//     answerTitle: "모바일과 웹 시청 이용 방법이 다릅니다.",
//     answer: " - 웹으로 360도 영상을 시청하실 경우 마우스 왼쪽 버튼을 누르고 마우스를 움직이면 영상이 회전됩니다. | - 모바일로 이용할 시 화면을 터치하여 손을 움직이면 영상이 회전됩니다. "
//   }
// ];

// let qaList = null;

const FAQCategory = (props) => {
  return (
    <>
      {props.qaList && props.qaList.map( (item, idx) => 
        { 
          if ( idx==0 ) return null;
          else
            return (
              <>
                <h3 className="qa-tit">{props.qaList[0].contents[idx-1].title}</h3>
                <ul className="qa-lst">
                  {item.contents.map( (i) => { return FAQLi(i); } ) }
                </ul>
              </>
            )
        } ) }
    </>
  )
}

const FAQLi = (props) => {
  const [ show, setShow ] = useState(false);
  // const _show = false;
  function click() { setShow(!show); }
  // function _click() { _show != _show; }
  return (
    <li>
      <a onClick={click} className={`qa-cont ${show?"show":""}`}>
        <dl>
          <dt>{props.question}</dt>
          <dd>
            <span className="answer-tit">
              {props.answerTitle}
            </span>
            <p className="answer-detail">
              {props.answer.split('|').map( (item) => (
                <>
                  {`${item}${''}`} <br />
                </>
              ))}
            </p>
          </dd>
        </dl>
      </a>
    </li>
  )
}

export const FAQ = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const [ qaList, setQAList ] = React.useState('');
  const navigate = useNavigate();

  // const schema = Yup.object({
  //     username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
  //     password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
  //   });
  // const { 
  //   register, 
  //   handleSubmit, 
  //   formState: { errors }, 
  // } = useForm( {
  //   reValidateMode: 'onChange',
  //   resolver: yupResolver(schema)
  // });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });
  const getFAQ = () => {
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_FAQ}`;
    
    function okFunc(res) {
      var category = res.data;
      for(var i=0; i<category.length; ++i)
      {
        var conts = category[i].contents;
        for(var j=0; j<conts.length; ++j)
        {
          conts[j].question = conts[j].title;
          var ansArr = conts[j].answer.split('|');
          conts[j].answerTitle = ansArr.shift();
          conts[j].answer = ansArr.join('|');
        }
      }

      setQAList( category );
    }
    function errFunc(e, code) {
      // 이외의 오류일경우
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  }

  useEffect( () => {
    // if ( _w.checkLogged() )
    //   navigate('home');
    getFAQ();
  }, [])

  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러 고객센터" />
  <meta property="og:description" content="로플러 고객센터" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>고객센터</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
      <div className="netstream-wrap member">
        {/* <!--nets-header--> */}
        <header className="nets-header">
          <RoflerCompanyMenuLogo />
        </header>
        {/* <!--//nets-header--> */}
        {/* <!--netstream-conts--> */}
        <section className="netstream-conts">
          {/* <!--my-page-wrap--> */}
          <div className="my-page-wrap">
            <RoflerCompanyMenu set="1" />
            {/* <!--search-wrap--> */}
            {/* <div className="search-wrap">
              <div className="input-txt">
                <input type="text" placeholder="어떤 도움이 필요하신가요?" />
              </div>
              <button type="button" className="btn-search"></button>
            </div> */}
            {/* <!--//search-wrap--> */}
            {/* <!--qa-lst-wrap--> */}
            <div className="qa-lst-wrap">
              {/* <h3 className="qa-tit">자주하는 질문</h3> */}
              {/* <!--qa-lst--> */}
              {/* <ul className="qa-lst">
                {qaList && qaList.map( (item) => { return FAQLi(item); } ) }
              </ul> */}
              <FAQCategory qaList={qaList} />
              {/* <!--//qa-lst--> */}
            </div>
            {/* <!--//qa-lst-wrap--> */}
          </div>
          {/* <!--//my-page-wrap--> */}
        </section>

  <RoflerCommonFooter />
</div>
        {/* <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default FAQ;
