import React from "react";
import { Helmet } from "react-helmet";
// import '../css/ui.css';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";
import { useEffect } from "react";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";


export const VideoPlayerLocalPage = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ contents, setContents ] = React.useState(null);        // 홈 화면에 추천, 베스트, 찜 콘텐츠 세팅
  const navigate = useNavigate();
  const location = useLocation();

  // const onSubmit = (data) => {
  //   console.log("섭밋 데이터 : ", data);

  //   let body = 
  //   {
  //   }
  //   console.log("바디 데이터 : ", body);
  //   const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}`
    
  //   function okFunc(res) {
  //     // navigate('/signup2');
  //   }
  //   function errFunc(e, code) {
  //     setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
  //   }
  //   _w.wiceanFetchLogin(url, 'POST',  data, okFunc, errFunc, null)
  // }

  useEffect( () => {
    if ( contents!==null ) return;
    setContents(location.state.contents);
    console.log("계속 돌아?", contents, location.state.contents);
  }, [contents])

  function getProjection()
  {
    // 360 비디오일 경우 video.js-vr set params
    // ffmpeg.wasm으로 metadata 읽으려고 했는데 크로스도메인 문제가 있어서 동작 안함.
    //모노면 '360', 'Sphere', or 'equirectangular'
    // 스테레오면 360_TB
    // var param_vr = params.get("vr");
    let param_vr = '';
    if ( contents && contents.b360VR.data[0] )
    {
      param_vr = '360';
      switch( contents.sSHAPE )
      {
        case '1:1T&B':
          param_vr = '360_TB';
          break;
        case '2:1':
          param_vr = 'Sphere';
          break;
      }
    }

    return param_vr;
  }

  return (
    <>
    {/* iframe에서 src=localhost로 두는 것은 사실 불가능함. */}
      <iframe width="500px" height="300px" src={`${process.env.REACT_APP_URL_PLAYER}/video_player.html?projection=${getProjection()}`} />

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { return setDimmer(null); }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default VideoPlayerLocalPage