import CryptoJS from "crypto-js";

// ------------------------------ for Rofler ------------------------------ ---
export function bpassAuth()
{
  let url = `${process.env.REACT_APP_URL_BPASS_BASE}${process.env.REACT_APP_URL_AUTH_VIEW_REQUEST}`;
  const { v1: uuidv1, v4: uuidv4, } = require('uuid');
  const state = uuidv4();
  // localstorage에 state를 저장
  saveLocalStorage("bpassstate", state);
  console.log("state + " + state);
  console.log("localstorage : " + window.localStorage.getItem('bpassstate'));
  const data = {
    service_id: process.env.REACT_APP_BPASS_SERVICE_ID,
    redirect_uri: encodeURIComponent(process.env.REACT_APP_URL_BPASS_REDIR),
    state: state,
    type: 1,
    // "0data": '0x11'
  }

  // _w.wiceanFetch(url, 'POST',  data, okFunc, errFunc, null)
  const p = Object.keys(data);
  url += "?" + p.map(i => `${i}=${data[i]}`).join('&');

  // alert(url);
  location.href = url;
}

export function getCustomerUID()
{
  const memID = getAvatarInfo()?.memID;
  return "rofler-" + memID;
}
export function getMerchantUID(cost, flagVOD)
{
  const memID = getAvatarInfo()?.memID;
  const customer_uid = "rofler-" + memID;
  const d = new Date().toISOString().split(/[-T:.]/);
  const datetime = d[0] + d[1] + d[2] + "-" + d[3] + d[4] + d[5];
  return `pay${flagVOD?'v':'s'}-${memID}-${cost}-${datetime}`;
}

export function isMobile(){
	// var UserAgent = navigator.userAgent;

	// if (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null)
	// 	return true;
	// else
	// 	return false;
  return true;
}

const __cryptKey = process.env.REACT_APP_KEY_FOR_WENCRYPT;
export const wEncrypt = (str) => 
{
  return CryptoJS.AES.encrypt(str, __cryptKey).toString();
}

export const wDecrypt = (str) =>
{
  try
  {
    const bytes = CryptoJS.AES.decrypt(str, __cryptKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  catch(e)
  {
    console.log("Decrypt시 오류");
    return '';
  }
}

export function parseJwt(token)
{
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    const inner = `00${c.charCodeAt(0).toString(16)}`;
    return `%${inner.slice(-2)}`;
    // return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function _t(t)
{
  if ( t && t.length>0 ) {
    return t.replace(/[.].*$/, '').split('T').join('_');
  }
  return '';
}

export function _l(str, obj)
{
  console.log(str);
  console.log(obj);
}

export function saveStorage(key, value) {
  window.sessionStorage.setItem(key, value);
}

export function loadStorage(key) {
  // _l(`key : ${key}`, window.sessionStorage.getItem(key));
  return window.sessionStorage.getItem(key);
}

export async function removeStorage(key) {
  await window.sessionStorage.removeItem(key);
}

export function saveLocalStorage(key, value) {
  window.localStorage.setItem(key, value);
}

export function loadLocalStorage(key) {
  // _l(`key : ${key}`, window.sessionStorage.getItem(key));
  return window.localStorage.getItem(key);
}

export async function removeLocalStorage(key) {
  await window.localStorage.removeItem(key);
}


export const wiceanGlobal = { 
  logged: false, 
  userInfo: null
};

export function checkLogged()
{
  const token = window.sessionStorage.getItem('access_token');
  // console.log( `Am I logged in? : ${token} ${token!=='undefined' && token!==null}`);
  return (token!=='undefined' && token!==null);
}

export function getAccessToken()
{
  return loadStorage('access_token');
}

export function setLogin(token)
{
  saveStorage('access_token', token);
}

export async function setLogout()
{
  await removeStorage('access_token');
}

export function getAvatarInfo()
{
  const at = getAccessToken();
  if ( at ) {
    return parseJwt(at);
  }
  return null;
}

export function getMyLevel()
{
  const me = getAvatarInfo();

  if ( me!==null ) {
    return me.level;
  }
  return 0;
}

// 외부 JS 로드
// JS를 순서대로 로드해야 하는 경우가 있어 callback함수를 실행하도록 함.
export function loadJS(path, callback, id)
{
  if ( id )
  {
    const el = document.getElementById(id);
    if ( el )
      el.parentNode.removeChild(el);
  }
  const script = document.createElement("script");
  script.src = path;
  script.async = true;
  if ( id )
    script.id = id;
  script.onload = () => {
    // this.scriptLoaded();
    if ( callback && typeof callback==='function')
      callback();
  }

  document.body.appendChild(script);
}

// WiceanReferrerPolicy = {
export const _RP = {
  NRWD: 'no-referrer-when-downgrade',
  NORE: 'no-referrer',
  ORIG: 'origin',
  OWCO: 'origin-when-cross-origin',
  SAOR: 'same-origin',
  STOR: 'strict-origin',
  SOWC: 'strict-origin-when-cross-origin',
  UNSF: 'unsafe-url'
}

/**
 * 
 * @param {*} _url 
 * @param {*} _method GET POST DELETE UPDATE CREATE PUT 
 * @param {*} _body     body of object, not string
 * @param {*} func 
 * @param bearerToken   bearer token for authentication
 * @param customHeader  custom headers in object format
 * @returns 
 */
export async function wiceanFetch(_url, _method, _body, func, errFunc, customHeader)
{
  __wiceanFetch(_url, _method, _body, func, errFunc, customHeader, {relayCode:false});
}

export async function wiceanFetchLogin(_url, _method, _body, func, errFunc, customHeader)
{
  __wiceanFetch(_url, _method, _body, func, errFunc, customHeader, {relayCode:true});
}

export async function wiceanFetchRelayCode(_url, _method, _body, func, errFunc, customHeader)
{
  __wiceanFetch(_url, _method, _body, func, errFunc, customHeader, {relayCode:true});
}


/**
 * relayCode : true일 경우 401의 handling을 외부에서 하겠다는 뜻.
 */
async function __wiceanFetch(_url, _method, _body, func, errFunc, customHeader, other)
{
  // const navigate = useNavigate();
  const option = {
    method: _method,
    headers: { 
      Accept: 'application/json',
      'Content-Type': 'application/json' 
      // 'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
  const accessToken = getAccessToken();
  // if ( bearerToken && typeof bearerToken==='string' && bearerToken.length>0 ) {
  // _l('access : ', accessToken);
  if ( accessToken ) {
    option.headers.Authorization = `Bearer ${accessToken}`;
  }
  if ( customHeader && typeof customHeader==='object' ) {
    option.headers = { ...option.header, ...customHeader };
  }

  _l('Option.header :', option.headers);
  let method = _method;
  switch (method)
  {
    case 'CREATE':
        method = 'POST';
        break;
    case 'UPDATE':
        method = 'PATCH';
        break;
    case 'READ':
        method = 'GET';
        break;
    default:
  }
  switch (method)
  {
    case 'PATCH':
    case 'POST':
    case 'DELETE':
    case 'PUT':
      if ( _body && typeof _body==='object' ) {
        option.body = JSON.stringify(_body);
        option.mode = 'cors';
        // option.referrerPolicy = _RP.UNSF;
      }
      break;
    case 'GET':
      if ( _body && typeof _body==='object' ) {
        _url += 
            "?" 
            + Object.keys(_body).map( 
                item => { 
                  return item + "=" + _body[item]; 
                }).join('&');
      }

    default:
  }

  console.log(`[I] ${method} "${_url}"`);

  const result = await fetch(_url, option)
  .then((response) => response.json())
  .then((res) => { 
    if ( res.statusCode && parseInt(res.statusCode, 10)===401) {
      // 로그아웃 되었을 때
      //  navigate('/');
      // session storage에서 jwt 토큰을 없앰.
      setLogout();
      if ( other.relayCode && errFunc )
      {
        const e = new Error(`${res.message}, status : ${res.statusCode}`);
        errFunc(e, res.statusCode);
      }
      else
      {
        alert('로그인에 실패했거나 로그인 상태가 아닙니다.');
        window.location.replace('/');
      }

      return;
    }
    console.log('result : ', res);
    if ( res.statusCode && parseInt(res.statusCode, 10)>299) // 오류 상황 발생
    {
      const e = new Error(`${res.message}, status : ${res.statusCode}`);
      if ( errFunc ) errFunc( e, res.statusCode );
      else alert(e.message);
      return;
    }
    if ( func ) func(res); 
  })
  .catch((e) => {
    console.error(`[E] ${_method} "${_url}" fetch중 오류 발생 : `);
    console.error(e);
    if ( errFunc ) {
      errFunc(e);
    }
    else {
      alert(e.message);
    }
  });

  return result;
}

export default function ____A() {}
