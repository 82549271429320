import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";

export const RoflerLanding = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const schema = Yup.object({
      // username : Yup.string().email("정확한 이메일 주소를 사용해 주세요").required("id로 email주소를 입력해 주세요"),
      // password: Yup.string().min(4, "8자 이상을 입력해주세요").required("패스워드를 입력해 주세요")
      username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
      password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
    });
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
  } = useForm( {
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });
  const letsStart = () => {
    if ( email.length==0 ) // 메일 주소가 적혀 있다면 가입되었는지 확인
    {
      // navigate('/signup', { state: {email}})
      // navigate('/signup');
      setDimmer(<>이메일 가입여부를 확인하기 위해<br />이메일을 입력해 주세요</>);
      return;
    }

    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}${email}`;
    
    function okFunc(res) {
      console.log("CHECK RES : ", res);
      if ( res.data?.user ) {
        setDimmer(<>이미 가입된 이메일입니다.</>)
      }
      else
        navigate('/signup', { state: {email}})
    }
    function errFunc(e, code) {
      // 이외의 오류일경우
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  }

  function emailInputKeyDownHandler(e)
  {
    if ( e.keyCode==13 )
      letsStart();
  }

  useEffect( () => {
    if ( _w.checkLogged() )
      navigate('home');
    console.log("location.state : ", location);
    if ( location.state && location.state.fromLogin )
      setDimmer(<>이메일을 입력하셔서<br />가입여부를 확인하시고<br />회원가입을 진행해 주세요</>);
  }, [])

  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러" />
  <meta property="og:description" content="로플러 홈" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>로플러 홈</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
{/* <!--netstream-wrap--> */}
<div className="netstream-wrap home">
  {/* <!--nets-header--> */}
  <header className="nets-header">
    <nav className="nets-nav">
      {/* <!--0723 HJ edit:s--> */}
      <h1 className="logo"><a href="#"><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
      {/* <!--0723 HJ edit:e--> */}
      <ul className="menu-lst">
        <li className="sign-bt"><button className="midium-confirm-bt" type="button" onClick={ () => {navigate('login')}}>로그인</button></li>
      </ul>
    </nav>
  </header>
{/* <!--//nets-header--> */}
{/* <!--netstream-conts--> */}
  <section className="netstream-conts">
    {/* <!--0910 add:s--> */}
    <div className="visual-main-img">
      <img src="images/home-bg.png" alt="메인 배경" />
    </div>
    {/* <!--0910 add:e--> */}
    <div className="main-visual-title-wrap">
      <h2>내가 좋아하는 영화가 있는 곳<br />
        ROFLER에서 ‘최애’ 영화를 찾으세요.</h2>

      <div className="tit-desc">
        <p>최애영화. 찾을 준비가 되셨나요?<br />
          아래 이메일 주소를 입력하고 멤버쉽에 가입하세요.</p>
      </div>
      <div className="email-start-wrap">
        <div className="email-start-input">
          <input type="text" placeholder="이메일 주소" onChange={(e) => {setMail(e.target.value)}} onKeyDown={emailInputKeyDownHandler}/>
        </div>
        <button type="button" className="input-bt" onClick={letsStart}><span>시작하기</span><span className="ico"></span></button>
      </div>
      <div className="tit-desc">
        <p style={{ fontSize: "1.1rem" }}>과학기술정보통신부와 한국인터넷진흥원의 2021년 블록체인 시범사업 지원을 받아 제작되었습니다.</p>
      </div>
    </div>
    <div className="sub-visual-wrap">
      <dl className="sub-conts">
        <dt>
          {/* <!--0905 img--> */}
          <img src="images/landing_01.png" alt="TV 프로그램" />
        </dt>
        {/* <!--0910 edit:s--> */}
        <dd>
          <div className="tit">참여하고 포인트를 획득하세요.</div>
          <p>
            P2P 네트워크에 기여하고 포인트를 획득하세요.<br />
            포인트는 어플내 다양하게 사용이 가능합니다.
          </p>
        </dd>
        {/* <!--0910 edit:e--> */}

      </dl>
      <dl className="sub-conts">
        <dt>
          {/* <!--0905 img--> */}
          <img src="images/landing_02.png" alt="Download 프로그램" />
        </dt>
        <dd>
          <div className="tit">모바일과 PC로 즐기세요.</div>
          <p>
            안드로이드 스마트폰, PC 웹에서
            <br />시청하세요. 애플은 곧 지원할 예정입니다.
          </p>
        </dd>
      </dl>
      <dl className="sub-conts">
        <dt>
          <img src="images/landing_03.png" alt="Download 프로그램" />
        </dt>
        <dd>
          <div className="tit">저렴한 수수료로 이용 가능</div>
          <p>저렴한 수수료로 요금제 이용 부담이 덜합니다.</p>
        </dd>
      </dl>
    </div>
  </section>
{/* <!--//netstream-conts--> */}
<RoflerCommonFooter />
</div>
        {/* <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default RoflerLanding;
