import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { Email } from "@material-ui/icons";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

/**
 * props :
 * - type : 1-Alert, 2-Confirm
 * - text : Confirm Dimmer의 내용 텍스트
 * - okfunc : ok 했을 때 호출할 함수
 * - nofunc : no 했을 때 호출할 함수
 * - show : true or false
 * 
 *** 사용법
 * const [ someState, setSomeState ] = React.useState( false );
 * ...
 * <Dimmer type="1" text={someState} okfunc={okFunction} nofunc={noFunction} />
 * 
 * 위와 같이 추가 후 어디선가 setSomeState( "텍스트" ) 를 이용하여 Dimmer를 활성화 한다.
 * 
 * @param {*} props 
 * @returns 
 */
export const Dimmer = (props) =>
{
  const [ flagOpen, setFlagOpen ] = React.useState(props.show?props.show:false); // 기본은 open
  const navigate = useNavigate();

  function okfunc()
  {
    console.log("ok 함수");
    if ( props.okfunc && typeof(props.okfunc=='function') )
      props.okfunc();
    setFlagOpen(false);
  }
  
  function nofunc()
  {
    if ( props.nofunc && typeof(props.nofunc=='function') )
      props.nofunc();
    setFlagOpen(false);
  }

  useEffect( () =>
  {
  }, []);

  return (
    <>
    {( (flagOpen || props.show ) && props.text) ? (
      <div className="dim-wrap">
        <div className="popup-wrap">
          <div className="popup">
            <div className="pop-conts">
              <p className="msg">
                {props.text}
              </p>
              <div className="pop-bt-wrap btns">
                <button className="pop-confirm-bt" onClick={okfunc}>확인</button>
                { props.type==1?'': <button className="pop-cancle-bt" onClick={nofunc}>취소</button> }
              </div>
            </div>
          </div>
        </div>
      </div>
    ):''}
    </>
  );
}

export default Dimmer;
