import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";
import RoflerCompanyMenu from "../component/RoflerCompanyMenu";
import RoflerCompanyMenuLogo from "../component/RoflerCompanyMenuLogo";
import Dimmer from "../component/Dimmer";

export const QNA = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const navigate = useNavigate();

  const schema = Yup.object({
      email: Yup.string(),
      title: Yup.string(),
      text: Yup.string(),
      // username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
      // password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
    });
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
  } = useForm( {
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues:
      {
        email: (_w.checkLogged() ? _w.getAvatarInfo().userID:''),
        title: '',
        text: '',
      },
  });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });
  // const letsStart = () => {
  //   if ( email.length==0 ) // 메일 주소가 적혀 있다면 가입되었는지 확인
  //   {
  //     // navigate('/signup', { state: {email}})
  //     navigate('/signup');
  //   }

  //   const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}${email}`;
    
  //   function okFunc(res) {
  //     console.log("CHECK RES : ", res);
  //     if ( res.data?.user ) {
  //       setDimmer(<>이미 가입된 이메일입니다.</>)
  //     }
  //     else
  //       navigate('/signup', { state: {email}})
  //   }
  //   function errFunc(e, code) {
  //     // 이외의 오류일경우
  //     setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
  //   }
  //   _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  // }

  useEffect( () => {
    // if ( _w.checkLogged() )
    //   navigate('home');
  }, [])

  function sendQNA(org)
  {
    const data = {
      title: org.title + "``" + org.email,
      contents: org.text
    }
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_QUESTION}${email}`;
    
    function okFunc(res) {
      setDimmer("문의가 등록되었습니다.");
      // console.log("CHECK RES : ", res);
      //   navigate('/signup', { state: {email}})
    }
    function errFunc(e, code) {
      // 이외의 오류일경우
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'POST',  data, okFunc, errFunc, null)

  }

  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러 고객센터" />
  <meta property="og:description" content="로플러 고객센터" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>고객센터</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
      {/* <!--netstream-wrap--> */}
      <div className="netstream-wrap member">
        {/* <!--nets-header--> */}
        <header className="nets-header">
          <RoflerCompanyMenuLogo />
        </header>
        {/* <!--//nets-header--> */}
        {/* <!--netstream-conts--> */}
        <section className="netstream-conts">
          {/* <!--my-page-wrap--> */}
          <div className="my-page-wrap">
            {/* <!--my-page-menu-wrap--> */}
            <RoflerCompanyMenu set="2" />
            {/* <!--//my-page-menu-wrap--> */}
            {/* <!--mypage-desc--> */}
            <p className="mypage-desc">
              문의주신 내용은 고객센터에서 확인 후 답변 드리도록 하겠습니다.<br />
              운영 시간: 평일 (월~금) 10: 00 ~ 19: 00
            </p>
            {/* <!--//mypage-desc--> */}
            {/* <!--inquiry-form--> */}
            <form className="inquiry-form" onSubmit={handleSubmit(sendQNA)}>
              <ul className="my-page-input-lst">
                <li>
                  <span className="info-txt">문의유형</span>
                  <div className="select-wrap">
                    <select>
                      <option>로그인</option>
                      <option>비밀번호 변경</option>
                      <option>해지 관련</option>
                    </select>
                  </div>
                </li>
                <li>
                  <span className="info-txt">답변 받을 이메일 주소</span>
                  <div className="input-txt">
                    <input type="text" {...register('email')} />
                  </div>
                </li>
                <li>
                  <span className="info-txt">제목</span>
                  <div className="input-txt">
                    <input type="text" {...register('title')} />
                  </div>
                </li>
                <li>
                  <span className="info-txt">설명</span>
                  <div className="textarea-wrap">
                    <textarea {...register('text')} ></textarea>
                  </div>
                </li>
              </ul>
              {/* <!--//inquiry-form--> */}
              {/* <!--btn-wrap--> */}
              <div className="btn-wrap">
                <button type="submit" className="check-normal-bt">제출</button>
              </div>
            </form>
            {/* <!--//btn-wrap--> */}
          </div>
          {/* <!--my-page-wrap--> */}
        </section>

  <RoflerCommonFooter />
  <Dimmer show={dimmer} text="문의가 등록되었습니다." okfunc={()=>{ navigate(-1); }} />
</div>
        {/* <!--//netstream-wrap-->    */}

{/* {dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
} */}
    </>
  );
};

export default QNA;
