// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
// import { Formik } from 'formik';
// import {
//   Box,
//   Button,
//   Container,
//   Grid,
//   Link,
//   TextField,
//   Typography
// } from '@material-ui/core';
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
// import { BarunsonTextField } from '../component/BarunsonTextField';
// import { BarunsonButton } from '../component/BarunsonButton';
// import React, {useState } from "react";
import React from "react";
import * as Yup from 'yup';
// import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { Email } from "@material-ui/icons";
// import axios from 'axios';
// import RoflerCommonFooter from "src/component/RoflerCommonFooter";
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { WSelect } from "../component/WComponents";

import * as _w from "../utils/wiceanUtil";
import { unstable_createPortal } from "react-dom";
import { Helmet } from "react-helmet";

// import WInput from "../component/WComponents";

const _op = (i, u) => { return {label: `${i} ${u}`, value: i}; };

export const RoflerSignupStep2 = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();


  // 사용하지 않는 함수
  const onSubmit = (data) => {
    console.log("섭밋 데이터 : ", data);

    let body = 
    {
    }
    console.log("바디 데이터 : ", body);
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}`
    
    function okFunc(res) {
      // navigate('/signup2');
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'POST',  data, okFunc, errFunc, null)
  }
  
  const registerSubscription = (memID, data) => {
    console.log("섭밋 데이터 : ", data);

    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_PAY_MONTHLY}${memID}`
    
    function okFunc(res) {
      console.log("정기 결제 등록 결과 : ", res);
      navigate('/signup3');
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
      console.log("정기 결제 등록 결과 오류 : ", e, code);
    }
    _w.wiceanFetchLogin(url, 'POST',  data, okFunc, errFunc, null)
  }


  function pay()
  {
    const res = confirm("결제를 계속 진행하시겠습니까?");
    const memID = `rofler-${location.state.memID}`;
    const customer_uid = "rofler-" + (location.state?`${location.state.memID}`:"0");
    const d = new Date().toISOString().split(/[-T:.]/);
    const datetime = d[0] + d[1] + d[2] + "-" + d[3] + d[4] + d[5];
    const merchant_uid = `pays-${memID}-${datetime}`;

    if ( res )
    {
      const payPayload = 
      {
        customer_uid,
        // pg: "html5_inicis",    // pg사가 다날 하나로 설정되어 있으므로 적지 않음. by 매뉴얼
        pay_method: "card",
        merchant_uid: merchant_uid,
        name: "로플러 정기결제",
        amount: process.env.REACT_APP_SUBSCRIBE_COST,
        buyer_email: location.state.body.email,
        buyer_name: location.state.body.name,
      } 
      console.log( "payload : ", payPayload);
      var IMP = window.IMP; // 생략 가능
      IMP.init("imp79723945"); // 예: imp00000000
      // 빌링키 발급 및 최초 결제를 함께 요청하는 케이스로 amount가 최초 결제 금액이 됨.
      IMP.request_pay(payPayload, 
        function (rsp) // 모바일 결제후 콜백
        {
          if (rsp.success)
          {
            console.log(rsp);
            // success시 리턴 JSON 구조
            // {
            //   apply_num: "49255789"
            //   bank_name: null
            //   buyer_addr: ""
            //   buyer_email: "aa@qq.com"
            //   buyer_name: "이이름"
            //   buyer_postcode: ""
            //   buyer_tel: ""
            //   card_name: "BC카드"
            //   card_number: "490220******9839"
            //   card_quota: 0
            //   currency: "KRW"
            //   custom_data: null
            //   customer_uid: "rofler-21"
            //   imp_uid: "imp_179199120944"
            //   merchant_uid: "pays-rofler-21-20211014-025638"
            //   name: "로플러 정기결제"
            //   paid_amount: 9900
            //   paid_at: 1634180355
            //   pay_method: "card"
            //   pg_provider: "danal_tpay"
            //   pg_tid: "202110141156391912944400"
            //   pg_type: "payment"
            //   receipt_url: "https://www.danalpay.com/receipt/creditcard/view.aspx?dataType=receipt&cpid=9810030929&data=FVp3pdsFwzueId88MxyqqCpfoYPa1wTUhVbRDs3xZYM3dsKz3jRiudeWi%2FJ35bBI"
            //   status: "paid"
            //   success: true
            // }
            // 빌링키 발급 성공
            // 서버에 확인할 때는 customer_uid와 merchant_uid만 필요하다.
            const returnBody = {
              // apply_num: rsp.apply_num,
              // card_name: rsp.card_name,
              // pay_method: rsp.pay_method,
              customer_uid: rsp.customer_uid,
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid,
            }

            registerSubscription(memID, returnBody);
          } else
          {
            setDimmer(<>결제에 실패했습니다. 다시 시도해 주세요</>)
            console.log(rsp);
            // 빌링키 발급 실패
          }
        })

      // navigate('/signup3');

    }
  }

  return (
    <>
<Helmet>
  {/* <!-- jQuery --> */}
  <script type="text/javascript" src="https://code.jquery.com/jquery-1.12.4.min.js" ></script>
  {/* <!-- iamport.payment.js --> */}
  <script type="text/javascript" src="https://cdn.iamport.kr/js/iamport.payment-1.2.0.js"></script>
  {/* jQuery 1.0 이상이 설치되어 있어야 합니다. */}
</Helmet>
{/* <!--netstream-wrap--> */}
<div className="netstream-wrap member">
    {/* <!--nets-header--> */}
    <header className="nets-header">
       <nav className="nets-nav">
            {/* <!--0723 HJ edit:s--> */}
           <h1 className="logo"><a href="#"><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
           {/* <!--0723 HJ edit:e--> */}
           <ul className="menu-lst">
               {/* <li className="sign-bt"><button className="midium-confirm-bt" type="button">Sign In</button></li> */}
           </ul>
       </nav>
    </header>
    {/* <!--//nets-header--> */}
    {/* <!--netstream-conts--> */}
    <section className="netstream-conts">
        {/* <!--join-wrap--> */}
        <div className="join-wrap">
            <span className="step-num">2/2 단계</span>
            <h2>
                광고 없이 무제한 영상 시청<br />
                멤버십은 언제든지 변경/해지 가능
            </h2>
             <div className="mebership-bx">
                <div className="mebership-conts">
                  <img src="images/rofler_membership.png" alt="rofler 멤버십" />
                  {/* <!--211013 add:s--> */}
                  <span className="type-txt">
                    로플러 기본 요금제
                  </span>
                  {/* <!--211013 add:e--> */}
                </div>
            </div>
            <div className="info-buy-bx">
                {/* <dl className="info-buy-conts">
                    <dt>월요금</dt>
                    <dd>9,900원</dd>
                </dl>
                <dl className="info-buy-conts">
                    <dt>VOD 콘텐츠</dt>
                    <dd>별도 구매</dd>
                </dl> */}
              <ul className="chk-lst">
                <li>최신 영화 감상</li>
                <li>영상 다운로드 지원</li>
                <li>모바일 앱 연동</li>
                <li>HD 화질 지원</li>
                <li>VOD 컨텐츠 별도 구매</li>
              </ul>
              <p className="info-buy-num">{parseInt(process.env.REACT_APP_SUBSCRIBE_COST).toLocaleString()}원</p>
              <p className="notice">
                본 상품은 구매 후 일정 시점이 경과하면 자동 결제가 진행되는 서비스입니다.<br />
                결제일로부터 7일 이내 이용 내역이 없을 경우 결제 취소 및 환불이 가능합니다.<br />
                VOD 컨텐츠 구매 시 최대 1일 동안 시청이 가능합니다.<br />
                해지 신청을 할 경우 다음 결제 예정일에 해지됩니다. <br />
                환불 관련 자세한 사항은 이용 약관을 참고해주세요.
              </p>
            </div>
            <div className="btn-wrap">
                <button className="big-confirm-bt" type="button" onClick={pay}>결제 하기</button>
            </div>
        </div>
        {/* <!--//join-wrap--> */}
    </section>
    {/* <!--//netstream-conts--> */}
    {/* <!--member-footer-wrap-->  */}
    {/* <div className="member-footer-wrap"> */}
      <RoflerCommonFooter />
    {/* </div> */}
    {/* <!--//member-footer-wrap--> */}
</div>
{/* // <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} class="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default RoflerSignupStep2;