// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
// import { Formik } from 'formik';
// import {
//   Box,
//   Button,
//   Container,
//   Grid,
//   Link,
//   TextField,
//   Typography
// } from '@material-ui/core';
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
// import { BarunsonTextField } from '../component/BarunsonTextField';
// import { BarunsonButton } from '../component/BarunsonButton';
// import React, {useState } from "react";
import React, { useCallback } from "react";
import * as Yup from 'yup';
import { Helmet } from "react-helmet";
// import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { Email } from "@material-ui/icons";
// import axios from 'axios';
// import RoflerCommonFooter from "src/component/RoflerCommonFooter";
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { WSelect } from "../component/WComponents";

import * as _w from "../utils/wiceanUtil";
import { unstable_createPortal } from "react-dom";
import { CircularProgress } from "@material-ui/core";
import jQuery from "jquery";
import { StayPrimaryPortraitRounded, WbIncandescentOutlined } from "@material-ui/icons";

window.$ = window.jQuery = jQuery;


// usage : ageDiv('AGEALL', 'span')
function ageDiv( ageStr, tag )
{
  let cls;
  let label;
  /*
  switch(ageStr)
  {
    case 'AGEALL':
        cls = 'age-limitAll';
        label = 'All';
        break;
    case 'AGE15':
        cls = 'age-limit15';
        label = '15';
        break;
    case 'AGE12':
        cls = 'age-limit12';
        label = '12';
        break;
    default:
  }
  */
  switch(ageStr)
  {
    case 'AGEALL':
        cls = 'age-limitAll';
        label = '전체이용가';
        break;
    case 'AGE15':
        cls = 'age-limit15';
        label = '15세';
        break;
    case 'AGE12':
        cls = 'age-limit12';
        label = '12세';
        break;
    default:
  }

  // return <span className={cls}>{label}</span>
  return label;
}

// import WInput from "../component/WComponents";
// 하단 콘텐츠 리스트의 개별 콘텐츠 항목을 렌더링.
function ContentsList(props)
{
  function descShorten( str )
  {
    const _l = 100;

    if ( str.length>_l )
      return `${str.substr(0, _l)}...`;
  }
  return (
    <>
      {(props.view === 1)?
    (
        <ul className="video-lst">
        { props.contents && props.contents.map( (item) => (
          <li className="video-bx">
            <a onClick={()=>{ props.ddFunc(item); }}>
              <img src={item.sPOSTERPATH} alt={item.sTITLE} title={item.sTITLE} />
              <span className="txt-info-bx">
                <em className="tit">{item.sTITLE}</em>
                <em className="item">{parseInt(item.nTIME/60)}분 | {ageDiv(item.cAGELIMIT, 'em')} | {item.cGENRE}</em>
                <em className="desc">{descShorten(item.tDESCRIPTION)}</em>
              </span>
            </a>
          </li>
        ))}
      </ul>
    ):
    (
        <ul className="video-conts-list">
        { props.contents && props.contents.map( (item) => (
          <li className="video-bx">
            <a onClick={()=>{ props.ddFunc(item); }}>
              <img src={item.sPOSTERPATH} alt={item.sTITLE} title={item.sTITLE} />
              <span className="txt-info-bx">
                <em className="tit">{item.sTITLE}</em>
                <em className="item">{parseInt(item.nTIME/60)}분 | {ageDiv(item.cAGELIMIT, 'em')} | {item.cGENRE}</em>
                <em className="desc">{descShorten(item.tDESCRIPTION)}</em>
              </span>
            </a>
          </li>
        ))}
      </ul>  
    )}
    </>
  )
}


export const HomeMain = (props) => {
  const [ popup, setPopup ] = React.useState(false);
  const [ dimmer, _setDimmer ] = React.useState(false);
  const [ spinner, setSpinner ] = React.useState(false);
  const [ detaildimmer, setDetailDimmer ] = React.useState(false);  // 상세 화면에 콘텐츠 세팅
  const [ JSLoaded, setJSLoaded ] = React.useState(false);
  const [ contents, setContents ] = React.useState(false);        // 홈 화면에 추천, 베스트, 찜 콘텐츠 세팅
  const [ maincontents, setMaincontents ] = React.useState(false); // 홈화면 상단 콘텐츠 세팅
  const [ recommended, setRecommended ] = React.useState(false); // 디테일 화면에 추천 아이콘
  const [ wishlisted, setWishlisted ] = React.useState(false); // 디테일 화면에 찜 아이콘
  const [ playcontents, setPlayContents ] = React.useState(false);        // 홈 화면에 추천, 베스트, 찜 콘텐츠 세팅
  const [ mode, setMode ] = React.useState(1);  // 화면 모드. 기본 1=보통 홈화면, 2=검색, 3=찜, 4=시청기록
  const [ prevmode, setPrevmode ] = React.useState(0);  // 바로 직전의 화면 모드. 화면 모드가 변하지 않았다면 화면 업데이트를 하지 않게 하기 위해.
  const [ searchResult, setSearchResult ] = React.useState(null);   // 검색 결과가 있을 경우 저장
  const [ wishResult, setWishResult ] = React.useState(null);  // 찜 리스트를 저장
  const [ historyResult, setHistoryResult ] = React.useState(null);  // 시청기록을 저장.
  const [ bestResult, setBestResult ] = React.useState(null);
  const [ recommendResult, setRecommendResult ] = React.useState(null);
  const [ customURI, setCustomURI ] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const moneyToPoint = 100;

  const [ tempContents, setTempContents ] = React.useState(null);  // 포인트/결제 선택창을 띄울 때 아직 playContents가 설정될 수 없어 임시로 저장하는 변수
  const [ showOption, setShowOption ] = React.useState(false);  // 구매 옵션(포인트, 카드결제)을 보여주는 메뉴

  function setDimmer( obj )
  {
    // document.body.focus();
    _setDimmer( obj );
  }



  // 사용하지 않는 함수
  const onSubmit = (data) => {
    console.log("섭밋 데이터 : ", data);

    let body = 
    {
    }
    console.log("바디 데이터 : ", body);
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}`
    
    function okFunc(res) {
      // navigate('/signup2');
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'POST',  data, okFunc, errFunc, null)
  }

  function goHome()
  {
    navigate('/');
  }

  function logout()
  {
    _w.setLogout();
    navigate('/');
  }

  function Mode1TopContentsPanel()
  {
    return (
    <>
      <div className="main-billboard-wrap">
        <div className="visual-main-img">
          <img src={maincontents && maincontents.sTRAILERPATH} alt="메인 비디오 이미지" />
        </div>
        <div className="billboard-info-wrap">
          {/* <div className="title-img">
          <img src="http://placehold.it/545x198" alt="메인타이틀" />
        </div> */}
          <div className="title-txt">{maincontents && maincontents.sTITLE}</div>

          <p className="info-items">{maincontents && Math.floor(maincontents.nTIME / 60)} 분 | {maincontents && getAgeString(maincontents.cAGELIMIT)} | {maincontents && maincontents.cGENRE}</p>
          <p className="info-desc">{maincontents && maincontents.tDESCRIPTION.substr(0, 100) + "..."}</p>
          <div className="info-bt-wrap">
            <button type="button" className="play-main-bt" onClick={() => { regView(maincontents); }}><span className="ico-play"></span>재생</button>
            <button type="button" className="detail-main-bt" onClick={() => { showDetail(maincontents); }}>상세 정보</button>
          </div>
        </div>
        <div className="sound-bt-wrap">
          {/* <button type="button" className="sound-bt"><span className="ico-sound"></span></button> */}
        </div>
      </div>
    </>
    );
  }

  // <VideoConts title="" conts="">
  function VideoConts(props)
  {
    return (
      <>
        <div className="video-conts">
          <h3 onMouseDown={props.listtype}>{props.title}</h3>
          {/* <!---video-lst-wrap--> */}
            <div className="video-lst-wrap">
              {(props.conts && props.conts.length>0)?(<ContentsList contents={props.conts} ddFunc={showDetail} view={props.viewmode}/>):
              ( <>
                <div className="no-lst-wrap">
                  <p className="none-txt">콘텐츠가 없습니다.</p>
                </div>
              </>)}
            </div>
          {/* <!---//video-lst-wrap--> */}
        </div>
      </>
    )
  }

  function Mode1BottomContentsList()
  {
    return (
      <>
        <div className="video-conts-wrap">
          {/* <!--video-conts--> */}
          <VideoConts title="찜한 콘텐츠" conts={contents.wishList} viewmode={1} listtype={(navigator.maxTouchPoints > 0)?null:clickWishlist} />
          <VideoConts title="베스트 콘텐츠" conts={contents.best} viewmode={1} listtype={(navigator.maxTouchPoints > 0)?null:clickViewBest} />
          <VideoConts title="추천 콘텐츠" conts={contents.recommend} viewmode={1} listtype={(navigator.maxTouchPoints > 0)?null:clickViewRecommend} />
          {/* <div className="video-conts">
            <h3>베스트 콘텐츠</h3>
            <div className="video-lst-wrap">
              {contents && <ContentsList contents={contents.best} ddFunc={showDetail} />}
            </div>
          </div>
          <div className="video-conts">
            <h3>추천 콘텐츠</h3>
            <div className="video-lst-wrap">
              {contents && <ContentsList contents={contents.recommend} ddFunc={showDetail} />}
            </div>
          </div> */}
        </div>
      </>
    )
  }

  /**
   * 화면에 보여질 전체 콘텐츠 로드
   * 로드가 끝나면 자바스크립트 시리즈를 호출한다. 
   * JS들이 이미 로드가 됐다면 호출돼선 안된다.
   * @param {*} onlyThisTime  이번만 그냥 렌더 하자... true/false
   * @returns 
   */
  function firstRender( onlyThisTime )
  {
    if ( !onlyThisTime && JSLoaded ) return;
    setJSLoaded(true);

    // home 화면에서만 back 버튼 클릭에 대한 처리를 한다. back이 인식되면 scrollable을 ture로.
    window.onpopstate = function (event)
    {
      // alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
      window.setBodyScrollable(true);
    };
    if ( !_w.checkLogged() )
    {
      console.log("로그인 필요");
      navigate("/login", { state: {url: '/'}});
    }
    const memID = _w.getAvatarInfo()?.memID;
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_HOME_CONTENTS}${memID}`
    
    function okFunc(res) {
      _w._l("First Render.");
      setContents( res.data );
      setMaincontents( res.data.best[0] );
      // loadJQ();
      loadBXSlider();
      setPopupIfExist(res.data.popup);
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)

  }

  function setPopupIfExist(url)
  {
    if ( url )
      setPopup(url);
// console.log("팝업 들어오고");
//     const img = new Image();
//     img.src = url;
//     img.onload = (() =>
//     {
// console.log("온로드 들어오고");
//       this.setPopup(url);
//     }).bind(this);
//     img.onerror = ((e) =>
//     {
// console.log("온에러 들어오고", e);
//     }).bind(this);
  }

  function loadJQ()
  {
    if ( JSLoaded ) return;
    // _w.loadJS('js/jquery.min.js', loadBXSlider);
    loadBXSlider();
  }
  function loadBXSlider()
  {
    _w.loadJS("js/jquery.bxslider.min.js", loadUI);
  }
  function loadUI()
  {
    _w.loadJS('js/ui.js', null, 'uijs');
  }

  function getAgeString( ageCode )
  {
    let ret;
    switch( ageCode )
    {
      case 'AGEALL':
          ret = "전체이용가";
          break;
      case 'AGE15':
          ret = '15세'
          break;
      case 'AGE12':
          ret = '12세'
          break;
      default:
    }
    return ret;
  }

  function prevDef(e) { e.preventDefault(); e.stopPropagation(); }
  function setBodyScrollable( flag )
  {
    if ( !flag )
    {
      document.body.classList.add('hidden');
      document.body.onscroll = prevDef;
      document.body.ontouchmove = prevDef;
      document.body.onmousewheel = prevDef;
    }
    else
    {
      document.body.classList.remove('hidden');
      document.body.onscroll = null;
      document.body.ontouchmove = null;
      document.body.onmousewheel = null;
    }

  }
  function showDetail(item)
  {
    setDetailDimmer(item);
    setBodyScrollable(false);
    getWishRecomm(item.nMOVIEID);
  }

  // 이 함수는 이 페이지의 콘텐츠를 모두 표출한 후에 호출해야 함.
  useEffect( () => {
    firstRender();
  }, [JSLoaded])

  useEffect( ()=>{
    // console.log("mode변경후 useEffect : " + mode)
    // try
    // {
    //     window.setBXSlider(); // this is defined in ui.js
    // }
    // catch(e)
    // {
    //   // loadBXSlider();
    //   loadUI();
    // }

    if ( location.state && location.state.login )
    {
      console.log("custom URI changed : " + customURI);
      setCustomURI(true);
      // location.state = null;
    }
  }, []);

  function setWishList( movieID )
  {
    if ( !_w.checkLogged() )
      navigate('/login');

    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_WISHLIST}${movieID}`;
    let protocol;
    if ( !wishlisted ) protocol = 'POST';  // 찜등록이 안되어 있다면 등록 
    else protocol = 'DELETE';             // 현재 찜등록이 되어 있다면 DELETE하도록
    
    function okFunc(res) {
      // 찜 표시하기
      if ( wishlisted ) setWishlisted(false) // 원래 찜이었다면 delete 됏을 것임 -> false
      else setWishlisted(true) // 찜 안돼 있었다면 true로 바꿈.
      firstRender( true );
      // loadUI();
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, protocol, null, okFunc, errFunc, null)
  }

  function setRecommend( movieID )
  {
    if ( !_w.checkLogged() )
      navigate('/login');

    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_RECOMMEND}${movieID}`;
    let protocol;
    if ( !recommended ) protocol = 'POST'; //원래 안추천이었으면 추천되도록
    else protocol = 'DELETE';             // 원래 추천이었으면 안추천 되도록
    
    function okFunc(res) {
      // 추천 아이콘 표기. toggle 되도록
      if ( recommended ) setRecommended(false) ;
      else setRecommended(true);
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, protocol, null, okFunc, errFunc, null)
  }

  function setSave( movieID )
  {
  }

  function setShare( movieID )
  {
  }

  /**
   * 추천과 찜이 체크되어 있는지 서버에 물어봄
   * @param {*} movieID 
   */
  function getWishRecomm( movieID )
  {
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_WISH_RECOMM}${movieID}`;
    function okFunc(res) {
      if ( res.data.wishlisted )
        setWishlisted(true);
      else
        setWishlisted(false);
      if ( res.data.recommended )
        setRecommended(true);
      else
        setRecommended(false);
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'GET', null, okFunc, errFunc, null)
  }

  // 플레이한 것으로 status 변경
  function updateViewStatus( viewID )
  {
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_VIEW_STATUS}${viewID}`;
    function okFunc(res) {
    }
    function errFunc(e, code) {
      // setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'PATCH', null, okFunc, errFunc, null)
  }

  // 서버에 준비 여부 확인
  function checkViewStatus( viewID, timeElapsed )
  {
    const contents = playcontents;
    console.log(contents);
    // view status API에는 viewID, PC/mobile 여부, 360여부가 인수로 들어간다.
    const PCorMobile = (_w.isMobile()?"MO":"PC");
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_VIEW_STATUS}${contents.viewID}/${PCorMobile}/${contents.b360VR.data[0]}`;

    function okFunc(res) {
      /*********************************************************************************/
      // PC일 경우와 Mobile일 경우에 따라 다르게 작동한다.
      // 1) PC / 360 : hls, 기존과 동일.
      // 2) PC / 2D
      // 3) Mo / 360 : hls, 
      // 4) Mo / 2D
      /*********************************************************************************/
      let wt = '';
      let url = '';
      let projection = '';
      if ( contents.b360VR.data[0] )
      {
        projection = '360';
        // 2021. 11. 30 이제 hls는 사용하지 않음
        // 2021. 12. 03 3D영상에서 DRM이 작동하지 않아 3d인 경우 hls가 다시 부활됨.
        url= `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_CDN_VIDEO}${contents.nMOVIEID}/1/movie.m3u8`; 
      }
      else
      {
        url = res.data.url; // 2021. 11. 30 이제 dash/drm으로 바뀜 . 2021. 12. 03 
        wt = res.data.drmtoken; // 360이 아닌 경우 DRM을 쓰므로 token을 전달함. 사실 contentID가 제대로 되지 않았으므로 서버에서 drmtoken이 제대로 오는지 모르겠다.
      }

      var params = {
        url: url,
        poster: contents.sPOSTERPATH,
        projection: projection,
        // p2p: _w.isMobile(), // mobile일 경우만 p2p옵션을 켜야 한다.
        p2p: false, // 2021-12-13 임시로 p2p를 무조건 false로.
        nMEMID: _w.getAvatarInfo()?.memID,
        episodeID: contents.nEPID,
        viewID: contents.viewID,
        JWT: _w.getAccessToken(),
        graph:false,
        widevineToken: wt,  // 2021. 12. 03 2D영상일 경우만 DRM을 사용한다.
        licenseUri: "https://license.pallycon.com/ri/licenseManager.do",

      }
      var keys = Object.keys(params);
      console.log("Player options : ", params)
      var paramStr = keys.map( (k)=>`${k}=${params[k]}` ).join('&') ;
      if ( _w.isMobile() )
      {
        // navigate('/player', { state: {contents:playcontents}});
        window.location.href='/video_player.html?' + paramStr;
        return;
      }
      // 이하는 PC일 경우
      if ( res.data.status==='VIEW1' ) // 영상이 준비됐다는 뜻. VIEW0은 등록, VIEW1은 준비됨, VIEW2는 영상 시청함.
      {
        updateViewStatus( viewID );
        setSpinner(false);
        let param = '?';
        
        if ( contents.b360VR.data[0] ) // 360 영상이라면 적합한 프로젝션 타입을 설정함.
        {
          var param_vr = '360';
          switch( contents.sSHAPE )
          {
            case '1:1T&B':
              param_vr = '360_TB';
              break;
            case '2:1':
              param_vr = 'Sphere';
              break;
            default: 
          }
          param += `projection=${param_vr}`
        }

        window.location = `${process.env.REACT_APP_URL_PLAYER}video_player.html?${paramStr}`;
      }
      else
      {
        if ( timeElapsed>20 ) // 20초가 지났는데도 플레이가안되면 뭔가 잘못된 것임.
        {
          setSpinner(false);
          navigate(-1);
          setDimmer(<>영상이 준비되지 않았습니다.</>);
        }
        // 영상이 준비되지 않았다면 다시 체크
        setTimeout(() => { checkViewStatus(viewID, timeElapsed+2); }, 2000);
      }
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
      setSpinner(false);
    }
    _w.wiceanFetch(url, 'GET', null, okFunc, errFunc, null)
  }

  // 시청 버튼을 누르면 view 테이블에 등록
  function checkDaemonInstalled( contents, viewID )
  {
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_DAEMON_INSTALLED}`;
    function okFunc(res) {
      if ( res.data.daemon )  // 대몬이 설치되었다면, 영상 플레이 로직 시작
      {
        // 설명을 올리고 spinner를 돌린다.
        setDimmer(<>영상 시청을 요청했습니다.<br />잠시만 기다려 주세요.</>);
        setSpinner(true);
        // regView( );
        setTimeout(() => { checkViewStatus(viewID, 0); }, 2000);
      }
      else    // 설치 되지 않았다면, 메시지를 띄우고 설치 유도
      {
        setPlayContents( false ); // playcontents가 변경되면 아래에서 useEffect가 수행될 것임.
        const downok = confirm('로플러 프로그램을 설치해야 p2p를 통한 원활한 시청이 가능합니다. 설치 파일을 다운로드 하시겠습니까?');
        if ( downok ) // 인스톨러 다운
        {
          setDimmer(<>로플러 설치 프로그램을 다운로드합니다.<br />다운로드한 파일을 이용해 <br />로플러를 설치하고 실행해 주세요. <br />로플러에서 로그인 후 다시 영상을 재생해 주세요.</>)
          window.location = 'RoflerInstall.exe';
        }
      }
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'GET', null, okFunc, errFunc, null)

  }

  function paypoint( contents )
  {
    const cost = contents.nVODPRICE;
    const res = confirm(cost * moneyToPoint + "점이 SSAK 포인트로 결제됩니다. 결제를 계속 진행하시겠습니까?");

    if ( res )
    {
      const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_PAY_VOD_POINT}`
      console.log("contents : ", contents);
      const data = {
        viewID: contents.viewID,
        episodeID: contents.nEPID
      }

      function okFunc(res)
      {
        console.log("VOD point 결제 등록 결과 : ", res);
        if ( res.statusCode==200 ) // 성공일 경우 플레이
        {
          // dVIEWSTART를 기준으로 현재 플레이 가능인지를 체크하므로, 결제가 끝난 시점에 클라이언트단에서 플레이 가능함을 인식할 수 있도록 이 값을 설정.
          contents.dVIEWSTART = new Date().toISOString();
          setPlayContents( contents ); // playcontents가 변경되면 아래에서 useEffect가 수행될 것임.
        }
          // checkDaemonInstalled( contents, contents.viewID )
        // navigate('/signup2');
      }
      function errFunc(e, code)
      {
        setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
        console.log("VOD 결제 등록 결과 오류 : ", e, code);
      }
      _w.wiceanFetch(url, 'POST', data, okFunc, errFunc, null)

    }
  }

  // iamport 모듈의 결제가 끝난 후 서버와 통신이 성공하면 유료 콘텐츠를 플레이한다.
  function serverPaycheck(memID, data, contents)
  {
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_PAY_VOD}${memID}`

    function okFunc(res)
    {
      console.log("VOD결제 등록 결과 : ", res);
      if ( res.statusCode==200 ) // 성공일 경우 플레이
      {
        // dVIEWSTART를 기준으로 현재 플레이 가능인지를 체크하므로, 결제가 끝난 시점에 클라이언트단에서 플레이 가능함을 인식할 수 있도록 이 값을 설정.
        contents.dVIEWSTART = new Date().toISOString();
        setPlayContents( contents ); // playcontents가 변경되면 아래에서 useEffect가 수행될 것임.

      }
        // checkDaemonInstalled( contents, contents.viewID )
      // navigate('/signup2');
    }
    function errFunc(e, code)
    {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
      console.log("VOD 결제 등록 결과 오류 : ", e, code);
    }
    _w.wiceanFetchLogin(url, 'POST', data, okFunc, errFunc, null)
  }

  // VOD 현금 결제
  function pay( contents )
  {
    console.log(contents);
    const cost = contents.nVODPRICE;
    const res = confirm(cost + "원이 결제됩니다. 결제를 계속 진행하시겠습니까?");
    const memID = _w.getAvatarInfo()?.memID;
    const customer_uid = _w.getCustomerUID();
    const merchant_uid = _w.getMerchantUID(cost, true); // true이면 VOD결제

    if ( res )
    {
      const payPayload = 
      {
        // customer_uid,
        // pg: "html5_inicis",    // pg사가 다날 하나로 설정되어 있으므로 적지 않음. by 매뉴얼
        pay_method: "card",
        merchant_uid: merchant_uid,
        name: "로플러 VOD(" + contents.sTITLE + ")",
        amount: cost,
        buyer_email: _w.getAvatarInfo()?.userID,
        buyer_name: _w.getAvatarInfo()?.username,
      } 
      // console.log( "payload : ", payPayload);
      // console.log( process.env );
      var IMP = window.IMP; // 생략 가능
      IMP.init(process.env.REACT_APP_IAMPORT_UID); // 예: imp00000000
      // 빌링키 발급 및 최초 결제를 함께 요청하는 케이스로 amount가 최초 결제 금액이 됨.
      IMP.request_pay(payPayload, 
        function (rsp) // 모바일 결제후 콜백
        {
          if (rsp.success)
          {
            // console.log(rsp);
            // success시 리턴 JSON 구조
            // {
            //   apply_num: "49255789"
            //   bank_name: null
            //   buyer_addr: ""
            //   buyer_email: "aa@qq.com"
            //   buyer_name: "이이름"
            //   buyer_postcode: ""
            //   buyer_tel: ""
            //   card_name: "BC카드"
            //   card_number: "490220******9839"
            //   card_quota: 0
            //   currency: "KRW"
            //   custom_data: null
            //   customer_uid: "rofler-21"
            //   imp_uid: "imp_179199120944"
            //   merchant_uid: "pays-rofler-21-20211014-025638"
            //   name: "로플러 정기결제"
            //   paid_amount: 9900
            //   paid_at: 1634180355
            //   pay_method: "card"
            //   pg_provider: "danal_tpay"
            //   pg_tid: "202110141156391912944400"
            //   pg_type: "payment"
            //   receipt_url: "https://www.danalpay.com/receipt/creditcard/view.aspx?dataType=receipt&cpid=9810030929&data=FVp3pdsFwzueId88MxyqqCpfoYPa1wTUhVbRDs3xZYM3dsKz3jRiudeWi%2FJ35bBI"
            //   status: "paid"
            //   success: true
            // }
            // 빌링키 발급 성공
            // 서버에 확인할 때는 customer_uid와 merchant_uid만 필요하다.
            const returnBody = {
              // apply_num: rsp.apply_num,
              // card_name: rsp.card_name,
              // pay_method: rsp.pay_method,
              // customer_uid: rsp.customer_uid,
              imp_uid: rsp.imp_uid,
              merchant_uid: rsp.merchant_uid,
              episodeID: contents.nEPID,
              paid_amount: cost,
              viewID: contents.viewID
            }

            serverPaycheck(memID, returnBody, contents);
          }
          else
          {
            setDimmer(<>결제에 실패했습니다. 다시 시도해 주세요</>)
            console.log(rsp);
            // 빌링키 발급 실패
          }
        })

      // navigate('/signup3');

    }
  }

  function getViewLimitDate(contents)
  {
    return new Date(new Date(contents.dVIEWSTART).getTime() + (contents.nRENTDAY==0?7:contents.nRENTDAY)*24*3600*1000);
  }
  /**
   * 영상 플레이 버튼을 눌렀을 때 실행되는 함수
   * - DB에 T_MEMBER에 bDAEMON 필드가 1이면 넘어가고, 0이면 프로그램을 설치하도록 다운로드 컨펌 화면을 띄운다.
   * URL scheme을 이용한 방법은 브라우저에서 보안 이슈로 완전히 막혔기 때문에 DB를 이용한 방법을 쓸 수 밖에 없음
   * 컨펌화면의 내용은 ‘로플러 프로그램을 설치해야 p2p를 통한 원활한 시청이 가능합니다. 설치 파일을 다운로드 하시겠습니까?’
   * 예를 누르면 파일이 다운로드 되도록 한다.
   * TB_VIEW에 insert. cSTATUS=VIEW0 으로 입력 dREG에 등록 시점 기록 (자동) cVIEW = BUYS (나중에 유료 영상이 생기면 바뀌어야 함)
   * (여기 중간에 데몬 쪽에서 영상 파일을 어느정도 받으면 시청 가능 상태로 바꿔주는 <cSTATUS=VIEW1, dREADY> 세팅 과정이 필요)
   * 클라가 2~3초후 서버에 cSTATUS를 확인하고 VIEW1로 바뀌었다면 플레이 시작
   * 플레이 버튼을 눌렀을 때 처리과정
   * - 이미 설치가 되어 있다면
   * 서버에 플레이리스트 요청
   * 서버에서는m3u8파일을 만들되, 1차는 localhost:8080, 2차는 CDN을 이용하여 다운받도록 한다.
   * 플레이리스트를 받으면 서버에 cSTATUS=VIEW2로 변경 (플레이리스트 줄때 설정하면 됨)
   * 
   * 2021. 01. 03 위 내용은 일단 내용적으로 참고만 하고 자세한 내용은 인수인계 문서 참고.
   * @param {*} vcontents 
   */
  function checkNeedPay( vcontents )
  {
    // dVIEWSTART가 null이면 1970년 1월 1일 + 7일, dVIEWSTART가 있으면 해당일 7일후의 날짜가 저장됨.
    const dVIEWLIMIT = getViewLimitDate(vcontents);
    if ( vcontents.nVODPRICE!=0 && dVIEWLIMIT<new Date() && contents.usePay==1 ) // 결제가 필요하다면
    {
      // 내 포인트가 얼마 남았나 확인
      // 싹과 현금 결제중 선택 
      checkMyPointToView(vcontents);
    }
    else
      // setPlayContents가 실제 플레이될 콘텐츠를 set 하는 과정임.
      setPlayContents( vcontents ); // playcontents가 변경되면 아래에서 useEffect가 수행될 것임.
  }

  // 바로 위 checkNeedPay함수랑 묶여서 작동됨
  useEffect( () => {
    const viewID = playcontents.viewID;
    // console.log("[i] useEffect(playcontents) - mobile - viewID : ", viewID, playcontents );
    if ( playcontents===false )
      return;
    if ( _w.isMobile() )
    {
      setBodyScrollable(true);
      setTimeout(() => { checkViewStatus(viewID, 0); }, 2000);
      // regView( );
    }
    else
    // 프로그램 설치 여부 확인
      checkDaemonInstalled( playcontents, viewID); // playcontents가 수정되면 실행된다.
  }, [playcontents])
  
  // 내 포인트로 볼 수 있는 콘텐츠인지 판정
  function checkMyPointToView(contents, viewID)
  {
    // const contents = playcontents;
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_MY_POINT}`;
    function okFunc(res) {
      // 내 포인트가 얼마 남았나 확인
      const point = res.data.point;
      setTempContents(contents);
      // 현금, 포인트는 100배의 비율을 가진다.
      if ( contents.nVODPRICE*moneyToPoint<point ) // 포인트가 더 많다면, 포인트를 쓸 것인지 결제할 것인지 물어 본다.
      {
        setShowOption(point);
        // pay(contents);
      }
      else
        pay(contents);
    }
    function errFunc(e, code) {
      if ( code===406 || code==="406" )
        setDimmer(<>포인트가 모자랍니다.</>);
      else
        setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
      setSpinner(false);
    }
    _w.wiceanFetch(url, 'GET', null, okFunc, errFunc, null)
  }

  // 시청 버튼을 누르면 view 테이블에 등록
  function regView(contents)
  {
    console.log("[I] regview");
    // const contents = playcontents;
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_WANT_VIEW}${contents.nMOVIEID}`;
    function okFunc(res) {
      const viewID = res.data.viewID;
      contents.viewID = viewID; // viewID를 contents에 넣고 이후로 이용한다.
      // console.log("viewID 설정됨 : ", viewID, contents);

      checkNeedPay(contents);
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
      setSpinner(false);
    }
    _w.wiceanFetch(url, 'POST', null, okFunc, errFunc, null)
  }

  function HomeContentsSkeleton(props)
  {
    const [ updated, setUpdated ] = React.useState( );
    const elemRef = React.useRef();

    // HomeContentsSkeleton이 붙고 나면 실행되는 javascript
    useEffect( (node) => {
      if ( updated ) return;
      // console.log("--------------- useEffect run : ");
      if ( window.setBXSlider )
        window.setBXSlider(); // this is defined in ui.js
      setUpdated(true);
      // if ( updated )
      //   window.setBXSlider(); // this is defined in ui.js
    }, [])

    var ret = null;
    // _w._l("Display Mode : " + mode);
    switch(props.mode)
    {
      case 1:   // 일반 홈화면
        ret = (<> <Mode1TopContentsPanel /> <Mode1BottomContentsList /> </>);
        break;
      case 2:   // 검색 결과
        ret = (<VideoConts ref={elemRef} title="검색 결과" conts={searchResult} viewmode={1}/>);
        break;
      case 3:   // 찜리스트
        ret = (<VideoConts ref={elemRef} title="찜한 콘텐츠" conts={wishResult} viewmode={1}/>);
        break;
      case 4:   // 시청기록
        ret = (<VideoConts ref={elemRef} title="시청기록" conts={historyResult} viewmode={1}/>);
        break;
        case 5:   // 베스트 리스트
        ret = (<VideoConts ref={elemRef} title="베스트 콘텐츠" conts={bestResult} viewmode={(navigator.maxTouchPoints > 0)?1:2}/>);
        break;
        case 6:   // 추천 리스트
        ret = (<VideoConts ref={elemRef} title="추천 콘텐츠" conts={recommendResult} viewmode={(navigator.maxTouchPoints > 0)?1:2}/>);
        break;
    }

    setPrevmode(props.mode);

    // _w._l("ret : ", ret);

    return ret;
  }

  function clickWishlist()
  {
    // alert('wish clicked!');
    if ( mode===3 ) return;
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_GET_WISHLIST}`
    
    function okFunc(res) {
      setWishResult(res.data);
      setMode(3);
    }
    function errFunc(e, code) {
      if ( code===404 ) 
        setDimmer(<>자료가 없습니다.</>);
      else
        setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'GET',  null, okFunc, errFunc, null)
  }

  function clickViewHistory()
  {
    if ( mode===4 ) return;
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_GET_VIEWHISTORY}`
    
    function okFunc(res) {
      setHistoryResult(res.data);
      setMode(4);
    }
    function errFunc(e, code) {
      if ( code===404 ) 
        setDimmer(<>자료가 없습니다.</>);
      else
        setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'GET', { page: 1, numItemPerPage: 100 }, okFunc, errFunc, null)
  }

  function clickViewBest()
  {
    if ( mode===5 ) return;
    const memID = _w.getAvatarInfo()?.memID;
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_HOME_CONTENTS}${memID}`
   
    function okFunc(res) {
      setBestResult(res.data.best);
      setMode(5);
    }
    function errFunc(e, code) {
      if ( code===404 ) 
        setDimmer(<>자료가 없습니다.</>);
      else
        setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  }

  function clickViewRecommend()
  {
    if ( mode===6 ) return;
    const memID = _w.getAvatarInfo()?.memID;
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_HOME_CONTENTS}${memID}`
   
    function okFunc(res) {
      setRecommendResult(res.data.recommend);
      setMode(6);
    }
    function errFunc(e, code) {
      if ( code===404 ) 
        setDimmer(<>자료가 없습니다.</>);
      else
        setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  }

  function doSearch(e)
  {
    var phrase = "";
    if ( e.keyCode!=13 )
    {
      if ( e.target.value.length==0 && mode===2 )
        setMode(1);
      return;
    }
    phrase = e.target.value;

    console.log(phrase);
    var searchStr = phrase;

    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_GET_SEARCH}`
    
    function okFunc(res) {
      setSearchResult(res.data);
      setMode(2);
    }
    function errFunc(e, code) {
      if ( code==406 )
        setDimmer(<>자료가 없습니다.</>);
      else
        setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'GET', { searchStr }, okFunc, errFunc, null)

    // if ( e.target)
  }

// detail 팝업을 띄운 후 5초 후에 trailer 영상을 띄우기 위해 사용.
// time을 id로 하여 video 태그가 제대로 설정되고 detaildimmer에 설정한 id가 같을 경우 플레이 하도록 함.
// setTimeout이 의도대로 돌아가지 않는 경우가 생겨 id로 같은 영상일 경우를 확인하고 플레이 함.
useEffect( ()=>{
  const IDTimeout = new Date().getTime();
  setTimeout( (id) => {
    const __v = document.getElementById('trailerMV');
    if ( __v!=null && typeof __v!=='undefined' && (detaildimmer && detaildimmer.IDTimeout && detaildimmer.IDTimeout==id) )
    {
      __v.addEventListener('ended', function() {__v.load()});
      __v.play();
    }
  }, 5000, IDTimeout)
  if ( detaildimmer )
    detaildimmer.IDTimeout = IDTimeout
}, [detaildimmer])

return (
<>
<Helmet>
<meta charset="UTF-8" />
<meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
<meta property="og:type" content="website" />
<meta property="og:title" content="로플러" />
<meta property="og:description" content="로플러 메인" />
<meta property="og:url" content="" />
<meta property="og:image" content="" />
<meta name="mobile-web-app-capable" content="yes" />
<meta name="format-detection" content="telephone=no" />
<title>로플러</title>
<link href="css/ui.css" rel="stylesheet" />
{/* <script src="js/jquery.min.js"></script> */}
{/* <script src="js/jquery.bxslider.min.js"></script> */}
{/* <script src="js/ui.js"></script> */}

{/* 아임포트 모듈 설치 */}
{/* <!-- jQuery --> */}
{/* <script type="text/javascript" src="https://code.jquery.com/jquery-1.12.4.min.js" ></script> */}
{/* <!-- iamport.payment.js --> */}
<script type="text/javascript" src="https://cdn.iamport.kr/js/iamport.payment-1.2.0.js"></script>
{/* jQuery 1.0 이상이 설치되어 있어야 합니다. */}

</Helmet>
{/* <!--netstream-wrap--> */}
<div className="netstream-wrap main">
  {/* <!--nets-header--> */}
  <header className="nets-header">
    <nav className="nets-nav">
          <h1 className="logo"><a onClick={() => {navigate('/');}}><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
      <ul className="nav-tab">
        <li><a onClick={()=>{console.log("찜찜"); clickWishlist();}} onTouchStart={clickWishlist} className={mode==3?"on":""}>찜한 콘텐츠</a></li>
        <li><a onClick={clickViewHistory} className={mode==4?"on":""}>시청 기록</a></li>
      </ul>
      <ul className="menu-lst">
        {/* <!--1004:검색 인풋 추가 검색버튼을 누를때 show클래스 추가되며 인풋창 toggle:s--> */}
        <li>
          <div className="input-search-wrap">
            <div className="input-search">
              <input type="text" placeholder="제목, 감독,배우" onKeyDown={doSearch}/>
            </div>
            <button className="s-ico-bt" type="button" id="search-bt">
              <span className="ico-search"></span>
            </button>
          </div>
        </li>
        {/* <!--1004:검색 인풋 추가:e--> */}
        {/* <!--1004:사람아이콘 눌렀을때 토글 메뉴 toggle:s--> */}
        <li className="my-set">
          <button className="m-ico-bt" type="button" id="person-bt">
            <span className="ico-person"></span>
          </button>
          <ul className="s-menu-lst">
            <li><a onClick={()=>{navigate('/mypage', {state:{usePay: contents.usePay}})}}>설정</a></li>
            <li><a onClick={logout}>로그아웃</a></li>
          </ul>
        </li>
        {/* <!--1004:사람아이콘 눌렀을때 토글 메뉴 toggle:e--> */}
      </ul>
    </nav>
  </header>
  {/* <!--//nets-header--> */}
  {/* <!--netstream-conts--> */}
  <section className="netstream-conts">
    {mode && <HomeContentsSkeleton mode={mode}/>}
    {/* <!--video-conts-wrap--> */}
    {/* <!--//video-conts-wrap--> */}
  </section>
  {/* <!--//netstream-conts--> */}

  {/* <!--member-footer-wrap-->  */}
  {/* <div className="member-footer-wrap"> */}
    <RoflerCommonFooter />
  {/* </div> */}
  {/* <!--//member-footer-wrap--> */}
</div>
{/* // <!--//netstream-wrap-->    */}

{/* 비디오 상세 화면 팝업 - dimmer popup */}
{detaildimmer?
  (
    <div className="dim-wrap" onScroll={prevDef} onTouchMove={prevDef} onMouseDown={prevDef} onMouseUp={prevDef} >
      {/* <!--detail-video-popup-wrap--> */}
      <div className="detail-video-popup-wrap">
        {/* <!--detail-video-layer--> */}
        <div className="detail-video-layer">
          {/* <!--top-conts-info--> */}
          <div className="top-conts-info">
            {/* <!--visual-main-conts--> */}
            <div className="visual-main-conts">
              <div className="video-img-wrap">
                {/* <img src={detaildimmer.sTRAILERPATH} alt="영화" /> */}
                {/* <!--21112:s--> */}
                <video poster={detaildimmer.sTRAILERPATH} id="trailerMV">
                  <source src={detaildimmer.sTRAILERMVPATH} type="video/mp4" />
                </video>
                {/* <!--21112:e--> */}
              </div>
            </div>
            {/* <!--//visual-main-conts--> */}
            {/* <!--my-play-wrap--> */}
            <div className="my-play-wrap">
              {/* <div className="title-img">
                <img src="http://placehold.it/252x92/fff" alt="메인타이틀" />
              </div> */}
              <div className="title-txt">{detaildimmer.sTITLE}</div>
              <ul className="play-bt-lst">
                <li>
                  <button type="button" className="play-main-bt" onClick={()=>{ regView(detaildimmer); }}><span className="ico-play"></span>재생</button>
                </li>
                <li>
                  <a className={`my-bt-zzim ${wishlisted?'on':''}`} onClick={()=>setWishList(detaildimmer.nMOVIEID)}><span className="txt">찜</span></a>
                </li>
                <li>
                  <a className={`my-bt-recom ${recommended?'on':''}`} onClick={()=>setRecommend(detaildimmer.nMOVIEID)}><span className="txt">추천</span></a>
                </li>
                <li>
                  <a className="my-bt-save" onClick={()=>setSave(detaildimmer.nMOVIEID)}><span className="txt">저장</span></a>
                </li>
                <li>
                  <a className="my-bt-share" onClick={()=>setShare(detaildimmer.nMOVIEID)}><span className="txt">공유</span></a>
                </li>
              </ul>
            </div>
            {/* <!--//my-play-wrap--> */}
            {/* <!--rgt-info--> */}
            {/* <div className="rgt-info">
              { (detaildimmer?.b360VR.data[0])?<span className="ico-360"></span>:''}
              <span className="quality">VOD/SSAK</span>
              <span className="price">{detaildimmer?`${detaildimmer.nVODPRICE} 원`:'무료'}</span>
            </div> */}
            {/* <!--//rgt-info--> */}
            <a className="close-bt" onClick={()=>{ setBodyScrollable(true); return setDetailDimmer(null); }} ></a>
          </div>
          {/* <!--//top-conts-info--> */}
          {/* <!--detail-conts-wrap--> */}
          <div className="detail-conts-wrap">
            {/* <!-- 1004 edit:s--> */}
            <p className="type-detail-tit">
              <span>
                {detaildimmer && Math.floor(detaildimmer.nTIME/60)} 분 | {detaildimmer && getAgeString(detaildimmer.cAGELIMIT)} | {detaildimmer && detaildimmer.cGENRE}
              </span>
              {(detaildimmer?.b360VR.data[0]) ? <span className="ico-360"></span> : ''}
            </p>
            {/* <!--txt-info-wrap--> */}
            <ul className="txt-info-wrap">
              <li>
                <dl className="person-info">
                  <dt>감독</dt>
                  <dd>{detaildimmer?.sDIRECTOR}</dd>
                </dl>
                <dl className="person-info">
                  <dt>주연</dt>
                  <dd>
                    {detaildimmer?.sSTAR}
                  </dd>
                </dl>
              </li>
              <li className="rgt-info">
                <dl>
                    <dt>결제</dt>
                    <dd>
                        {detaildimmer && 
                        ((detaildimmer.nVODPRICE==0 || contents.usePay!=1)? // VOD 가격이 없으면 "무료"라고 표기. VOD가격이 있으면 "원", "SSAK" 표기
                          <a href="#" className="price">무료</a>
                          : <>
                              <a href="#" className="price">{detaildimmer.nVODPRICE + " 원"}</a>
                              <a href="#" className="price">({detaildimmer.nVODPRICE*moneyToPoint + " SSAK"})</a>
                            </>
                          )}
                    </dd>
                    <dd className="limit-date">
                      {detaildimmer.dVIEWSTART && getViewLimitDate(detaildimmer)>new Date()?getViewLimitDate(detaildimmer).toISOString().split('T')[0]+"까지 시청 가능":''}
                    </dd>
                </dl>
                <dl>
                    <dt>재생</dt>
                    <dd>{detaildimmer && Math.floor(detaildimmer.nTIME / 60)} 분</dd>
                </dl>
              </li>
              <li>
                <div className="year-age">
                  <span>{detaildimmer?detaildimmer.dOPEN.split('-')[0]:''} | {detaildimmer?ageDiv(detaildimmer.cAGELIMIT, 'em'):''} | {detaildimmer?.cGENRE}</span>
                </div>
                <p className="description">
                  {detaildimmer?.tDESCRIPTION}
                </p>
              </li>
            </ul>
            {/* <!--//txt-info-wrap--> */}
            {/* <!-- 0910 del:s--> */}
            {/* <div className="turn-lst-wrap">
              <span className="turn-txt">회차</span>
              <ol className="turn-lst">
                <li>
                  <a href="#">
                    <dl className="video-conts-sect">
                      <dt>
                        <img src={detaildimmer?.sTRAILERPATH} alt="슬기로운 의사생활" />
                      </dt>
                      <dd>
                        <div className="tit">제 1화  {detaildimmer?.sTITLE}</div>
                        <p className="detail">
                          {detaildimmer && detaildimmer.tDESCRIPTION.substr(0, 100)+"..."}
                        </p>
                      </dd>
                    </dl>
                  </a>
                </li>
              </ol>
            </div> */}
            {/* <!-- 0910 del:e--> */}
          </div>
          {/* <!--//detail-conts-wrap--> */}
        </div>
        {/* <!--//detail-video-layer--> */}
      </div>
      {/* <!--detail-video-popup-wrap--> */}
    </div>
  ):''
}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    {spinner?'': (<>
                      <div className="pop-bt-wrap">
                          <button onClick={() => { return setDimmer(null); }} className="pop-confirm-bt">확인</button>
                      </div>
                    </>)}
                </div>
            </div>
        </div>
    </div>
  ):''
}

{/* 구매옵션 */}
{showOption?
  (
    <div className="dim-wrap" onClick={()=>{setShowOption(false)}}>
      <div className="popup-wrap title-type small">
        <div className="popup">
          <h1>구매옵션 선택</h1>
          <div className="pop-conts">
            <ul className="opt-lst">
              <li><a onClick={()=>{setShowOption(false); paypoint(tempContents)}} className="option-point">SSAK 포인트 구매 (보유 포인트: <span className="use-point">{parseInt(showOption)}</span>)</a></li>
              <li><a onClick={()=>{setShowOption(false); pay(tempContents);}} className="option-card">카드사 결제 진행</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  ):''
}

{spinner?<CircularProgress />:''}
    </>
  );
};

export default HomeMain;
