import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";

export const WithdrawDone = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const [ contents, setContents ] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() =>
  {
    if (contents === false)
      setContents(location.state.contents);
  }, [])


  // const schema = Yup.object({
  //     // username : Yup.string().email("정확한 이메일 주소를 사용해 주세요").required("id로 email주소를 입력해 주세요"),
  //     // password: Yup.string().min(4, "8자 이상을 입력해주세요").required("패스워드를 입력해 주세요")
  //     username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
  //     password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
  //   });
  // const { 
  //   register, 
  //   handleSubmit, 
  //   formState: { errors }, 
  // } = useForm( {
  //   reValidateMode: 'onChange',
  //   resolver: yupResolver(schema)
  // });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });

  // const letsStart = () => {
  //   const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}${email}`;
    
  //   function okFunc(res) {
  //     console.log("CHECK RES : ", res);
  //     if ( res.data?.user ) {
  //       setDimmer(<>이미 가입된 이메일입니다.</>)
  //     }
  //     else
  //       navigate('/signup', { state: {email}})
  //   }
  //   function errFunc(e, code) {
  //     // 이외의 오류일경우
  //     setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
  //   }
  //   _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  // }

  // useEffect( () => {
  //   if ( _w.checkLogged() )
  //     navigate('home');
  // }, [])

  function getBirthStr(str)
  {
    const arr = str.split('T')[0].split('-');
    return `${arr[0]} 년 ${arr[1]} 월 ${arr[2]} 일 `;
  }

  function getNextPayStr(str)
  {
    const d = new Date(new Date(str).getTime() + 30 * 24 * 3600 * 1000);
    return getBirthStr(d.toISOString());
  }

  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러" />
  <meta property="og:description" content="로플러 홈" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>로플러 홈</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
{/* <!--netstream-wrap--> */}
<div className="netstream-wrap member">
  {/* <!--nets-header--> */}
  <header className="nets-header">
    <nav className="nets-nav">
      <h1 className="logo"><a href="#"><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
    </nav>
  </header>
  {/* <!--//nets-header--> */}
  {/* <!--netstream-conts--> */}
  <section className="netstream-conts">
    <div className="my-page-wrap">
      {/* <!--cancle-account-wrap--> */}
      <div className="cancle-account-wrap">
        <h2>멤버십이 해지되었습니다.</h2>
        <p className="my-msg">
          <span className="eail">
            {contents && contents.sEMAIL}
          </span>
          으로<br />
          {/* <!--1004 br추가--> */}
          서비스 해지 확인 메일을 보냈습니다.<br />
          <span className="date-txt">
            <em className="date-num">
              {contents && getNextPayStr(contents.dLASTPAY)}
            </em>
            까지 계속 컨텐츠를 시청하실 수 있습니다.
          </span>
        </p>
        <div className="btn-wrap">
          <button type="button" className="check-normal-bt">홈 이동</button>
        </div>
      </div>
      {/* <!--//cancle-account-wrap--> */}
    </div>
  </section>
  {/* <!--//netstream-conts--> */}

<RoflerCommonFooter />
</div>
{/* <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default WithdrawDone;
