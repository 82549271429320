// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
// import { Formik } from 'formik';
// import {
//   Box,
//   Button,
//   Container,
//   Grid,
//   Link,
//   TextField,
//   Typography
// } from '@material-ui/core';
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
// import { BarunsonTextField } from '../component/BarunsonTextField';
// import { BarunsonButton } from '../component/BarunsonButton';
// import React, {useState } from "react";
import React from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { Email } from "@material-ui/icons";
// import axios from 'axios';
// import RoflerCommonFooter from "src/component/RoflerCommonFooter";
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";

// import WInput from "../component/WComponents";

export const RoflerLoginWrapper = (props) => {

  // 이전에 navigate() 함수에 의해 넘어 오며 state에 url을 setting해 뒀다면,
  // 로그인이 끝난 후 해당 url로 이동하도록 한다.
  const location = useLocation();
  let toURL = null;
  if ( location.state && location.state.url )
    toURL = location.state.url;

  function getInfo()
  {
    const info = localStorage.getItem(process.env.REACT_APP_KEY_FOR_AUTH);
    if ( info ) // ID/PW가 저장되어 있을 때
    {
      const decryptedInfoArr = _w.wDecrypt(info).split('|');
      return { username: decryptedInfoArr[0], password: decryptedInfoArr[1], infosave: true };
    }

    return null;
  }

  return <RoflerLogin {...props} info={getInfo()} toURL={toURL} />
}

export const RoflerLogin = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const navigate = useNavigate();

  const schema = Yup.object({
      // username : Yup.string().email("정확한 이메일 주소를 사용해 주세요").required("id로 email주소를 입력해 주세요"),
      // password: Yup.string().min(4, "8자 이상을 입력해주세요").required("패스워드를 입력해 주세요")
      username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
      password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
    });
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
  } = useForm( {
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });
  const onSubmit = (data) => {
    const _data = JSON.stringify(data);
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_LOGIN}`
    
    function okFunc(res) {
      setLogin(res.data.access_token);
      if ( data.infosave )
        localStorage.setItem(process.env.REACT_APP_KEY_FOR_AUTH, _w.wEncrypt(`${data.username}|${data.password}`));
      else
        localStorage.removeItem(process.env.REACT_APP_KEY_FOR_AUTH);

      
      // alert("UserAgent : " + navigator.userAgent + ", isMobile : " + _w.isMobile() );
      // console.log("UserAgent : " + navigator.userAgent + ", isMobile : " + _w.isMobile() );
      if (!_w.isMobile())
        window.location=`${process.env.REACT_APP_URL_DAEMON}login?jwt=${_w.getAccessToken()}&id=${data.username}`;

      // const loginOption = {login: true};
      // if ( props && props.toURL )
      //   navigate(props.toURL, {state:loginOption});
      // else
      // {
      //   navigate('/home', {state:loginOption});
      // }
      navigate('/home');
    }
    function errFunc(e, code) {
      // Uauthorized 일 경우
      if ( code===401 ) {
        console.log("401");
        setDimmer(<>아이디와 패스워드가<br /> 일치하지 않습니다.<br /> 확인 후 다시 시도해주세요.</>);
        return;
      }
      // 이외의 오류일경우
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'POST',  data, okFunc, errFunc, null)
  }

  function bpassLogin()
  {
    _w.bpassAuth();
  }

  function backToLanding()
  {
    const loginOption = {fromLogin: true};
    navigate('/', {state:loginOption});
  }

  function rqpwchange()
  {
    navigate('/rqpwreset')
  }

  return (
    <>
<div className="netstream-wrap bg-img">
    <header className="nets-header">
    {/* <!--nets-header--> */}
       <nav className="nets-nav">
            {/* <!--0723 HJ edit:s--> */}
           <h1 className="logo"><a href="#"><img src="./images/logo-bi.png" alt="로고이미지" /></a></h1>
           {/* <!--0723 HJ edit:e--> */}
           {/* <ul className="menu-lst">
               <li className="sign-bt"><button className="midium-confirm-bt" type="button">Sign In</button></li>
           </ul> */}
       </nav>
    </header>
    {/* <!--//nets-header--> */}
    {/* <!--netstream-conts--> */}
    <section className="netstream-conts">
        {/* <!--login-wrap--> */}
        <div className="login-wrap">
            <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                <h2>로그인</h2>
                <ul className="input-lst">
                    <li>
                        <div className={`input-txt ${errors.username?"check-val":""}`}>
                            <input type="text" placeholder="이메일 주소" {...register("username")} defaultValue={props.info?.username} />
                        </div>
                        <div className="validation-txt">{errors.username?.message}</div>
                    </li>
                    <li>
                        <div className={`input-txt ${errors.password?"check-val":""}`}>
                            <input type="password" placeholder="비밀번호를 입력하세요." {...register("password")} defaultValue={props.info?.password} />
                        </div>
                        <div className="validation-txt">{errors.password?.message}</div>
                    </li>
                </ul>
                <div className="captcha-bx">
                    {/* <div className="captcha"></div> */}
                </div>
                {/* <!--0722 HJ add--> */}
                <div className="btn-wrap">
                    <button className="big-confirm-bt" type="submit">로그인</button>
                    <button className="big-confirm-bt" type="button" onClick={bpassLogin}>BPASS 로그인</button>
                 </div>
                <ul className="info-lst">
                    <li className="check"><input type="checkbox" id="chk-info" name="chk-info" className="chk-info" defaultChecked={props.info?.infosave} defaultValue={props.info?.infosave} {...register("infosave")} /><label htmlFor="chk-info">로그인 정보 저장</label></li>
                    <li className="help"><a onClick={rqpwchange}>도움이 필요하신가요?</a></li>
                    <li className="member"><a onClick={backToLanding}>회원이 아니신가요?</a></li>
                    <li className="join"><a onClick={backToLanding}>지금 가입하세요.</a></li>
                </ul>
            </form>
        </div>
        {/* <!--//login-wrap--> */}
    </section>
      {/* <Helmet>
        <title>Login | Material Kit</title>
      </Helmet> */}
    {/* <div className="login-footer-wrap"> */}
      <RoflerCommonFooter />
    {/* </div> */}
</div>
{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default RoflerLoginWrapper;
