import { useNavigate } from "react-router-dom"

const RoflerCompanyMenuLogo = (props) => {

  return (
    <>
      <nav className="nets-nav">
        <h1 className="logo"><a href="/home"><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
      </nav>
    </>
  )
}

export default RoflerCompanyMenuLogo;