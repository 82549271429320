// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
// import { Formik } from 'formik';
// import {
//   Box,
//   Button,
//   Container,
//   Grid,
//   Link,
//   TextField,
//   Typography
// } from '@material-ui/core';
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
// import { BarunsonTextField } from '../component/BarunsonTextField';
// import { BarunsonButton } from '../component/BarunsonButton';
// import React, {useState } from "react";
import React from "react";
import * as Yup from 'yup';
// import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { Email } from "@material-ui/icons";
// import axios from 'axios';
// import RoflerCommonFooter from "src/component/RoflerCommonFooter";
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { WSelect } from "../component/WComponents";

import * as _w from "../utils/wiceanUtil";
import { unstable_createPortal } from "react-dom";

// import WInput from "../component/WComponents";

const _op = (i, u) => { return {label: `${i} ${u}`, value: i}; };

export const RoflerSignupStep3 = (props) => {
  const [ year, setYear ] = React.useState(0);
  const [ month, setMonth ] = React.useState(0);
  const [ day, setDay ] = React.useState(0);
  const [ dimmer, setDimmer ] = React.useState(false);
  const navigate = useNavigate();


  const onSubmit = (data) => {
    // console.log("섭밋 데이터 : ", data);

    let body = 
    {
    }
    // console.log("바디 데이터 : ", body);
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}`
    
    function okFunc(res) {
      // navigate('/signup2');
    }
    function errFunc(e, code) {
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'POST',  data, okFunc, errFunc, null)
  }

  function goHome()
  {
    navigate('/login');
  }

  function firstRender()
  {
    if ( day!=0 ) return;

    const d = new Date(new Date().getTime() + 30*24*3600*1000);
    const dArr = d.toISOString().split('T')[0].split('-');
    setYear( dArr[0] );
    setMonth( dArr[1] );
    setDay( dArr[2] );
    // setYear(d.getFullYear());
    // setMonth(d.getMonth());
    // setDay(d.getDay());
  }

  useEffect( () => {
    firstRender();
  }, [day])

  return (
    <>
  {/* <!--netstream-wrap--> */}
<div className="netstream-wrap member">
  {/* <!--nets-header--> */}
  <header className="nets-header">
    <nav className="nets-nav">
      {/* <!--0723 HJ edit:s--> */}
      <h1 className="logo"><a href="#"><img src="/images/logo-bi.png" alt="로고이미지" /></a></h1>
      {/* <!--0723 HJ edit:e--> */}
      <ul className="menu-lst">
        {/* <li className="sign-bt"><button className="midium-confirm-bt" type="button">Sign In</button></li> */}
      </ul>
      </nav>
  </header>
  {/* <!--//nets-header--> */}
  {/* <!--netstream-conts--> */}
  <section className="netstream-conts">
    {/* <!--join-wrap--> */}
    <div className="join-wrap">
      <span className="step-num">완료!</span>
      <h2>가입하신 것을 축하합니다.</h2>
      <div className="mebership-bx">
        <p className="info-tit">이용 중인 멤버십</p>
        <div className="mebership-conts">
          <img src="images/rofler_membership.png" alt="rofler 멤버십" />
          <span class="type-txt">
            로플러 기본 요금제
          </span>
        </div>
      </div>
      <p className="buy-desc">다음 결제 예정일: {`${year}년 ${month}월 ${day}일`}</p>
      <div className="btn-wrap">
        <button className="big-confirm-bt" type="button" onClick={goHome}>홈으로 이동</button>
      </div>
    </div>
    {/* <!--//join-wrap--> */}
  </section>
  {/* <!--//netstream-conts--> */}

  {/* <!--member-footer-wrap-->  */}
  {/* <div className="member-footer-wrap"> */}
    <RoflerCommonFooter />
  {/* </div> */}
  {/* <!--//member-footer-wrap--> */}
</div>
{/* // <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} class="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default RoflerSignupStep3;