import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail, ToggleOff } from "@material-ui/icons";
import ConfirmDimmer, { Dimmer } from "../component/Dimmer";

export const MyPage = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ popupWithdraw, setPopupWithdraw ] = React.useState(false);
  const [ loginDimmer, setLoginDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const navigate = useNavigate();
  const [ contents, setContents ] = React.useState('');
  const location = useLocation();

  const schema = Yup.object({
      username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
      password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
    });
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
  } = useForm( {
    reValidateMode: 'onChange',
    // resolver: yupResolver(schema)
  });

  const pointTypeArr = {
    "PTP1"  :	"멤버십 결제최초",
    "PTP2"  :	"멤버십 결제",
    "PTP3"  :	"파일다운로드옵션 설정",
    "PTP4"  :	"영상 재생",
    "PTP5"  :	"로플러 프로그램 로그인",
    "PTM1"  :	"포인트 이용 영화 시청",
  }
  function getPointTable( tableContents )
  {
    var aa = [1, 2, 3];
    // console.log("tableContents : ", tableContents);
    if (tableContents && Array.isArray(tableContents) && tableContents.length>0 )
    {
      var items = 
          tableContents.map((item, idx) => { return (
            <>
              <tr>
                <td>{idx+1}</td>
                <td>{item.point}</td>
                <td>{pointTypeArr[item.type]}</td>
              </tr>
            </>
          ) })
      // console.log("items : ", items);
      return <>{items}</>
    }
    else
      return (
            <>
              <tr>
                <td></td>
                <td>내용이 없습니다.</td>
                <td></td>
              </tr>
            </>
          );
        // <>
        //   <tr>
        //     <td></td>
        //     <td>내용이 없습니다.</td>
        //     <td></td>
        //   </tr>
        // </>
  }

  const getMyInfo = () => {
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_MYPAGE}`;
    
    function okFunc(res) {
      // console.log("CHECK RES : ", res);
      setContents(res.data);
    }

    function errFunc(e, code) {
      _w._l('code : ' + code)
      if ( code==401 ) // 로그인 되지 않았을 때
      {
        _w._l('로그인 안됨');
        setLoginDimmer(<>로그인 되지 않았습니다.<br />로그인 화면으로 이동합니다.</>);
      }
      else
        // 이외의 오류일경우
        setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  }

  function getBirthStr(str)
  {
    const arr = str.split('T')[0].split('-');
    return `${arr[0]} 년 ${arr[1]} 월 ${arr[2]} 일 `;
  }

  function getNextPayStr(str)
  {
    const d = new Date(new Date(str).getTime() + 30 * 24 * 3600 * 1000);
    return getBirthStr(d.toISOString());
  }

  // 회원 탈퇴 통신 관련 함수
  function withdraw(data)
  {
    console.log("data : ", data);
    if ( !confirm('확인을 누르시면 탈퇴합니다.') )
      return;

    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_WITHDRAW}`;
    
    function okFunc(res) {
      setPopupWithdraw(false);
      setLoginDimmer(<>탈퇴되셨습니다.<br />홈 화면으로 이동합니다.</>);
    }

    function errFunc(e, code) {
      _w._l('code : ' + code)
      if ( code==401 ) // 로그인 되지 않았을 때
      {
        _w._l('로그인 안됨');
        setLoginDimmer(<>로그인 되지 않았습니다.<br />로그인 화면으로 이동합니다.</>);
        navigate('/');
      }
      else if ( code==403 ) // 패스워드가 틀렸을 때
        // 이외의 오류일경우
        setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchLogin(url, 'DELETE', data, okFunc, errFunc, null)

  }

  const bpass = () =>
  {
    if ( contents.bBPASSID )
      return;
    let url = `${process.env.REACT_APP_URL_BPASS_BASE}${process.env.REACT_APP_URL_AUTH_VIEW_REQUEST}`;
    const { v1: uuidv1, v4: uuidv4, } = require('uuid');
    const state = uuidv4();
    // localstorage에 state를 저장
    _w.saveLocalStorage("bpassstate", state);
    console.log("state + " + state);
    console.log("localstorage : " + window.localStorage.getItem('bpassstate'));
    const data = {
      service_id: process.env.REACT_APP_BPASS_SERVICE_ID,
      redirect_uri: encodeURIComponent(process.env.REACT_APP_URL_BPASS_REDIR),
      state: state,
      type: 1,
      "0data": '0x11'
    }

    // _w.wiceanFetch(url, 'POST',  data, okFunc, errFunc, null)
    const p = Object.keys(data);
    url += "?" + p.map(i => `${i}=${data[i]}`).join('&');

    // alert(url);
    location.href = url;
  }

  useEffect( () => {
    // if ( !_w.checkLogged() )
    //   navigate('/home');
    _w._l("useEffect init");
    getMyInfo();
  }, [])

  function logout()
  {
    _w.setLogout();
    navigate('/');
  }
  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러" />
  <meta property="og:description" content="로플러 마이페이지" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>마이페이지</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
{/* <!--netstream-wrap--> */}
<div className="netstream-wrap member">
  {/* <!--nets-header--> */}
  <header className="nets-header">
    <nav className="nets-nav">
      <h1 className="logo"><a onClick={()=>{navigate('/home');}}><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
    </nav>
  </header>
  {/* <!--//nets-header--> */}
  {/* <!--netstream-conts--> */}
  <section className="netstream-conts">
    {/* <!--my-page-wrap--> */}
    <div className="my-page-wrap">
      {/* <!--my-info-set--> */}
      <dl className="my-info-set">
        <dt className="info-tit">계정</dt>
        <dd>
          <ul className="info-cont-lst">
            <li>
              <span className="tit-item">생년월일</span>
              <span>{contents && getBirthStr( contents.dBIRTH )}</span>
            </li>
            <li>
              <span className="tit-item">BPASS</span>
              <span>
                <a onClick={bpass} className="txt-link strong">{contents.bBPASSID?"BPASS와 연동되어 있습니다.":"연동하러 가기"}</a>
              </span>
            </li>
            <li>
              <span className="tit-item">아이디</span>
              <span>{contents && contents.sEMAIL}</span>
            </li>
            <li>
              <span className="tit-item">비밀번호</span>
              <span>
                <a href="/passwordchange" className="txt-link">비밀번호 변경</a>
              </span>
            </li>
          </ul>
        </dd>
      </dl>
      {/* <!--//my-info-set--> */}
      {/* <!--my-info-set--> */}
      { location.state.usePay!=1?<></>:
      <>
      <dl className="my-info-set">
        <dt className="info-tit">멤버십</dt>
        <dd>
          <ul className="info-cont-lst">
            <li>
              <span className="tit-item">상품명</span>
              <span>{contents && (contents.bMEMBERSHIP==1?"로플러 멤버십 이용중":"현재 멤버십 상태가 아닙니다.")}</span>
            </li>
            <li>
              <span className="tit-item">다음 결제일</span>
              <span>{contents && getNextPayStr(contents.dLASTPAY)}</span>
            </li>
            <li>
              <span className="tit-item">결제관리</span>
              <span>
                <a onClick={() => {navigate('/cancelMembership', {state:{contents}})}} className="txt-link">{contents && (contents.bMEMBERSHIP==1?"멤버쉽 해지":"멤버쉽 신청")}</a>
              </span>
            </li>
          </ul>
        </dd>
      </dl>
      <dl className="my-info-set">
        <dt className="info-tit">파일 다운로드</dt>
        <dd>
          <div className="chk-option">
            <span className="tit-item">옵션</span>
            <span>
              <input type="checkbox" id="chk-member" name="chk-info" className="chk-info" onClick={(e)=>{console.log("E: " , e); 
                // toggleDownload(e);
                }}/>
              <label for="chk-member">멤버십 이용 시에만 해당 기능을 사용할 수 있습니다.
              </label>
            </span>
          </div>
        </dd>
      </dl>
      </>
      }
      {/* <!--//my-info-set--> */}
      {/* <!--my-info-set--> */}
      <dl className="my-info-set">
        <dt className="info-tit">
          <span className="lgt">포인트</span>
          <span className="rgt">
            전체 보유: {contents && parseInt(contents.nPOINT)} SSAK
            <em className="desc">포인트는 영상 다운로드를 통해 획득하거나 소모하실 수 있습니다. </em>
          </span>
        </dt>
        <dd>
          <table className="tb-mypage">
            <caption>획득 내역</caption>
            <thead>
              <th>순번</th>
              <th>획득한 포인트</th>
              <th>사이트접속</th>
            </thead>
            {/* <tr>
              <td>1</td>
              <td>1000 SSACK</td>
              <td>영상 다운로드</td>
            </tr>
            <tr>
              <td>2</td>
              <td>1000 SSACK</td>
              <td>영상 다운로드</td>
            </tr>
            <tr>
              <td>3</td>
              <td>1000 SSACK</td>
              <td>영상 다운로드</td>
            </tr> */}
            { getPointTable(contents.pointGetList) }
          </table>
        </dd>
        <dd>
          <table className="tb-mypage">
            <caption>소비 내역</caption>
            <thead>
              <th>순번</th>
              <th>사용한 포인트</th>
              <th>사이트접속</th>
            </thead>
            {/* <tr>
              <td>1</td>
              <td>1000 SSACK</td>
              <td>영상 다운로드</td>
            </tr>
            <tr>
              <td>2</td>
              <td>1000 SSACK</td>
              <td>영상 다운로드</td>
            </tr>
            <tr>
              <td>3</td>
              <td>1000 SSACK</td>
              <td>영상 다운로드</td>
            </tr> */}
            {getPointTable(contents.pointUseList) }
          </table>
        </dd>
      </dl>
      {/* <!--//my-info-set--> */}
      <div className="go-help">
        <ul className="link-lst">
          <li>
            <a onClick={() => { navigate('/faq')}} className="txt-link">고객센터</a>
          </li>
          {/* <!--211017 s:--> */}
          {/* <!--li class="app">
              <a href="#" class="txt-link">앱 설정 (모바일 전용)</a>
          </li--> */}
          <li class="withdrawal">
              <a onClick={()=>{setPopupWithdraw(true);}} class="txt-link">회원탈퇴</a>
          </li>
          {/* <!--211017 e:--> */}
        </ul>
      </div>
      <div className="btn-wrap">
        <button className="big-cancle-bt" type="button" onClick={logout}>로그 아웃</button>
      </div>
    </div>
    {/* <!--//my-page-wrap--> */}
  </section>
  {/* <!--//netstream-conts--> */}
  <RoflerCommonFooter />
</div>
{/* <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}


<Dimmer type="1" text={loginDimmer} okfunc={()=>{ navigate('/login'); }}></Dimmer>
{/* <Dimmer type="1" text={withdrawDimmer} okfunc={()=>{ trueWithdraw(withdrawData) }}></Dimmer> */}


{/* 회원 탈퇴 팝업 */}
{popupWithdraw?
<div className="dim-wrap">
  <div className="popup-wrap withdrawal">
    <div className="popup">
      <h1>회원탈퇴 안내</h1>
      <div className="pop-conts">
        <p className="desc">
          로플러 서비스를 이용하시는데 불편함이 있으셨나요?<br />
          이용 불편 및 각종 문의 사항은 고객센터로 문의 주시면 성심 성의껏 답변 드리겠습니다.
        </p>
        <p className="notice">
          회원탈퇴 전, 아래의 주의사항을 반드시 확인해주시길 바랍니다.
        </p>
        <ul className="noti-lst">
          <li>
            <em className="emphasis">- 회원탈퇴를 위해서는 우선 멤버십이 해지되어 있어야 합니다.</em>
          </li>
          <li>
            - 회원탈퇴 시 멤버십 서비스 이용이 불가합니다.<br />
            <em className="emphasis">멤버십 이용 기간 중이어도 탈퇴를 누르는 즉시 사이트 이용이 불가합니다.</em>
          </li>
          <li>
            - 회원탈퇴 시 개인정보 및 로플러에서 만들어진 모든 데이터는 삭제됩니다. (동일한 아이디로 가입이 불가합니다.)<br />단, 아래 항목은 표기된 법률에 따라 특정 기간 동안 보관됩니다.
          </li>
          <li>
            · 계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
          </li>
          <li>
            · 대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래 등에서의 소비자보호에 관한 법률)
          </li>
          <li>
            · 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래 등에서의 소비자보호에 관한 법률)
          </li>
          <li>
            · 접속에 관한 기록: 사이트 방문 기록 3개월 (통신비밀보호법)
          </li>
          <li>
            - 보유하고 있던 SSAK 포인트는 탈퇴와 함께 삭제되며 환불되지 않습니다.
          </li>
        </ul>
        <form className="info-form" onSubmit={handleSubmit(withdraw)}>
          <p className="desc">회원탈퇴를 위해 계정 이메일 및 비밀번호를 입력해주세요.</p>
          <ul className="input-lst">
              <li>
                <label for="email">이메일</label>
                <div className="input-txt">
                  <input type="text" id="email" {...register('email')} />
                </div>
              </li>
              <li>
                <label for="password">비밀번호</label>
                <div className="input-txt">
                  <input type="password" id="password" {...register('password')} />
                </div>
              </li>
              <li>
                <button type="submit" className="check-normal-bt" >탈퇴하기</button>
              </li>
          </ul>
        </form>
      </div>
      <a href="#" className="bt-close"></a>
    </div>
  </div>
</div>
// <!--//Popup--> 
: <></> }

    </>
  );
};

export default MyPage;
