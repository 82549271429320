import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";
import Dimmer from "../component/Dimmer";

export const BPASSRedir = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ faildimmer, setFailDimmer ] = React.useState(false);
  const [ loginfaildimmer, setLoginFailDimmer ] = React.useState(false);
  const [ notconnecteddimmer, setNotConnectedDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // const schema = Yup.object({
  //     // username : Yup.string().email("정확한 이메일 주소를 사용해 주세요").required("id로 email주소를 입력해 주세요"),
  //     // password: Yup.string().min(4, "8자 이상을 입력해주세요").required("패스워드를 입력해 주세요")
  //     username : Yup.string().email("회원 가입에 사용한 정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
  //     password: Yup.string().min(4, "패스워드는 최소 4자리 입니다.").required("패스워드를 반드시 입력해 주세요")
  //   });
  // const { 
  //   register, 
  //   handleSubmit, 
  //   formState: { errors }, 
  // } = useForm( {
  //   reValidateMode: 'onChange',
  //   resolver: yupResolver(schema)
  // });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });

  const bpassLogin = (service, state, code) => {
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_BPASS_LOGIN}${service}/${state}/${code}`;
    
    function okFunc(res) {
      setLogin(res.data.access_token);
      const userinfo = _w.getAvatarInfo();
      console.log('user info : ', userinfo);

      // 로플러 대몬 호출
      if ( !_w.isMobile() )
        window.location = `${process.env.REACT_APP_URL_DAEMON}login?jwt=${res.data.access_token}&id=${userinfo.userID}`;

      navigate('/home');
    }
    function errFunc(e, code) {
      // 이외의 오류일경우
      // setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
      if ( code==406)
        setNotConnectedDimmer(true);
      else
        setLoginFailDimmer(true);
    }
    _w.wiceanFetch(url, 'POST',  null, okFunc, errFunc, null)
  }

  const bpassVerify = (service, state, code) => {
	  console.log("----- URL : ", process.env.REACT_APP_URL_BPASS_VERIFY);
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_BPASS_VERIFY}/${service}/${state}/${code}`;
    
    function okFunc(res) {
      console.log("CHECK RES : ", res);
      setDimmer(true);
      // navigate('/mypage');
    }
    function errFunc(e, code) {
      // 이외의 오류일경우
      // setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
      setFailDimmer(true);
    }
    _w.wiceanFetch(url, 'GET',  null, okFunc, errFunc, null)
  }


  useEffect( () => {
    console.log("Params : ", location);
    const params = new URLSearchParams(location.search);
    window.__aa = params;
    const error = params.get('error');
    if ( error )
    {
      const message = params.get('message');
      _w._l("BPASS QR인증 오류 :" + (message?message:""));
      alert('BPASS인증 과정에 문제가 발생했습니다.');
      navigate('/mypage');
      return;
    }
    else
    {
      const code = params.get('code');
      const state = params.get('state');

      console.log(`${state}, ${_w.loadLocalStorage('bpassstate')}`);

      if ( state!=_w.loadLocalStorage('bpassstate') ) // 저장된 state와 다르다면 오류
      {
        alert('BPASS인증의 state값이 다릅니다.');
        // navigate('/mypage');
        return;
      }

// 리다이렉션 됐을 때 로그인 되어있는지 여부에 따라 
// - 로그인이 되어 있으면 계정 등록
// - 로그인이 안되어 있으면 did 확인해서 로그인
// 을 하도록 한다.

      if ( _w.checkLogged() ) // 로그인이 되어 있다면
        // 서버 인증 과정 지속
        bpassVerify(process.env.REACT_APP_BPASS_SERVICE_ID, state, code);
      else  // 로그인이 안되어 있다면
        bpassLogin(process.env.REACT_APP_BPASS_SERVICE_ID, state, code);
    }
  }, [])

  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러" />
  <meta property="og:description" content="로플러 홈" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>로플러 홈</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
{/* <!--netstream-wrap--> */}
<div className="netstream-wrap home">
<RoflerCommonFooter />
</div>
{/* <!--//netstream-wrap-->    */}

<Dimmer type="1" okfunc={()=>{navigate('/')}} text="BPASS와 정상적으로 연동되었습니다." show={dimmer} />
<Dimmer type="1" okfunc={()=>{navigate('/mypage')}} text="BPASS인증에 실패했습니다." show={faildimmer} />
<Dimmer type="1" okfunc={()=>{navigate('/login')}} text="BPASS인증에 실패했습니다." show={loginfaildimmer} />
<Dimmer type="1" okfunc={()=>{navigate('/login')}} text={<>BPASS연동이 필요합니다.<br />먼저 비밀번호로 로그인 후 <br />설정에서 BPASS연동을 해주세요.</>} show={notconnecteddimmer} />
{/* {dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
} */}
    </>
  );
};

export default BPASSRedir;
