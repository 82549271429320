import React, { useEffect } from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";

import * as _w from "../utils/wiceanUtil";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Mail } from "@material-ui/icons";

export const PasswordChange = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const [ email, setMail ] = React.useState('');
  const navigate = useNavigate();

  const schema = Yup.object({
      oldPassword: Yup.string().min(8, "패스워드는 최소 8자리 입니다.").required("패스워드를 반드시 입력해 주세요"),
      password: Yup.string().min(8, "패스워드는 최소 8자리 입니다.").matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "대문자, 특수문자, 숫자 포함 8자 이상").required("패스워드를 반드시 입력해 주세요"),
      passwdConfirm: Yup.string().oneOf([Yup.ref('password'), null], "패스워드가 일치하지 않습니다.").required("패스워드를 반드시 입력해 주세요"),
    });
  const { 
    register, 
    handleSubmit, 
    formState: { errors }, 
  } = useForm( {
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { ref, ...rest} = register('email', 
    {
      required: true, 
      pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
    });

  // const letsStart = () => {
  //   const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}${email}`;
    
  //   function okFunc(res) {
  //     console.log("CHECK RES : ", res);
  //     if ( res.data?.user ) {
  //       setDimmer(<>이미 가입된 이메일입니다.</>)
  //     }
  //     else
  //       navigate('/signup', { state: {email}})
  //   }
  //   function errFunc(e, code) {
  //     // 이외의 오류일경우
  //     setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
  //   }
  //   _w.wiceanFetchLogin(url, 'GET',  null, okFunc, errFunc, null)
  // }

  // useEffect( () => {
  //   if ( _w.checkLogged() )
  //     navigate('home');
  // }, [])

  const onSubmit = (data) => {
    // const data = {
    //   passwdConfirm: '!@34QWer',
    //   password: '!@34QWer',
    // }
    console.log("섭밋 데이터 : ", data);

    let body = 
    {
      oldPasswd: data.oldPassword,
      passwd: data.password,
    }
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_CHANGE_PASSWORD}`
    
    function okFunc(res) {
      // navigate('/signup2', { state: {memID: res.data.memID, body} });
      setDimmer(<>패스워드를 성공적으로 변경했습니다.</>);
      navigate(-1);
    }

    function errFunc(e, code) {
      // alert('e.message : ' + e.message + " : " + code);
      if ( code==403 ) // 원래 패스워드를 잘못 입력했을 경우
      {
        setDimmer(<>원래의 패스워드를 정확히 입력해야<br />패스워드 변경이 가능합니다.</>);
        return;
      }
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetch(url, 'PATCH', body, okFunc, errFunc, null)
    // _w.wiceanFetchRelayCode(url, 'PATCH', body, okFunc, errFunc, null)
  }

  return (
    <>
<Helmet>
  <meta charset="UTF-8" />
  <meta content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover" name="viewport" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="로플러" />
  <meta property="og:description" content="로플러" />
  <meta property="og:url" content="" />
  <meta property="og:image" content="" />
  <meta name="mobile-web-app-capable" content="yes" />
  <meta name="format-detection" content="telephone=no" />
  <title>로플러</title>
  <link href="css/ui.css" rel="stylesheet"></link>
</Helmet>
{/* <!--netstream-wrap--> */}
<div className="netstream-wrap member">
  {/* <!--nets-header--> */}
  <header className="nets-header">
    <nav className="nets-nav">
      <h1 className="logo"><a href="#"><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
    </nav>
  </header>
  {/* <!--//nets-header--> */}
  {/* <!--netstream-conts--> */}
  <section className="netstream-conts">
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="my-page-wrap">
        {/* <!--password-change--> */}
        <div className="password-change">
          <h2>비밀번호 변경</h2>
          <ul className="my-page-input-lst">
            <li>
              <div className="input-txt">
                <input type="password" placeholder="기존 비밀 번호" {...register('oldPassword')}/>
              </div>
              <div className="validation-txt">{errors.oldPassword?.message}</div>
            </li>
            <li className="val-check">
              <div className="input-txt">
                <input type="password" placeholder="대문자, 특수문자 포함 8자 이상" {...register('password')}/>
              </div>
              <div className="validation-txt">{errors.password?.message}</div>
            </li>
            <li>
              <div className="input-txt">
                <input type="password" placeholder="새 비밀번호 재입력" {...register('passwdConfirm')}/>
              </div>
              <div className="validation-txt">{errors.passwdConfirm?.message}</div>
            </li>
          </ul>
          <div className="btn-wrap">
            <button type="submit" className="check-normal-bt">
              저장
            </button>
            <button type="button" className="cancle-normal-bt" onClick={() => {navigate(-1)}}>
              취소
            </button>
          </div>
        </div>
        {/* <!--//password-change--> */}
      </div>
    </form>
  </section>
  {/* <!--//netstream-conts--> */}

  <RoflerCommonFooter />
</div>
{/* <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
    </>
  );
};

export default PasswordChange;
