// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
// import { Formik } from 'formik';
// import {
//   Box,
//   Button,
//   Container,
//   Grid,
//   Link,
//   TextField,
//   Typography
// } from '@material-ui/core';
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
// import { BarunsonTextField } from '../component/BarunsonTextField';
// import { BarunsonButton } from '../component/BarunsonButton';
// import React, {useState } from "react";
import React from "react";
import * as Yup from 'yup';
// import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { Email } from "@material-ui/icons";
// import axios from 'axios';
// import RoflerCommonFooter from "src/component/RoflerCommonFooter";
import RoflerCommonFooter from "../component/RoflerCommonFooter";
import wiceanFetch, { setLogin } from "../utils/wiceanUtil";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { WSelect } from "../component/WComponents";

import * as _w from "../utils/wiceanUtil";
import { unstable_createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import Dimmer from "../component/Dimmer";

// import WInput from "../component/WComponents";

const _op = (i, u) => { return {label: `${i} ${u}`, value: i}; };

export const RoflerSignup = (props) => {
  const [ dimmer, setDimmer ] = React.useState(false);
  const navigate = useNavigate();
  const [ optionsBYear, setBYear ] = React.useState(null);
  const [ optionsBMonth, setBMonth ] = React.useState(Array(12).fill().map( (el, i) => _op(i+1, "월")) );
  const [ optionsBDay, setBDay ] = React.useState(Array(31).fill().map( (el, i) => _op(i+1, "일")));
  const [ year, setYear ] = React.useState(false);
  const [ goHomeDimmer, setGoHomeDimmer ] = React.useState(false);
  // const [ _email, setEmail ] = React.useState('');
  // const [ name, setName ] = React.useState('');
  const location = useLocation();

  // let _body = null; // 회원가입시 사용한 기본 데이터를 임시 저장해 signup2 페이지로 넘겨주기 위함.

  const schema = Yup.object({
      username : Yup.string().required("성함을 입력해 주세요").max(20, "20자 이내로 입력해 주세요"),
      email : Yup.string().email("정확한 이메일을 입력해 주세요").required("이메일 주소를 반드시 입력해 주셔야 합니다."),
        // .when([], (v, {originalValue}) => {console.log(`original Value : ${originalValue}`)} ),
      authcode : Yup.string().test('len', '6자리 인증 코드를 정확히 입력해 주세요', (val)=>{ /*console.log('v', val);*/ return (val.length===6 && "121212"<val && val<"989898")}).required("인증 코드는 필수입니다."),
        // .when([], (v, {authcode}) => {console.log(v), console.log(`auth Value : ${authcode}`)} ),
      password: Yup.string().min(8, "패스워드는 최소 8자리 입니다.").matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "대문자, 특수문자, 숫자 포함 8자 이상").required("패스워드를 반드시 입력해 주세요"),
      passwdConfirm: Yup.string().oneOf([Yup.ref('password'), null], "패스워드가 일치하지 않습니다.").required("패스워드를 반드시 입력해 주세요"),
    });
  const { 
    register, 
    trigger,
    handleSubmit, 
    formState: { errors }, 
    getValues
  } = useForm( {
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues:
      {
        email: '',
        byear: "2000",
        checkPolicy: true,
        checkWantInform: true,
        authcode: '',
      }
  });
  // const { ref, ...rest} = register('email', 
  //   {
  //     required: true, 
  //     pattern: { value: /\S+@\S+\.\S+/, message:"정확한 이메일 주소를 입력해 주세요."}
  //   });

  // 월이 선택되면 일 리스트를 바꾼다.
  const setDayOptions = (e) => {
    const month = e.target.value;
    if ( !month )
      return;

    const months = Array( new Date(year, month, 0).getDate() ).fill().map( (el, i) => _op(i+1, "일"));
    setBDay( months );
  }

  useEffect( () => {
    if ( optionsBYear )
      return;
    setBYear( Array(80).fill().map( (el, i) => _op(i+1940, "년") ) );
    // setBDay(1);
  }, [optionsBYear]);

  function _option(options, defaultLabel, defaultSelected)
  {
    // console.log("_options, 배열 드롸따", options, defaultLabel)
    if ( options ) {
      return options.map((item) => 
        <option 
          key={item.value}
          value={item.value} 
          selected={defaultSelected && item.value==defaultSelected}
        >
          {item.label}
        </option>);
    }
    return <option value="0">{defaultLabel}</option>;
  }

  const emailBlurHandler = (e) => 
  {
    console.log("블러 ", e);
  }
  const onSubmit = (data) => {
    // const data = {
    //   passwdConfirm: '!@34QWer',
    //   password: '!@34QWer',
    //   authcode: 696214,
    //   email: 'rofler@e.mail.com',
    //   username: '로플러',
    //   byear: '2003',
    //   bmonth: '5',
    //   bday: '9',
    //   checkPolicy: true,
    //   checkWantInform: true
    // }
    console.log("섭밋 데이터 : ", data);

    // if ( !data.checkPolicy || !data.checkWantInform )
    if ( !data.checkPolicy )
    {
      setDimmer(<>개인 정보 수집에 동의해 주세요</>);
      return;
    }

    let body = 
    {
      email: data.email,
      passwd: data.password,
      name: data.username,
      birthymd: new Date(data.byear, data.bmonth, data.bday).toISOString().split('T')[0],
      authcode: data.authcode,
    }
    const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_SIGNUP}`
    
    function okFunc(res) {
      // console.log("회원 가입후 res : ", res);
      if ( res.data.usePay!==1 )
        setGoHomeDimmer( <>회원 가입에 성공했습니다.<br />로그인하고 로플러를 이용해 주세요.</> );
        // navigate('/home', { state: {memID: res.data.memID, body} });
      else
        navigate('/signup2', { state: {memID: res.data.memID, body} });
    }

    function errFunc(e, code) {
      // alert('e.message : ' + e.message + " : " + code);
      if ( e.message.indexOf('wrong authcode')>=0 || `${code}`=="406" ) // 인증코드 오류
      {
        setDimmer(<>인증 코드가 잘못 되었거나<br />시간이 초과되었습니다.<br />6자리 인증코드를 정확히 입력해 주세요.</>);
        return;
      }
      if ( e.message.indexOf('email conflict')>=0 || `${code}`=="409" ) // 이미 가입된 이메일
      {
        setDimmer(<>이미 가입된 이메일입니다.</>);
        return;
      }
      setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
    }
    _w.wiceanFetchRelayCode(url, 'POST', body, okFunc, errFunc, null)
  }

  function sendAuthcodeHandler()
  {
    const values = getValues();
    const validate = trigger(["username", "email"]);

    // username과 email에 대해 임시 validate를 하고 문제가 없을 경우 이후 조치를 진행한다.
    validate.then(
      function(val)
      {
        const values = getValues();
        console.log(errors);
        console.log(values);
        if ( errors.email || errors.username || values.email.length==0 || values.username.length==0 )
        {
          setDimmer(<>이메일과 이름을<br />정확히 입력해 주세요</>);
          return;
        }
        var email = values.email;
        var name = values.username;
        const url = `${process.env.REACT_APP_URL_BASE_API}${process.env.REACT_APP_URL_USER_REG_EMAIL}`
        const _data = { email, name };
        
        function okFunc(res) {
          setDimmer(<>해당 이메일로 <br /> 인증번호가 발송되었습니다.<br />인증 번호를 입력해 주세요</>);
        }
        function errFunc(e, code) {
          if ( e.message.indexOf('sendmail error')===0 )
          {
            setDimmer(<>메일 전송에 실패했습니다.<br />정확한 메일 주소를 적으셨나요?</>);
            return;
          }
          setDimmer(<>서버와 통신중<br />오류가 발생했습니다.</>);
        }
        _w.wiceanFetchLogin(url, 'POST',  _data, okFunc, errFunc, null)

      }
    )
  }

  function okGoHome()
  {
    navigate('/home');
  }

  // console.log('메일 세팅됨', location.state?.email);
  let emailRegister;
  if ( location.state && location.state.email )
    emailRegister = register('email', {value: location.state?.email});
  else
    emailRegister = register('email');
  const nameRegister = register('username');

  // if ( location.state?.email ) // 랜딩 페이지에서 이메일이 넘어왔다면,
  // {
  //   if ( email.length==0 )
  //   {
  //     setValue('email', location.state.email)
  //     console.log('메일 세팅됨', location.state);
  //   }
  // }


  return (
    <>
{/* <!netstream-wrap--> */}
<div className="netstream-wrap member">
  {/* <!--nets-header--> */}
  <header className="nets-header">
      <nav className="nets-nav">
          {/* <!--0723 HJ edit:s--> */}
          <h1 className="logo"><a href="#"><img src="images/logo-bi.png" alt="로고이미지" /></a></h1>
          {/* <!--0723 HJ edit:e--> */}
          <ul className="menu-lst">
              {/* <li className="sign-bt"><button className="midium-confirm-bt" type="button">Sign Up</button></li> */}
          </ul>
      </nav>
  </header>
  {/* <!--//nets-header--> */}
  {/* <!--netstream-conts--> */}
  <section className="netstream-conts">
    {/* <!--join-wrap--> */}
    <div className="join-wrap">
        {/* <span className="step-num">1/2 단계</span> */}
        <h2>아이디를 생성하세요</h2>
        <p className="step-desc">몇 단계만 더 거치면 회원님의 취향에 걸맞는<br /> 각양각색의 영상을 찾아보실 수 있습니다.</p>
          <form className="join-form" onSubmit={handleSubmit(onSubmit)}>
            <ul className="input-lst">
                <li>
                    {/* <!--0729 HJ edit :s--> */}
                    <div className="input-txt">
                        <span className="info-txt">이름</span>
                        <input 
                          type="text" 
                          placeholder="8자 내외" 
                          // onChange={ (e) => { nameRegister.onChange(e); setName(e.target.value); }}
                          // onBlur={ (e) => { nameRegister.onChange(e); setName(e.target.value); }}
                          ref={nameRegister.ref}
                          // defaultValue={email}
                          {...nameRegister}
                        />
                    </div>
                    <div className="validation-txt">{errors.username?.message}</div>
                    {/* <!---0729 HJ edit:e--> */}
                </li>
                {/* <!--0729 HJ edit :s--> */}
                <li className="select-wrap">
                    <span className="info-txt">생년월일</span>
                    <ul className="select-lst">
                        <li>
                          {/* <WSelect {...register('byear')} options={optionsBYear?optionsBYear:{label: '년', value: 0}} /> */}
                          <select {...register('byear')} defaultValue="2000">
                            {/* {optionsBYear && optionsBYear.map((item) => <option value={item.value}>{item.label}</option>)} */}
                            {_option(optionsBYear, '년', 2000)}
                          </select>
                        </li>
                        <li>
                          {/* <WSelect {...register('bmonth')} options={optionsBMonth} /> */}
                          <select {...register('bmonth')} onChange={ (e)=>{setDayOptions(e)}}>
                            {/* {optionsBMonth && optionsBMonth.map((item) => <option value={item.value}>{item.label}</option>)} */}
                            {_option(optionsBMonth, '월', 1)}
                          </select>
                        </li>
                        <li>
                          {/* <WSelect {...register('bday')} options={optionsBDay} /> */}
                          <select {...register('bday')} >
                            {/* {optionsBDay && optionsBDay.map((item) => <option value={item.value}>{item.label}</option>)} */}
                            {_option(optionsBDay, '일', 1)}
                          </select>
                        </li>
                    </ul>
                    <div className="validation-txt"></div>
                </li>
                  {/* <!---0729 HJ edit:e--> */}
                <li className="email-wrap">
                      {/* <!---0729 HJ edit:s--> */}
                    <div className="input-txt" onClick={()=>{ setDimmer(<>서버에 존재하는지 확인된 이메일 입니다.<br />이메일을 변경하시려면 이전 화면에서 진행해 주세요.</>); }} >

                        <span className="info-txt">이메일 주소</span>
                        <input type="text" defaultValue={location.state.email} />
                        <input 
                          type="text" 
                          // onChange={ (e) => { console.log("이메일 세팅 : ", e); emailRegister.onChange(e); setEmail(e); }}
                          // onBlur={ (e) => { console.log("이메일 세팅2 : ", e); emailRegister.onChange(e); setEmail(e); }}
                          ref={emailRegister.ref}
                          placeholder="xxxx@xxxx.com" 
                          className="email-input" 
                          {...emailRegister}
                          hidden
                        />
                    </div>
                    <div className="validation-txt">{errors.email?.message}</div>
                    {/* <!---0729 HJ edit:e--> */}
                    <button className="square-confirm-bt" onClick={sendAuthcodeHandler}>코드 전송</button>
                </li>
                  <li>
                    {/* <!---0729 HJ edit:s--> */}
                    <div className="input-txt">
                        <span className="info-txt">인증 코드</span>
                        <input type="text" placeholder="" {...register('authcode')} />
                    </div>
                    {/* <input type="hidden" placeholder="" {...register('authcode')} /> */}
                    <div className="validation-txt">{errors.authcode?.message}</div>
                    {/* <!---0729 HJ edit:e--> */}
                </li>
                <li>
                    {/* <!---0729 HJ edit:s--> */}
                    <div className="input-txt">
                        <span className="info-txt">비밀번호를 입력하세요.</span>
                        <input type="password" placeholder="대문자, 특수문자 포함 8자 이상" {...register('password')} />
                    </div>
                    <div className="validation-txt">{errors.password?.message}</div>
                    {/* <!---0729 HJ edit:e--> */}
                </li>
                <li>
                    {/* <!---0729 HJ edit:s--> */}
                    <div className="input-txt">
                        <span className="info-txt">비밀번호 확인</span>
                        <input type="password" placeholder="" {...register('passwdConfirm')} />
                    </div>
                    <div className="validation-txt">{errors.passwdConfirm?.message}</div>
                    {/* <!---0729 HJ edit:e--> */}
                </li>
            </ul>
              <div className="btn-wrap">
                <button className="big-confirm-bt" type="submit">회원 가입</button>
            </div>
            <ul className="txtarea-lst">
              <li>
                <span className="tit-txt">이용 약관</span>
                <textarea style={{witeSpace: "pre"}} 
value={` Rofler는 인터넷 연결 지원 컴퓨터 및 기타 디바이스(' Rofler 지원 디바이스')에서 인터넷 스트리밍을 통해 회원에게 영화(' Rofler 콘텐츠')를 제공하는 블록체인 기반의 P2P스트리밍 서비스로, P2P 기술을 접목하여 동의한 유저들은 스토리지와 네트워크 대역폭을 CDN대신 할애하는 방식으로 스트리밍 서버 비용을 절감하고 유저들에게 이를 포인트로 돌려주며 제작사들이 저렴한 비용으로 영상을 제공하는 신개념의 OTT영상 플랫폼입니다.
본 이용 약관은 사용자가 Rofler 서비스를 이용하는 데 적용됩니다. 본 이용 약관에 언급되는 ' Rofler 서비스' 또는 '당사 서비스'는 모든 특성과 기능, 웹사이트, 모바일에서의 사용자 인터페이스뿐만 아니라 Rofler 서비스와 관련된 모든 콘텐츠와 소프트웨어를 포함하는 스트리밍 서비스를 의미합니다.

1. 멤버쉽
1.1. Rofler 멤버쉽은 해지 시까지 지속되며, Rofler 서비스를 이용하려면 인터넷 액세스 및 Rofler를 지원하는 디바이스가 필요합니다.
1.2. 회원의 Rofler 멤버쉽과 관련된 세부 사항은 Rofler 웹사이트에서 확인할 수 있습니다.
2. Rofler요금제
2.1. 회원은 Rofler 멤버십에 가입하는 과정에서 월정액으로 지불 방식과 VOD 컨텐츠를 별도로 구입할 수 있는 방식을 선택할 수 있습니다. 즉, 회원은 가입과 동시에 Rofler의 유료 서비스를 구입하는 것입니다.
2.2. Rofler는 회원 가입 시 사용한 디바이스 ID, 결제 수단, 이메일 주소 등과 같은 정보를 이용 자격 판단에 활용할 수 있으며, 다른 프로모션과 함께 사용할 경우 특정한 제한이 가해질 수 있습니다.
3. 결제 및 해지
3.1. (결제 방법) Rofler 서비스 월정액 요금과 서비스 이용과 관련해 발생하는 기타 청구 금액(예: 세금, 거래 수수료 등)은 Rofler ‘계정’ 페이지에 표시된 결제일에 결제 금액이 청구됩니다. 결제 일은 회원이 Rofler 가입 일자와 서비스 이용 유형에 따라 결정됩니다.
3.2. (결제 수단 제공 및 제한) Rofler 서비스의 원활한 사용을 위하여 회원은 하나 이상의 결제 수단을 제공해야 합니다. 미결제 금액에 대한 책임은 회원에게 있으며, 유효기간 만료, 잔고 부족 등과 같은 회원 개인의 사유로 결제가 정상적으로 처리되지 않는다면 유효한 결제 수단에 청구가 완료될 때까지 회원의 서비스 이용이 제한될 수 있습니다. 일부 결제 수단의 경우, 발급자가 회원에게 해외 거래 수수료나 기타 결제 수단 처리 관련 수수료 등의 수수료를 청구할 수 있으니, 자세한 사항은 결제 수단 서비스 제공업체를 통해 확인하시기 바랍니다.
3.3. (요금제 해지) 회원은 언제라도 Rofler 멤버십을 해지할 수 있으며, 이 경우 결제한 요금제 기간만큼은 Rofler 서비스를 계속 이용할 수 있습니다.
3.4. (환불 요청) 회원이 결제일로부터 7일 이내 Rofler 콘텐츠를 시청하지 않고 Rofler 멤버십을 해지하는 경우, 회원은 해당 결제 주기에 청구된 멤버십 요금을 전액 환불 요청할 수 있습니다.
4. Rofler 서비스
4.1. Rofler 서비스의 회원이 되려면 만 19세 이상이어야 합니다. 미성년자의 경우 성인의 감독하에서만 서비스를 이용할 수 있습니다.
4.2. Rofler 서비스는P2P스트리밍(rofler)를 통해 온라인 스트리밍 서비스를 할 수 있으며 P2P를 활용한 서버 비용의 절감을 통해 발생된 수익은 유저들에게 포인트로 보상해줍니다. 이와 관련한 포인트 정산, 사용내역, 네트워크 대역폭을 활용한 정보가 바른손의 블록체인 기술을 활용하여 투명하게 관리됩니다.
4.3. Rofler 서비스와 이 서비스를 통해 제공되는 모든 콘텐츠는 개인적, 비상업적 용도로만 사용해야 하며, 가구 구성원이 아닌 개인과 공유해서는 안 됩니다. Rofler 멤버십 가입 기간 동안 Rofler는 회원에게 Rofler 서비스에 액세스하고 Rofler 콘텐츠를 이용할 수 있는 제한적이고 비독점적이며 양도 불가능한 권한을 부여합니다. 앞서 언급한 권한을 제외하고는 어떠한 권리, 소유권 또는 이권도 회원에게 이전되지 않습니다. 또한 회원은 대중 공연이나 전시를 목적으로 Rofler 서비스를 이용해서도 안 됩니다.
4.3. 회원은 Rofler가 서비스를 제공하고 Rofler 콘텐츠에 대한 라이선스를 허용한 지역 내에서만 해당 콘텐츠 이용이 가능합니다. 시청 가능한 콘텐츠는 수시로 변경될 수 있으며, Rofler 서비스는 정기적으로 업데이트됩니다.
4.4. Rofler 콘텐츠는 지원되는 특정 디바이스에서 일시적으로 저장해 오프라인으로 시청할 수 있습니다('오프라인 콘텐츠'). 단, 계정당 허용되는 오프라인 콘텐츠 수, 오프라인 콘텐츠를 저장할 수 있는 디바이스의 최대 수, 오프라인 콘텐츠 시청을 시작해야 하는 기간, 오프라인 콘텐츠를 이용할 수 있는 기간 등에 제약이 따릅니다.
4.5. 회원은 모든 관련 법률, 규칙, 규정 또는 Rofler 서비스 및 콘텐츠 사용과 관련된 기타 제한에 따라 Rofler 서비스 내의 모든 기능을 포함한 Rofler서비스를 이용하는 데 동의합니다. 회원은 Rofler 서비스에 포함되어 있거나 Rofler 서비스를 통해 획득한 콘텐츠와 정보를아카이브, 복제, 배포, 수정, 전시, 시연, 출판, 라이선스, 2차적 저작물로 생성, 판매 권유하거나 이용하지 않을 것에 동의합니다. 다만, rofler서비스를 위한 P2P 스트리밍 이용시에는 법을 위반하지 않는 범위 내에서 허용합니다.회원은 소프트웨어 바이러스나 기타 컴퓨터 코드, 파일이나 프로그램을 포함하여, Rofler 서비스와 관련된 컴퓨터 소프트웨어나 하드웨어 또는 통신 장비의 기능을 방해, 파괴 또는 제한하기 위해 설계된 자료를 업로드, 게시, 이메일 전송, 또는 다른 방식으로 발송, 전송해서는 안 됩니다. 회원이 본 이용 약관에 명시된 조항을 위반하거나 불법복제, 명의도용, 신용카드 부정 사용, 기타 이에 준하는 사기행위 또는 불법행위에 가담하는 경우, Rofler는 회원의 서비스 사용을 종료시키거나 제한할 수 있습니다.
4.6. Rofler 소프트웨어는 ㈜바른손에 의해 자체 사용 목적으로 개발되었으며, Rofler 지원 디바이스를 이용해 Rofler로부터 콘텐츠를 합법적으로 스트리밍하고 시청하는 용도로만 사용할 수 있습니다. Rofler 소프트웨어는 디바이스와 매체별로 달라질 수 있으며, 기능도 디바이스마다 다를 수 있습니다. 회원은 Rofler 서비스 이용을 위해 타사 라이선스 계약의 대상인 타사 소프트웨어가 필요할 수 있음을 인지합니다. 회원은 Rofler 소프트웨어와 관련 타사 소프트웨어의 업데이트된 버전을 자동으로 다운로드하는 데에 동의합니다.
5. 비밀번호 및 계정 접근권한
Rofler 계정을 생성하여 본인의 등록 결제 수단에 요금이 청구되는 회원(이하 '계정 소유자')은 Rofler 계정 사용으로 인한 모든 활동에 대해 책임이 있습니다. 계정해킹, 명의도용, 신용카드 부정사용이나 기타 이에 준하는 사기행위 또는 불법행위로부터 회원 및 Rofler 등을 보호하기 위해 Rofler는 회원 계정을 종료하거나 보류 조치를 취할 수 있습니다.
6. 보증 및 책임의 제한
Rofler는 안정적인 서비스 제공을 위해 노력합니다. 다만, Rofler의 책임 없이 서비스 중단이나 오류가 발생할 수 있습니다. Rofler는 고의 또는 과실로 인하여 회원이 입은 손해를 배상하되, 특별한 사정으로 통상적인 범위를 벗어나는 손해는 Rofler의 고의 또는 중대한 과실을 제외하고는 책임지지 않습니다.
7. 기타
7.1. (준거법) 본 이용 약관은 대한민국 법률의 적용을 받고 그에 따라 해석됩니다.
7.2. (불원 자료) Rofler는 Rofler 콘텐츠와 관련된 불원 자료 또는 아이디어를 인정하지 않으며, Rofler 콘텐츠 또는 다른 미디어상의 프로그램과 Rofler에 전송된 자료 또는 아이디어의 유사성에 대해 책임지지 않습니다.
7.3. (고객 센터) Rofler 서비스 및 기능에 대한 자세한 정보를 확인하고자 하거나 계정과 관련하여 도움이 필요한 경우 Rofler 홈페이지에 제공된 이메일(support@barunson.co.kr)로 문의주시기 이용하시기 바랍니다.
7.4. (이용 약관의 변경 및 양도) Rofler는 수시로 본 이용 약관을 변경할 수 있습니다. Rofler는 변경 사항이 적용되기 최소 30일 이전에 관련 내용을 회원에게 통지합니다. Rofler는 회원과의 계약(관련 권리 및 의무 포함)을 관련 법률에서 정한 절차에 따라 양도 또는 이전할 수 있으며, 회원은 이에 동의하지 않는 경우 언제라도 Rofler 멤버십을 해지할 수 있습니다.
7.5. (전자적 커뮤니케이션) Rofler는 회원 계정과 관련한 정보(예: 결제 승인, 청구서, 비밀번호 또는 결제 수단 변경, 확인 메시지, 공지)를 회원이 등록 당시 기재한 이메일 주소로 이메일을 전송하는 등의 전자적 형태로만 통지합니다.
                `}>
                </textarea>
              </li>
              <li>
                <span className="tit-txt">개인정보 처리 방침</span>
                <textarea style={{witeSpace: "pre"}} 
value={` Rofler (이하 “Rofler”)는 고객(회원 및 비회원 포함)님의 개인정보를 적극적으로 보호하며 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 모든 관련 법령을 준수하기 위하여 개인정보 처리방침(이하 “개인정보처리방침")을 제정하고 이를 준수하고 있습니다. 또한 아래에 게시된 개인정보보호 정책을 통하여 고객님이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 설명합니다.

본 개인정보 처리방침은 회사가 운영하는 서비스에 공개되며, 고객님이 언제나 쉽게 찾아볼 수 있도록 하고 있습니다.회사의 개인정보 처리방침은 다음의 내용을 포함합니다.
1. 개인정보의 수집/이용 등에 대한 동의방법
2. 수집하는 개인정보 항목 및 개인정보의 수집/이용 목적
3. 개인정보의 보유, 이용기간 및 파기
4. 개인정보의 제3자 제공 및 공유에 대한 동의
5. 개인정보에 대한 고객의 권리와 그 행사 방법
6. 개인정보의 열람, 증명 또는 정정
7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
8. 개인정보의 수집과 이용 또는 제공에 대한 동의 철회
9. 만14세 미만 아동의 개인정보 보호
10. 고객 의견수렴 및 불만처리
11. 개인정보의 기술적, 관리적 보호
12. 개인정보 침해 관련 상담 및 신고
13. 개인정보 처리방침의 고지 또는 민원 처리
14. 본 개인정보 처리방침의 변경
1. 개인정보수집/이용 등에 대한 동의방법
고객님의 개인정보 수집, 이용, 제3자 제공 및 처리업무의 위탁 등에 대한 동의의 방법은 서비스 회원가입 시 PC 웹, 모바일 웹/앱 홈페이지(http://rofler.io)에 게시된 ‘서비스 이용약관’ 및 '개인정보처리방침'을 숙지하신 후 홈페이지에서 제공하는 ‘동의 버튼’을 클릭함으로써 완료됩니다.

계정 또는 고객 센터 문의 방법과 관련해 질문이 있는 경우 로플러 홈페이지(rofler.io)에 접속해 Rofler 온라인 고객 센터를 방문하시기 바랍니다.본 개인정보 처리방침에 대한 질문이나 Rofler의 회원 개인정보, 쿠키 또는 유사 기술 이용에 관해 질문이 있는 경우 이메일(support @barunson.co.kr)로 문의하시기 바랍니다. 회원의 개인정보 데이터를 관리하는 업체는 ㈜바른손입니다.회원이 Rofler에 도움을 요청하는 경우, 요청 사항을 해결하기에 앞서 회원 및 Rofler의 안전을 위해 신원 확인을 요구할 수도 있음을 참고해 주시기 바랍니다.
2. 수집하는 개인정보 항목 및 개인정보의 수집/이용 목적
수집/이용 목적
Rofler는 Rofler 서비스 및 마케팅 활동을 제공, 분석, 관리, 개선, 회원의 등록, 주문 및 결제를 처리하고, 다양한 주제에 관하여 회원들과 소통하기 위해 정보를 이용합니다.예를 들어, 다음 목적으로 해당 정보를 이용합니다.
• Rofler 시스템 보안을 확립하고, 부정행위를 방지하고, Rofler 계정의 보안을 유지하기 위해
• 사기 등 법을 위반하거나 금지될 가능성이 있는 행위를 방지, 감지 및 조사하고, Rofler 약관(Rofler 가입 혜택 이용 자격 유무 및 이용 가능한 혜택의 종류 결정 그리고 특정 디바이스에서 Rofler 이용 약관에 따라 해당 계정 사용이 허용되는지 여부 결정 등)을 적용하기 위해
• 사용자를 파악하고, 서비스(사용자 인터페이스 환경 및 서비스 품질 포함)를 개선하고, 콘텐츠 선정 및 배포를 최적화하기 위해
• Rofler 관련 뉴스, Rofler에서 이용 가능한 신규 기능 및 콘텐츠에 관한 상세 정보, 특별 혜택, 프로모션 공지, 고객 설문조사를 회원에게 전송하는 등 Rofler 서비스와 관련해 회원과 소통하고, 비밀번호 재설정 요청과 같은 운영 관련 요청 발생 시 고객을 지원하기 위해 이러한 소통은 이메일 등의 다양한 방식으로 이루어질 수 있습니다
회원이 Rofler에 제공하는 정보
Rofler는 다음 정보를 포함해 회원이 Rofler에 제공하는 정보를 수집합니다.
회원의 이름, 이메일 주소, 생년월일, 결제 수단.Rofler는 회원이 Rofler 서비스 이용 과정에서 정보를 입력하는 경우, 고객 센터와 상담하는 경우 또는 설문 조사나 마케팅 프로모션에 참여하는 경우 등 다양한 방식을 통해 이러한 정보를 수집합니다.
회원계정 설정(웹사이트의 '계정' 섹션에 설정된 환경 설정 포함) 시 입력하는 정보 또는 Rofler 서비스나 타사를 통해 Rofler에 제공하는 정보를 수집합니다.
회원 정보 보유기간
회원 탈퇴 후 30일까지 보관합니다.
(선택) 기타 개인정보 수집항목
수집/이용 목적
서비스 이벤트 및 업데이트 정보 제공, 타사와의 업무제휴를 통하여 제공하는 신상품 소개, 이벤트 안내
※ 고객님은 선택항목을 입력하지 않아도 서비스 가입이 가능하지만, 일부 서비스 제공에 제한이 있을 수 있습니다.
수집 방법
• 회원 가입 및 서비스 이용과정에서 고객님이 개인정보 수집에 대해 동의하고 직접 정보를 입력하여 수집
• 고객센터 이메일 등 고객님이 제시하는 개인정보 수집
• 제휴 서비스 및 단체 등으로부터의 제공
• 로그 분석 프로그램을 통한 생성정보 수집
• 쿠키(cookie)에 의한 정보 수집
서비스 이용 과정이나 사업처리 과정에서 생성되어 수집되는 정보
서비스 이용과정이나 사업처리 과정에서 이용요금 정산 및 개인 서비스 제공을 위하여 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
• 발/수신번호 및 통화시각
• 사용도수, 서비스 이용시간 및 이용기록
• 접속로그 및 접속IP정보, 쿠키
• 결제기록(수단, 내역, 사용처)
• 이용정지 기록
서비스 이용과정에서 자동수집장치에 의해 수집되는 정보
서비스 이용 과정에서 자동수집장치에 의해 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
• 이동통신사정보
• 단말기 모델명 및 단말식별번호(단말기 아이디)
• OS(Android/iOS) 및 버전
• PUSH 토큰
• 단말접속일시
• 세션아이디
• 단말 MAC Address
• 비식별 행태정보 (광고식별자 등)
※ 자동수집장치에 의해 수집되는 정보는 개인을 식별할 수 없는 형태이며 회사는 수집된 정보를 바탕으로 개인을 식별하는 일체의 활동을 하지 않습니다.

※ 비식별 행태정보는 이용자의 인구통계학적 특성과 관심, 기호, 성향의 추정을 통한 서비스 제공•개선, 신규 서비스 개발, 맞춤형 콘텐츠 추천 및 관심기반 마케팅/광고 등에 활용하기 위한 목적으로만 사용됩니다.

※ 자동수집장치에 의해 수집되는 정보에 대한 내용과 수집거부 방법은 “10. 자동수집장치의 설치/운영 및 거부방법”을 참고하시기 바랍니다.
3. 개인정보의 보유, 이용기간 및 파기
1) 이용기간 및 보유기간
고객님의 개인정보는 회사가 고객님에게 서비스를 제공하는 기간(이용기간) 또는 분쟁처리 기간(보유기간) 동안에 한하여 보유하고 이를 활용합니다.
가.이용기간: 서비스 가입일 ~해지일 까지
나.보유기간: 불량회원의 부정한 이용의 재발 방지 및 서비스 혼선 방지를 위해 이용계약 해지일로부터 30일간 또는 해당 이용 목적이 종료된 때까지 보유
2) 개인정보의 보관 및 보존기간
상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 개인정보를 보관합니다.이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
• 계약 또는 청약철회 등에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)
• 대금결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)
• 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래 등에서의 소비자보호에 관한 법률)
• 접속에 관한 기록: 사이트 방문 기록 3개월(통신비밀보호법)
• 그 외: 국세기본법 등에 따라 거래기록 등을 보관해야 하는 경우, 정보주체에게 별도 동의를 받은 경우
3) 개인정보의 파기절차 및 방법
가.파기절차: 고객님이 서비스 가입 등을 위해 제공하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 보유 사유에 따라 일정기간 저장된 후, 아래 다항에 명시한 방법으로 파기됩니다.별도 DB에 옮겨진 개인정보는 법률에 의해 보유되는 경우를 제외하고는 이외의 다른 목적으로 이용되지 않습니다.
나.파기대상: 보유기간 및 관련법령에 따른 보존기간이 종료된 고객정보
다.파기방법
• 서면에 작성, 출력된 개인정보: 분쇄하거나 소각
• DB 등 전자적 파일 형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적 방법으로 삭제
4. 개인정보의 제3자 제공 및 공유에 대한 동의
회사는 고객님의 개인정보를 '서비스 이용약관' 또는 '개인정보처리방침'에서 명시한 범위 내에서 이용하며, 명시한 범위를 넘어서 개인정보를 이용하거나 제3자에게 제공하지 않습니다.다만, 고객님의 사전동의가 있거나, 관련 법령이 정한 규정과 절차에 따라 제공하는 경우는 예외입니다.단, 향후 개인정보/신용정보를 제공받는 제3자와의 신규계약 또는 해지 등 계약 변경에 따라 제3자는 변경될 수 있으며, 이 경우 홈페이지의 개인정보 처리방침 등을 통해 공지하는 등 관계법령에 따라 요구되는 절차를 준수하겠습니다.
정보의 공개
Rofler는 아래에 설명된 바와 같이 특정한 목적으로 제3자에게 회원 정보를 공개합니다.
• Rofler 기업 그룹: Rofler는 Rofler 서비스 액세스 권한 부여, 고객 센터 지원 제공, 서비스 개선 관련 의사결정, 콘텐츠 개발 및 본 개인정보 처리방침의 '정보의 이용' 항목에 기술된 기타 목적에 필요한 경우 개발사인 ㈜바른손과 회원 정보를 공유합니다.
• 블록체인 상의 공개: Rofler가 제공하는P2P기술을 활용하여 스토리지 대역폭을 할당해 준 유저들의 정보 및 사용정보는 블록체인 상에 투명하게 공개됩니다.
• 서비스 제공업체: Rofler는 Rofler를 대신해 서비스를 수행하거나 Rofler를 도와 회원에게 서비스를 제공하는 작업에 다른 회사, 에이전트 또는 협력업체('서비스 제공업체')를 이용합니다.서비스를 제공하는 과정에서 서비스 제공업체는 회원의 개인정보 및 기타 정보에 접근할 수 있습니다.Rofler는 서비스 제공(서비스 유지 및 개선 포함)과 관련된 경우를 제외하고 서비스 제공업체가 회원의 개인정보를 사용하거나 공개하도록 허가하지 않습니다.
• 파트너사: 위에서 설명한 것처럼, 회원은 한 곳 이상의 Rofler 파트너사와 계약 관계일 수 있습니다.이러한 경우 Rofler는 회원에게 Rofler 서비스를 제공하고 Rofler 서비스의 이용 가능 여부에 대한 정보를 제공하는 과정에서 파트너사와 협력하고자 특정 정보를 파트너사에 공유할 수 있습니다.
• 프로모션 제공: Rofler는 회원의 참여를 위해 Rofler가 제3자와 회원 정보를 공유해야 하는 공동 프로모션이나 프로그램을 제공하기도 합니다.이러한 유형의 프로모션을 수행하는 과정에서 Rofler는 회원 이름 등의 프로모션 수행 관련 정보를 공유할 수 있습니다.이러한 제3자는 자체 개인정보 보호 정책을 준수할 책임을 지게 됩니다.
• Rofler 및 기타 당사자의 보호: Rofler와 서비스 제공업체는 (a) 관련 법률, 규정, 법적 절차 또는 정부 요청에 응하기 위해, (b) 이용 약관 위반 가능성에 대한 조사를 포함해 관련 이용 약관을 적용하기 위해, (c) 불법적이거나 위법성이 의심되는 행위(결제 사기 포함), 보안 또는 기술적 문제를 감지, 방지 또는 해결하기 위해, (d) 법률상 요구되거나 허용되는 범위에서 Rofler, Rofler 서비스 사용자 또는 대중의 권리, 재산 또는 안전에 대한 위해로부터 보호하기 위해, 회원의 개인정보 및 기타 정보의 공개가 필요하다고 판단하는 경우 해당 정보를 공개하고 이용할 수 있습니다.
• 사업 양도: 조직개편, 구조조정, 합병 또는 매각, 기타 자산 양도와 관련해 정보 인수 당사자가 Rofler의 개인정보 처리방침과 일관된 방식으로 회원의 개인정보를 존중하기로 합의하는 경우에 한해 Rofler는 개인정보를 포함한 정보를 이전합니다.
Rofler는 정보 공유 과정 중 개인정보를 다른 국가로 이전할 때 항시 이러한 정보가 본 개인정보 처리방침에 따라, 데이터 보호에 관한 관련 법률의 허용 범위 내에서 이전되도록 최선을 다할 것입니다.또한 회원은 아래의 방식으로 본인 정보를 공개하기로 선택할 수 있습니다.
• Rofler 서비스 중 일부는 회원의 스마트 디바이스에 설치된 클라이언트와 애플리케이션을 이용해 회원이 이메일 등의 공유 애플리케이션으로 정보를 공유할 수 있는 도구를 포함할 수 있습니다.
• 회원은 소셜 플러그인과 기타 유사한 기술을 통해 정보를 공유할 수 있습니다.
소셜 플러그인 및 소셜 애플리케이션은 해당 소셜 네트워크에서 자체적으로 운영하므로 해당 업체의 이용 약관 및 개인정보 보호 정책이 적용됩니다.
회원의 선택사항: 이메일
Rofler로부터 이메일 등의 특정 커뮤니케이션의 수신을 원치 않는 경우 수신을 거부하시면 됩니다.계정 거래 관련 메시지와 같이 Rofler가 보내는 서비스 관련 커뮤니케이션은 수신 거부할 수 없음을 유의하시기 바랍니다.
5. 개인정보에 대한 고객의 권리와 그 행사 방법
고객님은 언제나 홈페이지(http://rofler.io)를 방문하여 등록되어 있는 자신의 개인정보를 조회하거나 Rofler가 보유하고 있는 오래되거나 부정확한 개인정보를 수정 또는 업데이트할 수 있으며, 개인정보의 수집과 이용, 위탁 또는 제공에 대한 동의의 철회 및 가입 해지를 요청할 수 있습니다.
6. 개인정보의 열람, 증명 또는 정정
Rofler는 서비스 사용자의 개인정보가 소실, 도난, 무단 액세스, 이용 및 수정되지 않도록 합리적인 방식으로 행정적, 논리적, 물리적 및 관리적 방법을 활용합니다.
1) 고객님은 홈페이지(http://rofler.io)에 제공된 고객센터 이메일을 통해 개인정보에 대한 열람, 증명을 요구할 수 있습니다.
2) 고객님이 본인의 개인정보에 대한 열람, 증명을 요구하는 경우 고객님의 신분을 증명할 수 있는 주민등록증, 여권, 운전면허증(신형) 등의 신분증명(사본)을 제시 받아 본인 여부를 확인합니다.
3) 고객님의 대리인이 열람, 증명을 요구하는 경우에는 대리 관계를 나타내는 위임장, 명의고객의 인감증명서와 대리인의 신분증명서 등의 증표를 제시 받아 대리인인지 여부를 확인합니다.
4) 고객님께서 개인정보의 오류에 대해 정정을 요청하신 경우에는 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
5) 단, 다음과 같은 경우에는 예외적으로 개인정보의 열람 및 정정을 제한할 수 있습니다.
가.본인 또는 제3 자의 생명, 신체, 재산 또는 권익을 현저하게 해할 우려가 있는 경우
나.당해 서비스 제공자의 업무에 현저한 지장을 미칠 우려가 있는 경우
다.법령에 위반하는 경우
7. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
1) 쿠키란?
Rofler는 개인화되고 맞춤화 된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.이후 이용자가 웹 사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 파악하여 이용자의 환경설정을 유지하고 맞춤화 된 서비스를 제공하기 위해 이용됩니다. 쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.
2) 회사의 쿠키 사용 목적
회원이 방문한 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기-검색어, 보안접속 여부, 뉴스편집, 이용자 규모 등을 파악하여 회원에게 광고를 포함한 최적화된 맞춤형 정보를 제공하기 위해 사용합니다.
3) 쿠키의 설치/운영 및 거부
이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다.따라서 이용자는 웹_브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다. 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.
①[도구]메뉴에서[인터넷 옵션]을 선택합니다.
②[개인정보 탭]을 클릭합니다.
③[개인정보처리 수준]을 설정하시면 됩니다.
8. 개인정보의 수집과 이용 또는 제공에 대한 동의 철회
1) 고객님은 개인정보의 수집과 이용 또는 제공에 대한 동의를 철회할 수 있습니다.동의 철회를 위해서는 홈페이지에서 제공하는 ‘회원정보-회원 탈퇴’ 서비스에서 본인 확인 절차를 거친 후, 회원 탈퇴와 함께 동의를 철회할 수 있습니다.
2) 만 14세 미만의 아동은 자신에 대한 정보를 다른 사람에게 함부로 공유하지 않으며, 보내기 전에 반드시 부모님(법정대리인)의 허락을 받아야 합니다.Rofler는 만 14세 미만 아동의 개인정보를 제3자인 다른 사람 또는 업체와 공유하지 않으며, 만 14세 미만의 아동에게는 광고메일을 발송하지 않습니다.만 14세 미만 아동의 경우, ‘서비스 이용약관’ 제8조 및 ‘개인정보처리방침’ 제9조에 의거, 부모님(법정대리인)의 가입 동의를 얻은 후 서비스를 이용할 수 있도록 하고 있습니다.10일 이내에 부모님(법정대리인)이 가입 동의를 하지 않으면 만 14세 미만의 아동의 가입 정보는 삭제되며, 회원가입이 해지됩니다.
9. 만14세 미만 아동의 개인정보보호
1) 만 14 세 미만 아동(이하 ‘아동’이라 함)의 회원가입은 개인정보 수집 시 반드시 법정대리인 (부모님)의 동의를 구하고 있습니다.법정대리인(부모님)의 동의 방법은 법정대리인(부모님)의 휴대폰과 전자우편으로 발송한 인증번호로 동의를 구하고 있습니다.또한 일정 서식을 다운받아 법정대리인(부모님) 서명을 거친 후 고객센터 이메일로 송부해주시면 됩니다.
2) 회사는 법정대리인(부모님)의 동의를 받기 위하여 아동으로부터 법정대리인(부모님)의 이름과 연락처 등 최소한의 개인정보를 수집하고 있습니다.법정대리인(부모님)은 아동의 개인정보에 대한 열람, 정정 및 삭제를 할 수 있으며, 아동의 개인정보를 열람, 정정 및 삭제하고자 할 경우에는[회원정보수정]를 클릭하여 법정대리인(부모님)이 직접 하거나, 개인정보 관련 담당자에게 전화 또는 전자우편으로 요청하시면 필요한 조치를 취해 드립니다.
3) 회사는 아동에 관한 개인정보를 제3 자에게 제공하거나 공유하지 않으며, 아동으로부터 수집한 개인정보에 대하여 법정대리인(부모님)이 오류의 정정을 요구하는 경우 그 오류를 정정할 때까지 해당 개인정보의 이용 및 제공을 금지합니다.또한 아동의 회원가입 신청 후, 10일이 지나도 법정대리인(부모님)의 동의가 없을 경우에는 아동 및 법정대리인의 개인정보는 지체 없이 모두 폐기됩니다.
4) 아동 및 법정 대리인의 권리와 그 행사방법에 따른 '개인정보처리방침' 제6조 내지 제8조를 준용합니다.
10. 고객 의견수렴 및 불만처리
회사는 고객님의 의견을 매우 소중하게 생각합니다.고객님께서 문의사항이 있으실 경우, 회사 고객센터에 문의하시면 신속하고 정확한 답변을 드리겠습니다.
개인정보보호 책임자 및 담당자 연락처
㈜바른손
이메일: support @barunson.co.kr
11. 개인정보의 기술적, 관리적 보호
Rofler는 고객님의 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 주요 개인정보는 중앙 서버에서 통합 관리되며, 블록체인에 등록되는 개인정보는 블록체인이 아닌 웹서버나 오프체인으로 저장합니다.
12. 개인정보 침해 관련 상담 및 신고
개인정보 침해에 대한 상담 및 신고가 필요하신 경우에는 개인정보 관련 담당자에게 이메일로 연락하시거나, 과학기술정보통신부 산하 공공기관인 한국인터넷진흥원(KISA) 내에 설치된 개인정보 침해 신고센터로 문의하시기 바랍니다.
☎ 개인정보침해신고센터
전화: (국번없이)118 / 홈페이지: http://www.privacy.kisa.or.kr
☎ 대검찰청 첨단범죄수사과
전화: 02-3480-2000 / 홈페이지: http://www.spo.go.kr
☎ 경찰청 사이버테러대응센터
전화: 02-392-0330 / 홈페이지: http://www.cyberbureau.police.go.kr/index.do
13. 개인정보 처리방침의 고지 또는 민원 처리
개인정보보호 관련 법령의 개정 또는 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정할 수 있습니다.또한, 고객님 개인정보의 수집, 처리, 관리 등의 업무를 위탁하는 경우에는 홈페이지의 ‘개인정보처리방침’ 등을 통하여 그 사실을 고객님에게 고지합니다.고객님께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다.회사는 고객님의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
14. 본 개인정보 처리방침의 변경
Rofler는 법률적, 규제적 또는 운영상의 요구 사항이 변화함에 따라 필요시 본 개인정보 처리방침의 내용을 수정할 수 있습니다.Rofler는 법률에 의거하여 해당 변경 내용(효력 발생일 포함)을 통지합니다.변경 사항의 효력 발생일 이후 회원이 지속적으로 Rofler 서비스를 이용하는 경우, 해당 변경 사항을 인지하고 (해당하는 경우) 수락한 것으로 간주됩니다.본 개인정보 처리방침의 변경 내용을 인지 또는 수락하고자 하지 않을 경우, 회원은 Rofler 서비스 이용을 취소할 수 있습니다.
                `}> 
                </textarea>
              </li>
            </ul>
            <ul className="chk-lst">
                <li>
                    <input type="checkbox" id="chk-info01" name="chk-info" className="chk-info" {...register('checkPolicy')} />
                    <label htmlFor="chk-info01">저는 개인 정보 처리 방침에 따른<br />개인 정보 수집 및 활용에 동의합니다.</label>
                </li>
                <li>
                    <input type="checkbox" id="chk-info02" name="chk-info" className="chk-info" {...register('checkWantInform')} />
                    <label htmlFor="chk-info02">저는 할인 및 알림 이벤트 이메일 받는 것을 희망합니다. </label>
                </li>
            </ul>
        </form>
    </div>
    {/* <!--//join-wrap--> */}
  </section>
  {/* <!--//netstream-conts--> */}
  {/* <!--member-footer-wrap-->  */}
  {/* <div className="member-footer-wrap"> */}
    <RoflerCommonFooter />
  {/* </div> */}
  {/* <!--//member-footer-wrap--> */}
</div>
{/* // <!--//netstream-wrap-->    */}

{dimmer?
  (
    <div className="dim-wrap">
        <div className="popup-wrap">
            <div className="popup">
                <div className="pop-conts">
                    <p className="msg">
                      {dimmer}
                    </p>
                    <div className="pop-bt-wrap">
                        <button onClick={() => { setDimmer(''); setDimmer(null); return; }} className="pop-confirm-bt">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  ):''
}
{/* // 사실 이 부분은 원스토어 가입을 위해 결제 부분을 보이지 않기 위해서임. 2021. 12. 21 */}
<Dimmer type="1" text={goHomeDimmer} okfunc={okGoHome} show={true} />
    </>
  );
};

export default RoflerSignup;