import React from "react";
import * as Yup from 'yup';
import '../css/ui.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
// import { Email } from "@material-ui/icons";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export const RoflerCommonFooter = () =>
{
  const navigate = useNavigate();
  return (
    <>
      <div className="footer-wrap">
        <footer>
          {/* <p><a onClick={() => {navigate('/faq')}}>고객센터(문의하기)</a></p> */}
          {/* <p><a onClick={() => { window.location='/faq'}}>고객센터(문의하기)</a></p>
          <p>주식회사 바른손 &#47; 대표 강신범 &#47; 경기도 수원시 영통구 대학3로 1 K-tower 5F &#47; 070-4174-0700 &#47; 사업자등록번호 : 114-81-65451 &#47; <a href="/terms">이용약관</a> &#47; <a href="/policy">개인정보처리방침</a></p> */}
          
           <p>
            <a onClick={() => {navigate('/faq')}}>고객센터(문의하기)</a>
          </p>

          <p>
            <a onClick={() => {navigate('/terms')}}>이용약관</a>
          </p>

          <p className="help">
            <a onClick={() => {navigate('/policy')}}>개인정보처리방침</a>
          </p>

          <p>
            주식회사 바른손 | 통신판매업신고번호 : 제 2020-수원영통-0378 호<br />
            대표 : 강신범<br />
            이메일주소 : support@barunson.co.kr<br />
            전화번호 : 070-4174-0700<br />
            주소 : 대한민국 경기도 수원시 영통구 대학3로 1 K-tower 5층 , 16227<br />
            사업자등록번호 : 114-81-65451<br />
            클라우드호스팅 : Amazon Web Services Inc.
          </p>

        </footer>
      </div>
    </>
  );
}

export default RoflerCommonFooter;
